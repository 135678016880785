import Axios from "@app/api/axios";
import { EMAIL_ACCOUNTS } from "@utils/constant";

const emailAccountsService = () =>
  Axios.get(`${EMAIL_ACCOUNTS}`);
export default emailAccountsService;

export const emailAccountServiceById = (id) => 
  Axios.get(`${EMAIL_ACCOUNTS}?filter[id]=${id}`); 

