import React from "react";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";

import InviteUserSchema from "./InviteUserSchema";

const Schema = Yup.object({
  firstName: Yup.string().required(
    <FormattedMessage id="validation_empty_name" defaultMessage="Enter Name" />
  ),
  lastName: Yup.string().required(
    <FormattedMessage
      id="validation_empty_last_name"
      defaultMessage="Enter Last Name"
    />
  ),
}).concat(InviteUserSchema);

export default Schema;
