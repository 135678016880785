import React from "react";
import PropTypes from "prop-types";

import { Tooltip as PrimeTooltip } from "primereact/tooltip";
import styled from "styled-components";
import {
  compose,
  space,
  layout,
  flexbox,
  position,
  typography,
  color,
} from "styled-system";

import Config from "@src/config";

import breakpoints from "@utils/breakpoints";

const mobileBreakPoint = Config.mobileBreakPoint;

const ToolTip = styled(PrimeTooltip)`
  ${compose(layout, space, flexbox, position, typography, color)};
  .p-tooltip {
    border: 1px solid var(--grey-lightest) !important;
    background-color: var(--white) !important;
    border-radius: 10px !important;
    color: var(--grey-dark) !important;
  }

  .p-tooltip-text {
    border: 1px solid var(--grey-lightest) !important;
    background-color: var(--white) !important;
    border-radius: 10px !important;
    color: var(--grey-dark) !important;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;

    div {
      background-color: var(--white) !important;
      border-radius: 10px;
      color: var(--grey-dark) !important;
      font-size: var(--fs-h5);
      line-height: var(--lh-text-m);
      font-weight: var(--medium-weight);
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: normal;

      p {
        color: var(--grey-dark) !important;
        font-size: var(--fs-h5);
        line-height: var(--lh-text-m);
        font-weight: var(--medium-weight);
      }

      span {
        color: var(--grey-dark) !important;
        background-color: var(--white) !important;
      }

      strong {
        color: var(--grey-dark) !important;
        background-color: var(--white) !important;
        font-weight: var(--semibold-weight);
      }

      em {
        font-style: italic;
      }

      li {
        margin-left: 1.5rem;
      }

      ol,
      ul {
        list-style: disc;
      }

      @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
        max-width: 270px;
      }
    }
  }

  
`;

const Tooltip = ({ content, isHtml = false, autoHide = false, ...props }) => {
  return (
    <ToolTip autoFocus autoHide={autoHide} {...props}>
      {isHtml ? (
        <div autoFocus dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        content
      )}
    </ToolTip>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string,
  content: PropTypes.node,
  autoHide: PropTypes.bool,
  isHtml: PropTypes.bool,
};

export default Tooltip;
