import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import Tabs from "@components/Tabs";

const getTargetIndex = (content, targetUrl) => {
  const targetIndex = Math.max(
    content.findIndex(({ url }) => url === targetUrl),
    0
  );

  return targetIndex;
};

const TabsNavigator = ({ content, onActiveIndexChange, ...props }) => {
  const { pathname } = useLocation();

  const [activeTabIndex, setActiveTabIndex] = useState(() => {
    const targetIndex = getTargetIndex(content, pathname);

    onActiveIndexChange?.(targetIndex);

    return targetIndex;
  });

  const handleTabChange = event => {
    const { index } = event;
    const targetUrl = content[index]?.url;

    const targetTabIndex = getTargetIndex(content, targetUrl);

    if (targetTabIndex === activeTabIndex) {
      return;
    }

    setActiveTabIndex(targetTabIndex);

    onActiveIndexChange?.(targetTabIndex);

    window.history.pushState(null, "", targetUrl);
  };

  return (
    <Tabs
      content={content}
      activeIndex={activeTabIndex}
      onTabChange={handleTabChange}
      {...props}
    />
  );
};

TabsNavigator.propTypes = {
  content: PropTypes.array,
  onActiveIndexChange: PropTypes.func,
};

export default TabsNavigator;
