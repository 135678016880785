import React, { useState } from "react";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { EMAIL_ACCOUNTS } from "@utils/constant";
import Div from "@components/Div";
import ConfirmationPopup from "@components/ConfirmationPopup"; 
import { useToast } from "@hooks/useToast"; 
import deleteEmailAccountService from "@app/services/email/deleteEmailAccountService"; 
import CreateEmailAccountDialog from "./CreateEmailAccountDialog"; 
import EditEmailAccountsDialog from "./EditEmailAccountsDialog";

const List = () => {
  const { messages } = useIntl();
  const searchableColumns = ["id", "email_address"];
  const [showAddEmailAccountDialog, setShowAddEmailAccountDialog] = useState(false);
  const [showEditEmailAccountDialog, setShowEditEmailAccountDialog] = useState(false);
  const [editEmailAccountId, setEditEmailAccountId] = useState("");
  const [deleteEmailAccountId, setDeleteEmailAccountId] = useState("");
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();
  const handleAddNewEmailAccount = () => {
    setShowAddEmailAccountDialog(!showAddEmailAccountDialog);
  };
  const handleEditNewEmailAccountOpen = (values) => {
    const { id } = values;
    setEditEmailAccountId(id);
    setShowEditEmailAccountDialog(!showEditEmailAccountDialog);
  };
  const handleEditNewEmailAccountClose = () => {
    setShowEditEmailAccountDialog(!showEditEmailAccountDialog);
  };
  const handleDeleteEmailAccount = (values) => {
    const { id } = values;
    setDeleteEmailAccountId(id);
    setIsDeleteDialogVisible(true);
  };
  const handleCancelDeleteEmailAccount = () => {
    setIsDeleteDialogVisible(false);
  };
  const handleDeleteSubmit = async () => {
    try {
      await deleteEmailAccountService(deleteEmailAccountId);
      showSuccessToast(messages.label_email_account_deleted);
      setIsDeleteDialogVisible(false);
      return;
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  const dataTableConfig = {
    header: {
      title: "email_accounts_lable",
      actions: [
        {
          id: "new-court",
          type: "button",
          label: messages.label_add_new_email_account,
          onClick: handleAddNewEmailAccount,
          width: "auto",
          icon: "plus",
          variant: "header",
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "email_accounts.csv",
    backend_querying: true,
    no_records_message: "datatable_admin_reports_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${EMAIL_ACCOUNTS}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        [
          "fields",
          [
            "id",
            "email_address",
            "from_name",
            "enabled",
            "first_name",
            "last_name", 
            "callback_url"
          ],
        ],
      ],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
        width: "100px",
        sortable: "true",
      },
      {
        title: "watchlist_label_name",
        db_field: "from_name",
        type: "text",
        sortable: "true",
      },
      {
        title: "label_email",
        db_field: "email_address",
        type: "text",
        sortable: "true",
      },
      {
        title: "label_status",
        db_field: "enabled",
        type: "text",
        sortable: "true",
        width: "100px",
      },
      {
        title: "label_callback_url",
        db_field: "callback_url",
        type: "text",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            label: messages.label_edit_email_account,
            icon: "icon-pen",
            onClick: handleEditNewEmailAccountOpen,
          },
          {
            label: messages.label_email_account_delete,
            icon: "icon-rubber",
            onClick: handleDeleteEmailAccount,
          },
        ],
      },
    ],
  };

  return <>
  {<DataTable config={dataTableConfig} />}
  {showAddEmailAccountDialog && (
        <Div mt={8}>
          <CreateEmailAccountDialog handleClose={handleAddNewEmailAccount} />
        </Div>
      )}
      {showEditEmailAccountDialog && (
        <Div mt={8}>
          <EditEmailAccountsDialog editEmailAccountId={editEmailAccountId} handleClose={handleEditNewEmailAccountClose} />
        </Div>
      )}
      {isDeleteDialogVisible && (
        <ConfirmationPopup
          title={messages.label_email_account_delete}
          description={messages.label_confirm_email_account_delete}
          onCancelClick={handleCancelDeleteEmailAccount}
          onAcceptClick={handleDeleteSubmit}
        />
      )}
  </>
};
export default List;
