import React, { useRef,useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import queryString from "query-string";
import { H3, H4 } from "@components/Heading";
import Section from "@components/Section";
import Container from "@components/Container";
import Div from "@components/Div";
import { ROUTES } from "@utils/constant";
import AlertPage from "@pages/auth/shared/AlertPage";
import ProgressSpinner from "@components/ProgressSpinner";
import resetPassword from "@app/services/auth/resetPasswordService";
import Card from "@components/Card";
import GDPRInformationMessage from "@components/privacyPolicies/GDPRInformationMessage";
import SurveyForm from "@components/Survey/SurveyForm";
import ResetPasswordConfig from "./ResetPasswordConfig.json";

export const ResetPassword = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { search = "" } = location;
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const params = queryString.parse(search);
  const surveyRef = useRef(null);

  const handleResetPassword = async (values) => {
    setIsFetching(true);
    const { email = "", token = "" } = params;
    const payload = values;
    payload.email = email;
    payload.token = token;
    try {
      await resetPassword(payload);
      setIsFetching(false);
      setIsSubmitted(true);
    } catch (e) {
      setIsFetching(false);
      throw new Error(e);
    }
  };

  const redirectToLogin = () => {
    history.push(ROUTES.LOGIN.URL);
  };

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};
    handleResetPassword(data);
  };

  return (
    <Section
      flex={1}
      display="flex"
      justifyContent="center"
      bg="var(--site-background-color)"
      className="reset-password"
    >
      <Container
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between">
        {isFetching ? (
          <ProgressSpinner />
        ) : (
          <Div
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {isSubmitted && (
              <Div>
                <AlertPage
                  sourcePage={messages.title_set_new_password}
                  redirectToLogin={redirectToLogin}
                />
              </Div>
            )}
            {!isSubmitted && (
              <Card width={1} maxWidth="465px">
                <Div
                  px={[3, 3, 4, 4]}
                  py="24px"
                  pb="0"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  gridGap={3}
                >
                  <H3 color="var(--blue-dark) !important" textTransform="none !important" display={"block"} textAlign="center">
                    {messages.title_set_new_password}
                    </H3>
                  <H4 textAlign="center" fontSize="var(--fs-link-m) !important">{messages.text_new_password}</H4>
                  <SurveyForm
                    data={{ password: "", confirm_password: "" }}
                    formConfig={ResetPasswordConfig}
                    onRefLoaded={handleLoadSurveyRef}
                    onComplete={handleSave}
                    showCompleteButton={true}
                  />
                </Div>
              </Card>
            )}
          </Div>
        )}
        <GDPRInformationMessage />
      </Container>
    </Section>
  );
};

export default ResetPassword;
