import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { authActions } from "@app/reducers/authReducer";
import putUserService from "@app/services/users/putUserService";

import { ButtonLinkIcon } from "@components/Button";
import Card from "@components/Card";
import { default as Div } from "@components/Div";
import { H4 } from "@components/Heading";
import Icon from "@components/Icon";
import JsonFormDialog from "@components/JsonForm/JsonFormDialog";
import { Text, TextMediumWeight } from "@components/Text";

import { useToast } from "@hooks/useToast";

import Password from "@src/schema/Password";

import MyProfileBankIdLoginDialog from "./MyProfileBankIdLoginDialog";
import MyProfileBankIdSuccessDialog from "./MyProfileBankIdSuccessDialog";
import MyProfileDisableBankIdDialog from "./MyProfileDisableBankIdDialog";

const INITIAL_VALUES = {
  changePassword: {
    old_password: "",
    password: "",
    confirm_password: "",
  },
};

const MyProfileAccountSecurityCard = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();
  const { id, ssn } = useSelector(state => state.authReducer.userInfo);
  const { showSuccessToast, showErrorToast } = useToast();
  const [isBankIdLoginEnabled, setIsBankIdLoginEnabled] = useState(
    ssn !== null
  );
  const [isPasswordDialogVisible, setIsPasswordDialogVisible] = useState(false);
  const [isSsnSubmitDialogVisible, setIsSsnSubmitDialogVisible] =
    useState(false);
  const [isSuccessDialogVisible, setIsSuccessDialogVisible] = useState(false);
  const [isDisableBankIdDialogVisible, setIsDisableBankIdDialogVisible] =
    useState(false);

  // Update isBankIdLoginEnabled whenever ssn changes
  useEffect(() => {
    setIsBankIdLoginEnabled(ssn !== null);
  }, [ssn]);

  const handleOpenPasswordDialog = () => {
    setIsPasswordDialogVisible(true);
  };

  const handleClosePasswordDialog = () => {
    setIsPasswordDialogVisible(false);
  };

  const handleOpenSsnSubmitDialog = () => {
    setIsSsnSubmitDialogVisible(true);
  };

  const handleCloseSsnSubmitDialog = () => {
    setIsSsnSubmitDialogVisible(false);
  };

  const handleOpenSuccessDialog = () => {
    setIsSuccessDialogVisible(true);
  };

  const handleCloseSuccessDialog = () => {
    setIsSuccessDialogVisible(false);
  };

  const handleOpenDisableBankIDLoginDialog = () => {
    setIsDisableBankIdDialogVisible(true);
  };

  const handleCloseDisableBankIDLoginDialog = () => {
    setIsDisableBankIdDialogVisible(false);
  };

  const handleEditBankIdLogin = useMemo(
    () =>
      isBankIdLoginEnabled
        ? handleOpenDisableBankIDLoginDialog
        : handleOpenSsnSubmitDialog,
    [isBankIdLoginEnabled]
  );

  const handlePasswordSubmit = async values => {
    try {
      await putUserService(id, values);

      showSuccessToast(messages.text_update_successful);
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  const handleBankIdLoginSubmit = async ({ ssn }) => {
    try {
      const payload = {
        ssn,
      };

      if (ssn === null) {
        // Call the API only if ssn is null
        const response = await putUserService(id, payload);
        let user = response.data;
        dispatch(authActions.storeUserInfo({ ...user }));
        setIsBankIdLoginEnabled(false);
      } else {
        // If ssn is not null, use existing userInfo
        setIsBankIdLoginEnabled(true);
      }
      handleOpenSuccessDialog();
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  const handleDisableBankIdLogin = async () => {
    const payload = {
      ssn: null,
    };

    await handleBankIdLoginSubmit(payload);
  };

  const password = [
    {
      label: <FormattedMessage id="label_current_password" />,
      fieldType: "password",
      name: "old_password",
      placeholder: <FormattedMessage id="label_current_password" />,
      validation: Password.fields.current_password,
    },
    {
      label: <FormattedMessage id="label_new_password" />,
      fieldType: "password",
      name: "password",
      placeholder: <FormattedMessage id="label_new_password" />,
      validation: Password.fields.password,
      feedback: true,
    },
    {
      label: <FormattedMessage id="label_repeat_new_password" />,
      fieldType: "password",
      name: "confirm_password",
      placeholder: <FormattedMessage id="label_repeat_new_password" />,
      validation: Password.fields.confirm_password,
    },
  ];

  const content = useMemo(
    () => [
      {
        title: messages.label_password,
        text: messages.message_set_strong_password,
        buttonLabel: messages.label_change_password,
        onButtonClick: handleOpenPasswordDialog,
        iconName: "padlock",
        iconColor: "turquoise",
        iconSize: "var(--fs-icon-m)",
      },
      {
        title: messages.title_login_with_bank_id,
        titleSubContent: (
          <TextMediumWeight
            ml={1}
            color={`var(--${isBankIdLoginEnabled ? "turquoise" : "grey"}) !important`}
          >
            {isBankIdLoginEnabled ? messages.on : messages.off}
          </TextMediumWeight>
        ),
        text: messages.message_bank_id_login,
        buttonLabel: isBankIdLoginEnabled
          ? messages.label_disable_bank_id
          : messages.label_enable_bank_id,
        onButtonClick: handleEditBankIdLogin,
        iconName: "bankID",
        iconColor: isBankIdLoginEnabled ? "blue-dark" : "grey",
        iconSize: "var(--fs-icon-l)",
        isHidden: false,
      },
    ],
    [handleEditBankIdLogin, isBankIdLoginEnabled, messages]
  );

  const renderCardContent = useCallback(
    ({
      title,
      titleSubContent,
      text,
      buttonLabel,
      iconName,
      iconColor,
      iconSize,
      onButtonClick,
      // MARK: hide customer part of BankID for 4.2 release
      isHidden,
    }) =>
      !isHidden && (
        <React.Fragment key={title}>
          <Div width={1} height={1} bg="var(--grey-lightest)" />

          <Div
            display="flex"
            flexDirection={["column", "column", "row", "row"]}
            alignItems="flex-start"
            justifyContent={[
              "flex-start",
              "flex-start",
              "space-between",
              "space-between",
            ]}
            gridGap={[3, 3, 0, 0]}
          >
            <Div display="flex" flexDirection="column" gridGap={2}>
              <TextMediumWeight display="flex">
                {`${title}: `}
                {titleSubContent}
              </TextMediumWeight>

              <Text>{text}</Text>
            </Div>

            <ButtonLinkIcon
              p={0}
              label={buttonLabel}
              onClick={onButtonClick}
              icon={
                <Icon
                  name={iconName}
                  fontSize={`var(--fs-${iconSize})`}
                  color={`var(--${iconColor})`}
                  mr={2}
                />
              }
            />
          </Div>
        </React.Fragment>
      ),
    []
  );

  return (
    <>
      {isPasswordDialogVisible && (
        <JsonFormDialog
          title={messages.label_change_password}
          config={password}
          onHide={handleClosePasswordDialog}
          onSubmit={handlePasswordSubmit}
          INITIAL_VALUES={INITIAL_VALUES.changePassword}
        />
      )}

      {isSsnSubmitDialogVisible && (
        <MyProfileBankIdLoginDialog
          isBankIdLoginEnabled={isBankIdLoginEnabled}
          onClose={handleCloseSsnSubmitDialog}
          onSubmit={handleBankIdLoginSubmit}
        />
      )}

      {isSuccessDialogVisible && (
        <MyProfileBankIdSuccessDialog
          isDisableBankIdLogin={!isBankIdLoginEnabled}
          onClose={handleCloseSuccessDialog}
        />
      )}

      {isDisableBankIdDialogVisible && (
        <MyProfileDisableBankIdDialog
          onClose={handleCloseDisableBankIDLoginDialog}
          onSubmit={handleDisableBankIdLogin}
        />
      )}

      <Card p={3} borderRadius="20px" width={1}>
        <Div width={1} display="flex" flexDirection="column" gridGap="12px">
          <H4>{messages.title_account_security}</H4>

          {content.map(item => renderCardContent(item))}
        </Div>
      </Card>
    </>
  );
};

export default MyProfileAccountSecurityCard;
