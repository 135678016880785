import React, { useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import resendReportService from "@app/services/results/resendReportService";

import { ButtonText } from "@components/Button";
import Div from "@components/Div";
import { H5 } from "@components/Heading";
import Icon, { SmallEllipseIcon, BundleIcon } from "@components/Icon";
import Link, { LinkArrow } from "@components/Link";
import ProgressSpinner from "@components/ProgressSpinner";
import Span from "@components/Span";
import {
  Text,
  TextSmall,
  TextLargeSemiBoldWeight,
  ColouredSemiBoldText,
} from "@components/Text";
import Tooltip from "@components/ToolTip";

import { CustomerPermissions, AdminPermissions } from "@enum/Permissions";

import useAuthorization from "@hooks/useAuthorization";

import ConsentResultCard from "@pages/user/results/ConsentResults";

import {
  ROUTES,
  ANALYSIS,
  STATUS_EXPIRED,
  STATUS_EXPIRED_NO_CONSENT,
  STATUS_EXPIRED_NO_CONSENT_LONGER,
  STATUS_NOT_PAID,
  STATUS_PAID,
  STATUS_COMPLETED,
  STATUS_IN_PROGRESS,
  STATUS_CANCELLED,
  STATUS_NEW,
  STATUS_ERROR,
  STATUS_FAILED,
  ROLE_ANALYSIS,
  GENERAL_ANALYSIS,
  ASSIGNMENT,
  BUNDLE,
  PENDING_CONSENT,
  PENDING_CONSENT_TO_SHARE,
} from "@utils/constant";
import { diffDays, convertSEK, getFormattedNumber } from "@utils/utils";

const DELIVERY_IMMEDIATE = "Direkt";

const ResultsCard = ({
  status = "",
  isDetails = false,
  showAnalysLink,
  orderItem,
  product,
  report,
  assignment,
  isReport,
  fetchReportDetails,
  isChild,
}) => {
  const { isAdmin, hasAnyPermissions } = useAuthorization();
  const { messages } = useIntl();

  const {
    service_type: serviceType = "",
    price_after_adjustments: orderPrice = 0,
    credits: orderCredits = 0,
    name: service = "",
  } = orderItem;

  const {
    description: serviceDetail,
    grade_mapping,
    delivery_time = "",
    id: productID,
  } = product || {};

  const {
    grades,
    id: reportId,
    report_status: reportStatus = "",
    expired_at: reportExpiryDate = "",
  } = report ?? {};

  const assignmentStatus = assignment?.status;
  const assignmentId = assignment?.id;
  const TextComponent = isChild ? H5 : TextLargeSemiBoldWeight;
  const history = useHistory();
  const showGradeResults = hasAnyPermissions([
    CustomerPermissions.CustomerGradesReportAnalysis,
    CustomerPermissions.CustomerReadReportAnalysis,
    CustomerPermissions.CustomerReadSelfReportsAnalysis,
  ]);

  const showGradeActionButtons = hasAnyPermissions([
    AdminPermissions.AdminReadReportAnalysis,
    CustomerPermissions.CustomerReadReportAnalysis,
    CustomerPermissions.CustomerReadSelfReportsAnalysis,
  ]);

  const hasPricesPermission = hasAnyPermissions([
    CustomerPermissions.CustomerReadPrices,
  ]);

  const showDelivery = serviceType === BUNDLE || serviceType === ASSIGNMENT;
  const { analysis_type: analysisType = GENERAL_ANALYSIS } = product || {};
  const isRoleAnalysis = analysisType === ROLE_ANALYSIS;
  const isConsent =
    reportStatus === PENDING_CONSENT ||
    reportStatus === PENDING_CONSENT_TO_SHARE;
  const [retryResponse, setRetryResponse] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const formattedOrderPrice = (Math.round(orderPrice * 100) / 100).toFixed(2);

  const handleRedirectToReportDetails = useCallback(
    () => history.push(`${ROUTES.ADMIN_ANALYSIS_LIST.URL}/${reportId}`),
    [reportId, history]
  );

  const handleRedirectToAssigmentDetails = useCallback(
    () => history.push(`${ROUTES.ASSIGNMENT_DETAILS.URL}?id=${assignmentId}`),
    [assignmentId, history]
  );

  const handleRedirectToReport = useCallback(
    () => window.open(`${ROUTES.ORDER_RESULTS.URL}?id=${reportId}`, "_blank"),
    [reportId]
  );

  const handleRestartAnalysis = useCallback(async () => {
    try {
      setIsFetching(true);
      const response = await resendReportService(reportId);
      const { status: resStatus = "" } = response || {};
      setIsFetching(false);
      setRetryResponse(resStatus);
    } catch (e) {
      setIsFetching(false);
      throw new Error(e);
    }
  }, [reportId]);

  const handleStatus = useCallback(
    orderStatus => {
      switch (orderStatus) {
        case STATUS_NOT_PAID:
          return messages.status_not_started;
        case STATUS_PAID:
          return messages.paid;
        case STATUS_COMPLETED:
          return messages.completed;
        case STATUS_IN_PROGRESS:
          return messages.status_in_progress;
        case STATUS_CANCELLED:
          return messages.cancelled;
        case STATUS_NEW:
          return messages.new;
        case PENDING_CONSENT:
          return messages.consent_label_pending_consent;
        case PENDING_CONSENT_TO_SHARE:
          return messages.consent_label_pending_consent_share;
        case STATUS_EXPIRED_NO_CONSENT_LONGER:
          return messages.report_status_expired_no_consent_longer;
        case STATUS_ERROR:
          return (
            <ColouredSemiBoldText color="var(--red)">
              {messages.waiting}
            </ColouredSemiBoldText>
          );
        case STATUS_EXPIRED:
          return messages.expired_status_label;
        default:
          return orderStatus;
      }
    },
    [messages]
  );
  const renderReportStatusMsg = useCallback(
    status => {
      switch (status) {
        case PENDING_CONSENT:
        case PENDING_CONSENT_TO_SHARE:
          return messages.assignment_pending_consent_message;
        case STATUS_EXPIRED_NO_CONSENT:
          return messages.assignment_expired_consent_message;
        case STATUS_IN_PROGRESS:
          return messages.consent_status_in_progress_description;
        case STATUS_EXPIRED:
          return messages.assignment_expired_message;
        default:
          return null;
      }
    },
    [messages]
  );

  const renderAssignmentStatusMsg = useCallback(
    status => {
      switch (status) {
        case STATUS_EXPIRED:
          return (
            <Div mt={3} mb={3}>
              <TextSmall>{messages.assignment_expired_message}</TextSmall>
            </Div>
          );
        default:
          return null;
      }
    },
    [messages]
  );

  const getActionButtons = () =>
    showGradeActionButtons && (
      <LinkArrow
        label={messages.see_results}
        direction="right"
        variant="secondary"
        mr={3}
        handleClick={handleRedirectToReport}
      >
        <Span px={1}>
          <Icon name="headerarrowright" />
        </Span>
      </LinkArrow>
    );

  const getExpiryMessage = () => {
    let expiryDaysCount = "";
    expiryDaysCount = diffDays(reportExpiryDate);
    if (!expiryDaysCount) return "";
    if (expiryDaysCount > 0)
      return `${messages.label_expires_in} ${expiryDaysCount} ${messages.label_days}`;
  };

  const renderContent = () => {
    let content = "";

    if (serviceType === ANALYSIS) {
      if (isRoleAnalysis && reportStatus === STATUS_COMPLETED) {
        content = <>{constructResultSummary(grades)}</>;
      } else if (status === STATUS_CANCELLED) {
        content = (
          <Text display="block">{messages.message_order_cancelled}</Text>
        );
      } else if (status === STATUS_NOT_PAID) {
        content = (
          <Text display="block">{messages.message_order_not_paid}</Text>
        );
      } else if (status === STATUS_EXPIRED) {
        content = (
          <Text display="block">{messages.assignment_expired_message}</Text>
        );
      } else if (isConsent && isReport) {
        content = (
          <ConsentResultCard
            orderDetails={orderItem}
            report={report}
            showConsentModal={true}
            isReport={isReport}
            fetchReportDetails={fetchReportDetails}
          />
        );
      } else if (reportStatus === STATUS_FAILED) {
        content = (
          <>
            <Text display="block">{messages.assignment_failed_message}</Text>
            {isAdmin && (
              <Div display={["block", "flex"]} my={2}>
                <LinkArrow
                  label={messages.label_restart_analysis}
                  direction="right"
                  variant="secondary"
                  mr={3}
                  handleClick={handleRestartAnalysis}
                >
                  <Span px={1}>
                    <Icon name="headerarrowright" />
                  </Span>
                </LinkArrow>
              </Div>
            )}
          </>
        );
      } else if (reportStatus === STATUS_COMPLETED) {
        content = (
          <>
            <Div
              display="flex"
              alignItems="center"
              justifyContent={["center", "space-between"]}
              // maxWidth="1050px"
              width={1}
            >
              <Div width="100% !important">{constructResultSummary(grades)}</Div>
            </Div>

            {showGradeActionButtons && (
              <Div
                display="flex"
                flexDirection={["column", "column", "column", "row"]}
                alignItems={[
                  "flex-start",
                  "flex-start",
                  "flex-start",
                  "center",
                ]}
                gridGap={[3, 3, 0, 0]}
                mt="25px"
              >
                {getActionButtons()}

                {showAnalysLink && (
                  <Link
                    mr={2}
                    label={messages.title_detail_report}
                    direction="right"
                    iconPos={"left"}
                    onClick={handleRedirectToReportDetails}
                  >
                    <Div px={1}>
                      <Icon name="file-download" />
                    </Div>
                  </Link>
                )}
              </Div>
            )}
            
            
          </>
        );
      }
    } else if (status === STATUS_CANCELLED) {
      content = <Text display="block">{messages.message_order_cancelled}</Text>;
    } else if (status === STATUS_NOT_PAID) {
      content = (
        <Text display="block">{messages.assignment_message_not_paid}</Text>
      );
    } else if (status === STATUS_EXPIRED) {
      content = (
        <Text display="block">{messages.assignment_expired_message}</Text>
      );
    } else if (assignmentStatus) {
      content = (
        <Div display={["block", "flex"]}>
          {assignmentStatus === STATUS_COMPLETED && getActionButtons()}
          {isAdmin && (
            <Link
              label={messages.label_details}
              direction="right"
              iconPos={"left"}
              mt={2}
              mr={3}
              onClick={handleRedirectToAssigmentDetails}
            >
              <Div px={1}>
                <Icon name="file-download" />
              </Div>
            </Link>
          )}
        </Div>
      );
    }
    
    return content;
  };

  const constructResultSummary = () => {
    const GradData = grade_mapping?.grade?.find(item => item.grades === grades);
    const {
      bgColor,
      color,
      grades: gradeValue = "",
      statement = [],
    } = GradData || {};
    return (
      <Div display="flex" flexWrap="wrap" flexDirection={["column","row","row","row"]} gridGap={3} width={1}>
        {(isAdmin || showGradeResults) &&
          statement.map((item, index) => {
            const flex = [
              index === statement.length - 1 ? "1 1 1 100%" : "1 1 1 40%",
              index === statement.length - 1 ? "1 1 1 100%" : "1 1 1 40%",
              "none",
              "none",
            ];

            return (
              <ButtonText
                flex={flex}
                maxHeight={["none", "40px"]}
                key={item}
                px={"20px !important"}
                py="6px"
                label={
                  item === "grade" && !isRoleAnalysis
                    ? `${messages[item]} ${gradeValue}`
                    : `${messages[item]}`
                }
                bg={`var(--${bgColor}) !important`}
                color={`var(--${color}) !important`}
              />
            );
          })}
      </Div>
    );
  };

  const renderExpiredDays = () => {
    if (status === STATUS_PAID || status === STATUS_COMPLETED) {
      return <TextSmall ml={4}>{getExpiryMessage()}</TextSmall>;
    }
    return "";
  };

  const renderPrice = () => {
    if (!hasPricesPermission) {
      return <Div />;
    }

    let updatedPrice = getFormattedNumber(formattedOrderPrice);

    if (orderCredits >= 1) {
      return (
        <>
          <Div display={["flex", "none"]}>
            <H5 mt={1}>{messages.prepaid_price_label}</H5>
          </Div>
          <Div display={["none", "flex"]}>
            <TextLargeSemiBoldWeight
              fontSize={[
                "var(--fs-text-m) !important",
                "var(--fs-text-m) !important",
                "var(--fs-tex)",
                "var(--fs-tex)",
              ]}
            >
              {messages.prepaid_price_label}
            </TextLargeSemiBoldWeight>
          </Div>
        </>
      );
    }

    return (
      <>
        <Div display={["flex", "none"]}>
          <TextLargeSemiBoldWeight
            fontSize={[
              "var(--fs-text-m) !important",
              "var(--fs-text-m) !important",
              "var(--fs-tex)",
              "var(--fs-tex)",
            ]}
          >{`${convertSEK(updatedPrice)} Kr`}</TextLargeSemiBoldWeight>
        </Div>
        <Div display={["none", "flex"]}>
          <TextLargeSemiBoldWeight
            fontSize={[
              "var(--fs-text-m) !important",
              "var(--fs-text-m) !important",
              "var(--fs-tex)",
              "var(--fs-tex)",
            ]}
          >{`${convertSEK(updatedPrice)} Kr`}</TextLargeSemiBoldWeight>
        </Div>
      </>
    );
  };

  return (
    <>
      <Div
        display={"flex"}
        alignItems={["flex-start", "center"]}
        justifyContent="space-between"
        flexDirection={["column", "row", "row", "row"]}
      >
        <Div
          display="flex"
          justifyContent="start"
          minWidth={["0px", "460px"]}
          flexDirection={"row"}
          flexWrap="wrap"
        >
          <Div
            display="flex"
            flexDirection={["column", "column", "row", "row"]}
            mt={[2, 0, 0, 0]}
            flexWrap="wrap" 
          >
            <Div
              display="flex"
              alignItems={["baseline", "center"]}
              flexWrap="wrap"
            >
              <TextComponent
                fontSize={[
                  "var(--fs-text-m) !important",
                  "var(--fs-text-m) !important",
                  "var(--fs-tex)",
                  "var(--fs-tex)",
                ]}
                wordBreak="break-word" 
              >
                {service}
                {serviceType === BUNDLE && (
                  <BundleIcon ml={2} name="bundle-tag"></BundleIcon>
                )}
                <Span mr={2}/>
                <SmallEllipseIcon
                  name="info"
                  className={`description_${productID}`}
                >
                  <Tooltip
                    isHtml
                    target={`.description_${productID}`}
                    content={serviceDetail}
                  />
                </SmallEllipseIcon>
                <Span mr={2}/>
              </TextComponent>
            </Div>

            {isRoleAnalysis &&
              reportStatus === STATUS_COMPLETED &&
              grades === null &&
              !isAdmin && (
                <TextComponent display={["none", "flex"]} ml={4}>
                  {messages.label_one_day}
                </TextComponent>
              )}
          </Div>

          <Div display="flex" alignItems="center" flexWrap="wrap">
            {isDetails && (
              <Div
                mt={[2, 0, 0, 0]}
                display={
                  isRoleAnalysis &&
                  reportStatus === STATUS_COMPLETED &&
                  grades === null
                    ? "none"
                    : "flex"
                }
                alignItems="center"
                flexWrap="wrap" /* Ensure wrapping for these elements as well */
              >
                <TextSmall ml={[0, 4, 4, 4]}>
                  {((serviceType === ANALYSIS || serviceType === ASSIGNMENT) &&
                    status === STATUS_PAID) ||
                  ((serviceType === ANALYSIS || serviceType === ASSIGNMENT) &&
                    status === STATUS_COMPLETED)
                    ? handleStatus(reportStatus)
                    : null}
                </TextSmall>
              </Div>
            )}

            <Div display={["none", "flex"]}>
              {!isRoleAnalysis &&
                (serviceType === ANALYSIS || serviceType === ASSIGNMENT) &&
                isDetails &&
                renderExpiredDays()}
            </Div>
          </Div>
        </Div>

        {!isDetails &&
          !isReport &&
          (showDelivery || reportStatus === STATUS_COMPLETED) && (
            <TextComponent display={["none", "none","flex", "flex"]} justifyContent="flex-end">
              {delivery_time === DELIVERY_IMMEDIATE
                ? messages.order_item_card_delivered 
                : delivery_time}
            </TextComponent>
          )}

        {isDetails && !isReport && (
          <Div
            display="flex"
            justifyContent={[
              "space-between",
              "flex-end",
              "flex-end",
              "flex-end",
            ]}
            minWidth={["100%", "125px", "125px", "125px"]}
          >
            <Div display={["block", "none", "none", "none"]} mt={2}>
              {messages.price}
            </Div>
            {!isChild && renderPrice()}
          </Div>
        )}
        
      </Div>

      <Div mt="25px">
      {serviceType === ANALYSIS && renderReportStatusMsg(reportStatus) && (
          <Div my={1} display="flex">
            <TextSmall>{renderReportStatusMsg(reportStatus)}</TextSmall>
          </Div>
        )}

        {serviceType === ASSIGNMENT && renderAssignmentStatusMsg(reportStatus)}

        <Div width={1}>{renderContent()}</Div>

        <Div
          mt={3}
          mb={3}
          display={
            isRoleAnalysis &&
            reportStatus === STATUS_COMPLETED &&
            grades === null
              ? "block"
              : "none"
          }
        >
          <TextSmall>{messages.grade_grey_message}</TextSmall>
        </Div>

        {isFetching && <ProgressSpinner />}

        {!isFetching && retryResponse === 200 && (
          <TextLargeSemiBoldWeight
            fontSize={[
              "var(--fs-text-m) !important",
              "var(--fs-text-m) !important",
              "var(--fs-tex)",
              "var(--fs-tex)",
            ]}
            color="var(--blue-dark) !important"
          >
            {messages.retry_success}
          </TextLargeSemiBoldWeight>
        )}
      </Div>
      
    </>
  );
};

ResultsCard.propTypes = {
  orderItem: PropTypes.object,
  product: PropTypes.object,
  report: PropTypes.object,
  assignment: PropTypes.object,
  grades: PropTypes.string,
  status: PropTypes.string,
  isReport: PropTypes.bool,
  isDetails: PropTypes.bool,
  isChild: PropTypes.bool,
  showAnalysLink: PropTypes.bool,
  fetchReportDetails: PropTypes.func,
};

export default ResultsCard;
