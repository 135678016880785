import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import styled from "styled-components";

import CloseIcon from "@assets/close-icon.svg";

import Div from "@components/Div";
import Link from "@components/Link";
import Span from "@components/Span";
import { Text, TextMediumWeight } from "@components/Text";

import Logo from "../Logo";

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: var(--grey);
  font-weight: var(--medium-weight);
  cursor: pointer;
  white-space: nowrap;
`;

export const FilterCapsule = ({
  onClear,
  onClick,
  filters,
  appliedFilters,
}) => {
  const { messages } = useIntl();

  const generateNamesWithEllipsis = (finalValues = []) =>
    finalValues.flatMap(({ name, code }, index, array) => {
      const delimiter = array.length - 1 !== index ? ", " : "";

      return [
        <Span
          key={code}
          display="inline-block"
          maxWidth="70px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          lineHeight="14px"
        >
          {name}
        </Span>,
        delimiter,
      ];
    });

  const getFilterCapsule = (dateLabel, title, label, key, onClick) => (
    <Div
      display="flex"
      alignItems="center"
      bg="var(--white)"
      p="4px 8px"
      borderRadius="5px"
      key={key}
      mr={14}
      mb={1}
      onClick={onClick}
      mt={[2, 0, 0, 0]}
    >
      <Logo width={9} height={9} mr={10} logo={CloseIcon} />

      <Text
        color="var(--light-gray) !important"
        fontWeight="var(--light-weight) !important"
        fontSize="var(--fs-text-small) !important"
        lineHeight="var(--fs-text-small) !important"
      >
        {dateLabel || title}:
      </Text>

      <TextMediumWeight
        ml={1}
        lineHeight="var(--fs-text-small) !important"
        fontSize="var(--fs-text-small) !important"
      >
        {label}
      </TextMediumWeight>
    </Div>
  );

  const filterSelectedValues = (
    values = [],
    { title = "", options = [], type = "", id: filterColumnId = "" } = {}
  ) => {
    if (type === "calendar") {
      const [fromDate = {}, toDate = {}] = values;
      const [start_date = ""] = fromDate.values || [];
      const [end_date = ""] = toDate.values || [];

      let label_date = start_date;

      if (end_date) {
        label_date = `${label_date} - ${end_date}`;
      } else {
        label_date = `${label_date} - ${start_date}`;
      }

      return getFilterCapsule(
        messages.label_date,
        title,
        label_date,
        `filter-date-`,
        onClick(values, type, "calendar", "")
      );
    } else if (type === "checkbox") {
      const label = options
        .filter(o => values?.includes(o?.value))
        .map(({ label }) => label )
        .join(", ");
      

      return getFilterCapsule(
        "",
        title,
        label,
        `filter-${filterColumnId}-`,
        onClick(values, type, filterColumnId)
      );
    }

    return (
      <>
        {values?.map(value => {
          let {
            label = "",
            id = "",
            options: multiselectOptions = [],
          } = options.find(opt => opt.value === value) || {};
          let dateLabel = "";
          let optionId = "";

          if (type === "input") {
            label = value;
          }

          if (type === "multiselect") {
            const filterValues = Array.isArray(value)
              ? value?.map(o => o?.code || o)
              : [value];

            const finalValues = multiselectOptions?.filter(
              obj =>
                filterValues.includes(obj?.code) ||
                filterValues.includes(String(obj?.code))
            );

            if (finalValues?.length <= 3) {
              label = generateNamesWithEllipsis(finalValues);
            } else {
              label = `${finalValues?.length} ${messages.label_selected}`;
            }

            optionId = value;
          }

          return getFilterCapsule(
            dateLabel,
            title,
            label,
            `filter-${id}-${optionId}`,
            onClick(value, type, id, optionId)
          );
        })}
      </>
    );
  };

  const renderFilterCapsules = () => {
    const calendarValue = appliedFilters.filter(({ values, type }) => {
      if (type === "calendar") {
        const [value = ""] = values;

        return value;
      }

      return "";
    });
    const otherFilters = appliedFilters.filter(o => o.type !== "calendar");

    let selectedFilters = [...otherFilters];

    if (calendarValue?.length) {
      selectedFilters = [
        ...selectedFilters,
        {
          type: "calendar",
          values: calendarValue,
          id: "calendar",
        },
      ];
    }

    return selectedFilters?.map(({ id, values, type }) => {
      const data = filters.find(
        filter => filter.id === id || filter.id === type
      );

      return filterSelectedValues(values, data);
    });
  };

  return (
    <>
      <Div
        display="flex"
        flexWrap="wrap"
        justifyContent={["left", "left", "right", "right"]}
        pt={[2, 2, 0, 0]}
        alignItems="center"
      >
        {renderFilterCapsules()}
      </Div>

      <StyledLink
        fontSize="var(--fs-text-small) !important"
        onClick={onClear}
        pr={2}
        pt={[2, 2, 0, 0]}
        my="auto"
        lineHeight="var(--fs-text-small) !important"
      >
        {messages.label_clear_filters}
      </StyledLink>
    </>
  );
};

FilterCapsule.propTypes = {
  filters: PropTypes.object,
  onClear: PropTypes.func,
  appliedFilters: PropTypes.array,
  onClick: PropTypes.func,
};

export default FilterCapsule;
