import React from "react";
import PropTypes from "prop-types";

import PrintReportDialog from "../../LegacyReport/PrintReportDialog";

const DownloadGDPRDialog = ({ onClose, onConfirm, downloadUrl }) => {
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <PrintReportDialog
      downloadUrl={downloadUrl}
      onClose={handleClose}
      onConfirm={handleConfirm}
    />
  );
};

DownloadGDPRDialog.propTypes = {
  isCase: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  downloadUrl: PropTypes.string,
};

export default DownloadGDPRDialog;
