import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";

import * as Yup from "yup";

import userService from "@app/services/users/userService";

import DataTable from "@components/DataTableV2/DataTable";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import AdminContainer from "@layout/AdminContainer";

import { AdminPermissions } from "@src/enum/Permissions";

import {
  ROUTES,
  ADMIN_CUSTOMER_DETAILS,
  SUBSCRIPTION_STATUS,
} from "@utils/constant";
import { getFormattedNumber } from "@utils/utils";

import CustomerAccountDialog from "./tabs/CustomerAccountDialog";

const searchableColumns = ["id", "customer_name"];

const FILTER_KEY_FROM = "filter[created_at][gte]";

const FILTER_KEY_TO = "filter[created_at][lte]";

const List = () => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { showErrorToast } = useToast();

  const [accountManagers, setAccountManagers] = useState([]);
  const [showAddCustomerDialog, setShowAddCustomerDialog] = useState(false);
  const [isFiltersLoaded, setIsFiltersLoaded] = useState(false);

  const hasWriteCustomersPermission = hasAllPermissions([
    AdminPermissions.AdminWriteCustomers,
  ]);

  const handleAddNewCustomer = () => {
    setShowAddCustomerDialog(!showAddCustomerDialog);
  };

  useEffect(() => {
    async function fetchUsers() {
      try {
        const userResponse = await userService(
          "filter[type]=admin&filter[status]=active&filter[is_active]=1&per_page=100"
        );
        const { data: { data: users = [] } = {} } = userResponse;
        const userFilterData = users.map(({ name = "", id = "" }) => ({
          name: `${name} #${id}`,
          code: id,
        }));
        setAccountManagers(userFilterData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsFiltersLoaded(true);
      }
    }

    fetchUsers();
  }, [messages.exception_error_message, showErrorToast]);

  const dataTableConfig = {
    header: {
      actions: [
        {
          id: "new-customer",
          type: "button",
          label: messages.label_new_customer,
          onClick: handleAddNewCustomer,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: !hasWriteCustomersPermission,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "customers.csv",
    filters: [
      {
        title: messages.label_status,
        id: "filter[is_active]",
        type: "checkbox",
        options: [
          {
            label: messages.active,
            value: 1,
          },
          {
            label: messages.inactive,
            value: 0,
          },
        ],
      },
      {
        title: messages.registered_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        filter_by_startdate: "filter[created_at][eq]",
        options: [
          {
            label: messages.calendar_date_select_placeholder,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
      {
        title: messages.label_created_by,
        id: "filter[created_by]",
        type: "multiselect",
        options: [
          {
            name: "filter[created_by]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[created_by]",
            options: accountManagers,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.account_manager,
        id: "filter[account_manager_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[account_manager_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[account_manager_id]",
            options: accountManagers,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.subscriptions_title,
        id: "filter[has_subscription]",
        type: "checkbox",
        options: [
          {
            label: messages.yes_label,
            value: "true",
          },
          {
            label: messages.no_label,
            value: "false",
          },
        ],
      },
      {
        title: messages.api_availability,
        id: "filter[integration_api_enabled]",
        type: "multiselect",
        options: [
          {
            name: "filter[integration_api_enabled]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[integration_api_enabled]",
            options: [
              {
                name: messages.yes_label,
                code: 1,
              },
              {
                name: messages.no_label,
                code: "0",
              },
            ],
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.test_account,
        id: "filter[test_account]",
        type: "multiselect",
        options: [
          {
            name: "filter[test_account]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[test_account]",
            options: [
              {
                name: messages.yes_label,
                code: 1,
              },
              {
                name: messages.no_label,
                code: "0",
              },
            ],
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.label_company,
        id: "filter[company_type]",
        type: "checkbox",
        options: [
          {
            label: messages.label_company_type_private,
            value: "private",
          },
          {
            label: messages.label_company_type_public,
            value: "public",
          },
        ],
      },
    ],
    enable_filter: true,
    backend_querying: true,
    no_records_message: "datatable_admin_customers_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    is_filter_options_updated: isFiltersLoaded,
    api: {
      resource: `/customers`,
      method: "GET",
      search_fields: searchableColumns,
      params: [["include", "orders,subscription"]],
    },
    columns: [
      {
        title: "customer_id",
        width: "120px",
        db_field: "id",
        type: "id",
        sortable: true,
        sortField: "id",
        href: values => {
          const { id } = values;
          return `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_DETAILS}`;
        },
      },
      {
        title: "title_customer",
        width: "30%",
        db_field: "customer_name",
        type: "link",
        sortable: true,
        sortField: "customer_name",
        formatter: (value, data) => {
          const { type, customer_name } = data;
          return type === "company" ? value : customer_name;
        },
        href: ({ id }) =>
          `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_DETAILS}`,
        validation: Yup.string(),
        company_name: Yup.string().typeError(
          <FormattedMessage
            id="validation_empty_company_name"
            defaultMessage="Enter Company Name"
          />
        ),
      },
      {
        title: "registered_date",
        db_field: "created_at",
        type: "dateTime",
        sortable: true,
      },
      {
        title: "customer_order",
        width: "100px",
        db_field: "orders_count",
        type: "text",
        sortable: true,
        sortField: "orders_count",
        formatter: value => getFormattedNumber(value),
      },

      {
        title: "label_credits",
        db_field: "available_credits",
        type: "text",
        sortable: true,
        sortField: "subscription.available_credits",
        width: "100px",
        formatter: (value, data) => {
          return data["subscription.status"] === SUBSCRIPTION_STATUS.ACTIVE &&
            data["subscription.available_credits"]
            ? `${data["subscription.available_credits"]} / ${data["subscription.total_credits"]}`
            : "-";
        },
      },
      {
        title: "label_payments",
        db_field: "invoice",
        type: "text",
        sortable: true,
        width: "11%",
        formatter: (value, data) => {
          if (data["subscription.status"] === "active") {
            return messages.subscription;
          } else if (data["invoice"] === 0) {
            return messages.klarna;
          } else {
            return messages.invoice;
          }
        },
      },
      {
        title: "label_status",
        db_field: "is_active",
        type: "enum",
        sortable: true,
        width: "120px",
        formatter: value => (value ? messages.active : messages.inactive),
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            type: "link",
            href: ({ id }) =>
              `${ROUTES.ADMIN_CUSTOMERS.URL}/${id}/${ADMIN_CUSTOMER_DETAILS}`,
          },
        ],
      },
    ],
  };

  let headerConfig = [
    {
      title: messages.title_customers,
    },
  ];

  return (
    <AdminContainer config={headerConfig} wide>
      {showAddCustomerDialog && (
        <CustomerAccountDialog handleClose={handleAddNewCustomer} />
      )}

      <DataTable config={dataTableConfig} />
    </AdminContainer>
  );
};

export default List;
