import React from "react";
import { useIntl } from "react-intl";

import styled from "styled-components";

import DataTable from "@components/DataTableV2/DataTable";
import Div from "@components/Div";

import { ORDERS_API } from "@utils/constant";

const StyledDiv = styled(Div)`
  .p-datatable-custom.p-datatable.p-component.p-datatable-hoverable-rows.p-datatable-selectable-cell.p-datatable-responsive-scroll {
    .p-datatable-wrapper {
      border-radius: 10px;
    }
  }
`;

const SubscriptionOrders = () => {
  const { messages } = useIntl();

  const config = {
    header: {
      title: "label_order_page",
      actions: [],
    },
    enable_filter: false,
    enable_csv_download: false,
    backend_querying: true,
    no_records_message: "datatable_admin_orders_no_objects_found",
    enable_search: false,
    enable_query_builder: true,
    api: {
      resource: `/${ORDERS_API}`,
      method: "GET",
      params: [
        [
          "fields",
          ["id", "created_at", "total_price", "customer_id", "user_id", "type"],
        ],
        ["include", ["customer", "user", "orderItems", "payments"]],
        ["filter[type]", "SO"],
      ],
    },
    columns: [
      {
        title: "watchlist_title_order_id",
        db_field: "id",
        type: "text",
        width: "100px",
        sortable: true,
      },
      {
        title: "label_date",
        db_field: "created_at",
        type: "dateTime",
        sortable: true,
      },
      {
        title: "label_purchaser",
        db_field: "user.name",
        type: "text",
        sortable: true,
        sortField: "user.firstname",
        formatter: value => value || messages.label_deleted_user,
      },
      {
        title: "package_label",
        db_field: "order_items",
        type: "text",
        sortable: true,
        sortField: "orderItems.name",
        formatter: (value = []) => {
          const [item = {}] = value;
          return item?.name || "-";
        },
      },
      {
        title: "type_label",
        db_field: "order_items",
        type: "text",
        formatter: (value = []) => {
          const [item = {}] = value;
          let { service_type = "" } = item;
          service_type = service_type.toLowerCase();
          return messages[`service_type_${service_type}`] || service_type;
        },
        sortable: true,
        sortField: "orderItems.service_type",
      },
      {
        title: "watchlist_label_price",
        db_field: "total_price",
        type: "currency",
        sortable: true,
      },
    ],
  };

  return (
    <StyledDiv>
      <DataTable config={config} />
    </StyledDiv>
  );
};

export default SubscriptionOrders;
