import React from "react";
import { withRouter} from "react-router-dom";
import Proptypes from "prop-types";
import AdminLayout from "./AdminLayout";
import CustomerLayout from "./CustomerLayout";
import CustomerGuestLayout from "./GuestLayout";
import useAuthorization from "@src/hooks/useAuthorization";
import BankIDLoginReminderDialog from "@pages/shared/BankIDLoginReminderDialog";
function Layout(props) {
  const { children } = props;
  const { isAdmin, isCustomer } = useAuthorization();
  return (
    <>
      {isAdmin && <AdminLayout>{children}</AdminLayout>}
      {isCustomer && <CustomerLayout>{children}</CustomerLayout>}
      {!isAdmin && !isCustomer && (
        <CustomerGuestLayout>{children}</CustomerGuestLayout>
      )}
      {(isAdmin || isCustomer) && <BankIDLoginReminderDialog />}
    </>
  );
}
Layout.propTypes = {
  children: Proptypes.node,
};
export default withRouter(Layout);
