import { OTHERS } from "@utils/constant";
import { PERMISSION_TYPE, ROLE_TYPE, USER_TYPE } from "@utils/enum";
import { isArraysEqual } from "@utils/utils";

const sortPermissionsBySubjectAndAction = (a, b) => {
  const [, aAction, ...aSubject] = a.split("-");
  const [, bAction, ...bSubject] = b.split("-");

  const aSubjectString = aSubject.join("-");
  const bSubjectString = bSubject.join("-");

  const subjectComparison = aSubjectString.localeCompare(bSubjectString);

  if (subjectComparison !== 0) {
    return subjectComparison;
  }
  if (aAction === PERMISSION_TYPE.READ && bAction === PERMISSION_TYPE.WRITE)
    return -1;
  if (aAction === PERMISSION_TYPE.WRITE && bAction === PERMISSION_TYPE.READ)
    return 1;

  return 0;
};

export const formatRolesDataPermissions = rolesData => {
  return rolesData?.reduce((acc, { value, permissions = [] }) => {
    acc[value] = permissions?.map(({ name }) => name);

    return acc;
  }, {});
};

export const getSelectedPermissions = permissions => {
  return Object.values(permissions).flatMap(group =>
    Object.entries(group)
      .filter(([, value]) => value)
      .map(([key]) => key)
  );
};

export const determineRoleToPick = (
  rolesPermissions,
  selectedPermissions,
  portal
) => {
  const fallbackRole =
    portal === USER_TYPE.CUSTOMER ? ROLE_TYPE.CUSTOM : ROLE_TYPE.ADMIN_CUSTOM;

  for (const [roleName, rolePermissions] of Object.entries(rolesPermissions)) {
    if (isArraysEqual(rolePermissions, selectedPermissions)) {
      return roleName;
    }
  }

  return fallbackRole;
};

export const updateSelectedPermissions = (
  selectedPermissions,
  pickedPermission,
  checked
) => {
  if (checked) {
    selectedPermissions.push(pickedPermission);
  } else {
    const index = selectedPermissions.findIndex(
      permission => permission === pickedPermission
    );

    if (index !== -1) {
      selectedPermissions.splice(index, 1);
    }
  }
};

export const formatPermissionsToFormStructure = (
  groupedPermissions,
  selectedUserPermissions
) => {
  const formPermissions = Object.entries(groupedPermissions).reduce(
    (acc, [permissionsCategory, permissionsObject]) => {
      permissionsObject.options?.forEach(option => {
        const userHasPermission = selectedUserPermissions?.some(
          selectedPermission => selectedPermission.name === option.value
        );

        if (!userHasPermission) {
          return;
        }

        if (!acc[permissionsCategory]) {
          acc[permissionsCategory] = {};
        }

        acc[permissionsCategory][option.value] = true;
      });

      return acc;
    },
    {}
  );

  return formPermissions;
};

export const getPermissionsByGroup = (permissionsData = []) => {
  const groupedPermissions = Object.groupBy(
    permissionsData,
    ({ group }) => group || OTHERS
  );

  const formattedPermissions = Object.entries(groupedPermissions).reduce(
    (acc, [key, value]) => {
      const [{ groupName = "" } = {}] = value ?? [];
      const sortedPermissions = value.sort(
        ({ value: aValue }, { value: bValue }) => {
          return sortPermissionsBySubjectAndAction(aValue, bValue);
        }
      );

      acc[key] = {
        title: groupName,
        options: sortedPermissions,
      };

      return acc;
    },
    {}
  );

  return formattedPermissions;
};

export const getPermissionsByRole = (selectedRole = "", rolesData = []) => {
  if (!selectedRole || !rolesData?.length) {
    return [];
  }

  const permissionsGroupedByRole = Object.groupBy(
    rolesData,
    ({ value }) => value
  );
  const [{ permissions: pickedRolePermissions = [] }] =
    permissionsGroupedByRole?.[selectedRole] ?? [];

  return pickedRolePermissions;
};

export const dividePermissionsEqually = (categorizedPermissions = []) => {
  categorizedPermissions.sort(
    (a, b) => b[1].options.length - a[1].options.length
  );

  const firstHalf = [];
  const secondHalf = [];

  let firstHalfTotalOptions = 0;
  let secondHalfTotalOptions = 0;

  categorizedPermissions.forEach(([category, details]) => {
    const optionsCount = details.options.length;

    if (firstHalfTotalOptions <= secondHalfTotalOptions) {
      firstHalf.push([category, details]);
      firstHalfTotalOptions += optionsCount;
    } else {
      secondHalf.push([category, details]);
      secondHalfTotalOptions += optionsCount;
    }
  });

  return {
    firstHalf,
    secondHalf,
  };
};
