import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import customerService from "@app/services/customers/customerService";
import userService from "@app/services/users/userService";
import getAllProducts from "@app/services/products/getAllProducts";

import DataTable from "@components/DataTableV2/DataTable";
import ProgressSpinner from "@components/ProgressSpinner";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import { AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";
import {
  CASES_DATA_STATUS_TYPE,
  CASES_STATUS_TYPE,
  PROCESS_TYPE,
} from "@utils/enum";

const SEARCHABLE_FIELDS = ["id", "customer.company_name", "orderItem.name"];

const fetchAssignees = async () => {
  const querry = `&filter[type]=admin&per_page=100`;
  const { data: { data = [] } = {} } = await userService(querry);

  const formattedData = data
    .filter(({ name = "" }) => name?.trim())
    .map(({ id, name }) => ({
      code: id,
      name,
    }));

  return formattedData;
};

const fetchCustomers = async () => {
  const { data: { data = [] } = {} } = await customerService();

  const formattedData = data
    .filter(({ company_name }) => company_name?.trim())
    .map(({ company_name }) => ({
      code: company_name,
      name: company_name,
    }));

  return formattedData;
};

const fetchProducts = async () => {
  const { data: { data = [] } = {} } = await getAllProducts(
    "?filter[type]=service&per_page=100"
  );

  const formattedData = data
    .filter(({ is_case }) => is_case)
    .map(({ name }) => ({ code: name, name }));

  return formattedData;
};

const CasesListTab = () => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { showErrorToast } = useToast();

  const [assignees, setAssignees] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterValuesLoaded, setIsFilterValuesLoaded] = useState(false);

  const hasReadCasesActivityLogPermission = hasAllPermissions([
    AdminPermissions.AdminReadCasesActivityLog,
  ]);
  const hasReadDataSourcesPermission = hasAllPermissions([
    AdminPermissions.AdminReadDataSources,
  ]);
  const hasReadReportAnalysisPermission = hasAllPermissions([
    AdminPermissions.AdminReadReportAnalysis,
  ]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        setIsLoading(true);

        const [assigneesData, customersData, productsData] = await Promise.all([
          fetchAssignees(),
          fetchCustomers(),
          fetchProducts(),
        ]);

        setAssignees(assigneesData);
        setCustomers(customersData);
        setProducts(productsData);

        setIsFilterValuesLoaded(true);
      } catch (error) {
        showErrorToast(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFilters();
  }, [showErrorToast, messages]);

  const formatAssignee = (value, event) => {
    const process_type = event["product.process_type"];
    const isEmptyAssignee = !value;

    if (!isEmptyAssignee) {
      return value;
    }

    if (process_type === PROCESS_TYPE.MANUAL) {
      return messages.unassigned;
    } else {
      return "-";
    }
  };

  const formatSourceStatus = value => {
    const availableStatusLabels = {
      [CASES_DATA_STATUS_TYPE.PENDING]: messages.label_cases_status_pending,
      [CASES_DATA_STATUS_TYPE.MANUAL]: messages.label_manual,
      [CASES_DATA_STATUS_TYPE.COMPLETED]: messages.completed,
    };
    const result = String(availableStatusLabels?.[value] ?? "-");

    return result;
  };

  const formatReport = (value, event) => {
    const { report_id } = event;
    const reportString = report_id
      ? messages.report_status_completed
      : messages.label_pending;
    const shouldShowReport = value || event["is_report"];
    const result = String(shouldShowReport ? reportString : "-");

    return result;
  };

  const formatCustomer = (value, values) => {
    const companyName = values?.["customer.company_name"];
    const valueToPresent = value || companyName;

    return valueToPresent || "-";
  };

  const handleCustomerDetailsRedirection = value => {
    const customerId = value?.["customer.id"];

    return customerId ? `/admin/kunder/${customerId}/detaljer` : "";
  };

  const redirectToCaseDetails = ({ id }) =>
    ROUTES.ADMIN_CASE_DETAILS.URL.replace(":case_id", id);

  const redirectToSourceData = ({ id }) =>
    `${ROUTES.ADMIN_CASE_SOURCE_DATA.URL.replace(":case_id", id)}`;

  const redirectToComments = ({ id }) =>
    `${ROUTES.ADMIN_CASE_COMMENTS.URL.replace(":case_id", id)}`;

  const redirectToReport = ({ report_id }) =>
    report_id ? `${ROUTES.ORDER_RESULTS.URL}?id=${report_id}` : "";

  const shouldHideReport = ({ report_id }) =>
    report_id === null || !hasReadReportAnalysisPermission;

  const redirectToActivityLog = ({ id }) =>
    ROUTES.ADMIN_CASE_ACTIVITY_LOG.URL.replace(":case_id", id);

  const shouldHideSourceDataAction = () => !hasReadDataSourcesPermission;

  const shouldHideActivitySourceAction = () =>
    !hasReadCasesActivityLogPermission;

  const dataSourceStatusFilterOptions = [
    {
      name: messages.label_cases_status_pending,
      code: CASES_DATA_STATUS_TYPE.PENDING,
    },
    {
      name: messages.label_manual,
      code: CASES_DATA_STATUS_TYPE.MANUAL,
    },
    {
      name: messages.completed,
      code: CASES_DATA_STATUS_TYPE.COMPLETED,
    },
  ];
  const caseStatusFilterOptions = [
    {
      name: messages.in_progress,
      code: CASES_STATUS_TYPE.IN_PROGRESS.replace("_", "-"),
    },
    {
      name: messages.completed,
      code: CASES_STATUS_TYPE.COMPLETED,
    },
    {
      name: messages.cancelled,
      code: CASES_STATUS_TYPE.CANCELLED,
    },
    {
      name: messages.label_pending,
      code: CASES_STATUS_TYPE.PENDING,
    },
    {
      name: messages.label_manual,
      code: CASES_STATUS_TYPE.MANUAL,
    },
  ];

  const dataTableConfig = {
    header: {
      actions: [],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_filter: true,
    is_filter_options_updated: isFilterValuesLoaded,
    filters: [
      {
        title: messages.label_customer,
        id: "filter[customer.company_name]",
        type: "multiselect",
        options: [
          {
            id: "filter[customer.company_name]",
            name: "filter[customer.company_name]",
            value: "",
            placeholder: messages.placeholder_choose,
            options: customers,
          },
        ],
      },
      {
        title: messages.registered_date,
        id: "filter[created_at]",
        type: "calendar",
        options: [
          {
            id: ["filter[created_at][gte]", "filter[created_at][lte]"],
            label: messages.calendar_date_select_placeholder,
            value: "",
          },
        ],
      },
      {
        title: messages.label_product,
        id: "filter[orderItem.name]",
        type: "multiselect",
        options: [
          {
            id: "filter[orderItem.name]",
            name: "filter[orderItem.name]",
            value: "",
            placeholder: messages.placeholder_choose,
            options: products,
          },
        ],
      },
      {
        title: messages.label_assignee,
        id: "filter[user_id]",
        type: "multiselect",
        options: [
          {
            id: "filter[user_id]",
            name: "filter[user_id]",
            value: "",
            placeholder: messages.placeholder_choose,
            options: assignees,
          },
        ],
      },
      {
        title: messages.label_data_status,
        id: "filter[data_sources_status]",
        type: "multiselect",
        options: [
          {
            id: "filter[data_sources_status]",
            name: "filter[data_sources_status]",
            value: "",
            placeholder: messages.placeholder_choose,
            options: dataSourceStatusFilterOptions,
          },
        ],
      },
      {
        title: messages.label_status,
        id: "filter[status]",
        type: "multiselect",
        options: [
          {
            id: "filter[status]",
            name: "filter[status]",
            value: "",
            placeholder: messages.placeholder_choose,
            options: caseStatusFilterOptions,
          },
        ],
      },
    ],
    enable_csv_download: true,
    csv_filename: "cases.csv",
    backend_querying: true,
    no_records_message: "no_results_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: "/cases",
      method: "GET",
      search_fields: SEARCHABLE_FIELDS,
      params: [["include", ["customer", "orderItem", "user"]]],
    },
    columns: [
      {
        db_field: "id",
        title: "label_id",
        type: "id",
        width: "75px",
        sortable: true,
        plainText: true,
      },
      {
        db_field: "customer.company_name",
        title: "title_customer_info",
        type: "link",
        formatter: formatCustomer,
        href: handleCustomerDetailsRedirection,
      },
      {
        db_field: "created_at",
        title: "title_registered",
        type: "dateTime",
        width: "175px",
        sortable: true,
      },
      {
        db_field: "order_item.name",
        title: "label_product",
        type: "text",
        className: "produceName",
      },
      {
        db_field: "user.name",
        title: "label_assignee",
        type: "text",
        width: "12%",
        formatter: formatAssignee,
      },
      {
        db_field: "order_item.person_initials",
        title: "datasource_candidate",
        width: "80px",
      },
      {
        db_field: "data_sources_status",
        title: "label_data_status",
        type: "enum",
        width: "120px",
        formatter: formatSourceStatus,
      },
      {
        db_field: "status",
        title: "label_status",
        type: "enum",
        width: "120px",
      },
      {
        db_field: "is_report",
        title: "label_report",
        type: "text",
        formatter: formatReport,
        width: "120px",
        isHidden: true,
      },
      {
        type: "actions",
        actions: [
          {
            label: messages.label_case_details,
            icon: "icon-headerarrowright",
            type: "link",
            href: redirectToCaseDetails,
          },
          {
            label: messages.label_source_data,
            icon: "icon-file-download",
            type: "link",
            href: redirectToSourceData,
            shouldHideAction: shouldHideSourceDataAction,
          },
          {
            label: messages.label_comments,
            icon: "icon-comment",
            type: "link",
            href: redirectToComments,
          },
          {
            label: messages.see_report,
            icon: "icon-menu-report",
            type: "link",
            href: redirectToReport,
            shouldHideAction: shouldHideReport,
          },
          {
            label: messages.activity_log,
            icon: "icon-productsA",
            type: "link",
            href: redirectToActivityLog,
            shouldHideAction: shouldHideActivitySourceAction,
          },
        ],
      },
    ],
  };

  return (
    <>
      {isLoading && <ProgressSpinner />}

      <DataTable config={dataTableConfig} />
    </>
  );
};

export default CasesListTab;
