import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import styled from "styled-components";

import { PrimaryButton } from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import Icon from "@components/Icon";
import { Text, TextMediumWeight } from "@components/Text";

import { useToast } from "@hooks/useToast";

const StyledLinkText = styled(TextMediumWeight)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

const CopyLinkDialog = ({ link, onClose }) => {
  const { messages } = useIntl();
  const { showErrorMessage, showToast } = useToast();

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(link);

      showToast("info", messages.label_copylink);
    } catch (error) {
      showErrorMessage(messages.label_error);
    } finally {
      onClose();
    }
  };

  const renderHeader = () => (
    <Div display="flex" justifyContent="center">
      <H3>{messages.label_success}</H3>
    </Div>
  );

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 1, "450px", "450px"]}
      m={[3, 3, "auto", "auto"]}
      header={renderHeader}
      onHide={onClose}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap={3}
        pt={3}
      >
        <Text textAlign="center">{`${messages.message_link_generated}:`}</Text>

        <Div
          border="1px dashed var(--turquoise)"
          display="flex"
          justifyContent="center"
          gridGap={2}
          width={1}
          px="24px"
          py={3}
          borderRadius="10px"
          backgroundColor="var(--site-background-color)"
        >
          <Icon
            name="link"
            color="var(--turquoise)"
            fontSize="var(--fs-icon-m)"
          />
          <StyledLinkText>{link}</StyledLinkText>
        </Div>

        <Div display="flex" justifyContent="center" width={1}>
          <PrimaryButton
            label={messages.label_copy_link}
            onClick={handleCopyLink}
            width={[1, 1, "fit-content", "fit-content"]}
            minWidth="200px"
          />
        </Div>
      </Div>
    </Dialog>
  );
};

CopyLinkDialog.propTypes = {
  link: PropTypes.string,
  onClose: PropTypes.func,
};

export default CopyLinkDialog;
