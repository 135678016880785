import React from "react";

import DataTable from "@components/DataTableV2/DataTable";
import ProgressSpinner from "@components/ProgressSpinner";
import TabsNavigator from "@components/TabsNavigator";

import AdminContainer from "@layout/AdminContainer";

import { TAB_CONTENT } from "./constants";
import useUnbilledAccounts from "./useUnbilledAccounts";

import ConfirmInvoiceModal from "../ConfirmInvoiceModal";

const UnBilledAccounts = () => {
  const {
    dataTableConfig,
    handleActiveIndexChange,
    handleCloseConfirmDialog,
    handleConfirmInvoice,
    headerConfig,
    invoiceDialogType,
    loading,
    tentativeDetails,
  } = useUnbilledAccounts();

  const shouldShowConfirmDialog = tentativeDetails !== null;

  return (
    <AdminContainer config={headerConfig} wide>
      {loading && <ProgressSpinner />}

      {shouldShowConfirmDialog && (
        <ConfirmInvoiceModal
          type={invoiceDialogType}
          data={tentativeDetails}
          onCancel={handleCloseConfirmDialog}
          onSubmit={handleConfirmInvoice}
        />
      )}

      <TabsNavigator
        content={TAB_CONTENT}
        onActiveIndexChange={handleActiveIndexChange}
      />

      <DataTable config={dataTableConfig} resetData={!loading} />
    </AdminContainer>
  );
};

export default UnBilledAccounts;
