import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { default as Div } from "@components/Div";
import Icon from "@components/Icon";
import Link, { LinkArrow } from "@components/Link";
import Sidebar from "@components/Sidebar";
import { H3 } from "@components/Heading";
import {
  LOGIN,
  BOOK_DEMO,
  USER_PROFILE,
  BACKGROUND_SE,
  HEADER_NAV_MAIN,
  MYACCOUNTS_NAV,
  HEADER_NAV,
} from "@utils/constant";
import { Text, TextMediumWeight } from "@components/Text";
import Skeleton from "@components/Skeleton";
import useAuthorization from "@hooks/useAuthorization";
import { isAuthenticated } from "@utils/utils";
import Logo from "@components/Logo";
import LogoFull from "@assets/logo.png";

import { Menubar as PRStyledMenubar } from "primereact/menubar";
import { compose, space, flexbox, layout } from "styled-system";
import styled from "styled-components";
import breakpoints from "@utils/breakpoints";
import Config from "@src/config";
import useHistory from "@utils/useHistory";
import logoutService from "@app/services/auth/logoutService";
import {
  ButtonLinkIcon,
  ButtonRaisedIcon,
  PrimaryButtonOutlined,
} from "@components/Button";
import SearchBar from "@components/DataTable/Utils/SearchBar";
import Multiselect from "@components/DataTable/Utils/Multiselect";
import UtilCalendar from "@components/DataTable/Utils/Calendar";
import CheckBoxes from "@components/DataTable/Utils/Checkbox";
import SideMenuContent from "./SideMenuContent";
import { useUser } from "@contexts/AuthProvider";

import Menu from "@components/Menu";

const mobileBreakPoint = Config.mobileBreakPoint;
const MenuBar = styled(PRStyledMenubar)`
  position: sticky;
  max-height: 80px;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--white);
  border: none;
  .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    box-shadow: none;
  }
  ${compose(space, flexbox, layout)};
  .p-menubar-root-list {
    width: -webkit-fill-available;
    .p-menuitem {
      .p-menuitem-link {
        padding: 0px 15px;
      }
    }
  }
  .p-menubar-button {
    display: none;
  }
  .p-menubar-start {
    white-space: nowrap;
  }
  .p-menubar-end {
    height: 60px;
    cursor: pointer;
    display: inline-flex;
    & > div:first-child {
      display: flex;
      flex-direction: row;
      height: 100%;
      &:hover {
        border-bottom: 4px solid var(--blue-medium); /* Background color on hover */
      }
    }
  }
  &.p-menubar.p-component {
    padding: 0px;
    background: var(--white);
    .p-menubar-root-list {
      padding-left: 20px;
      padding-top: 5px;
      .p-menuitem {
        &:last-child {
          .p-menuitem-link {
            &:hover {
              background: var(--transparent);
            }
            .p-menuitem-text {
              &:hover {
                border-bottom: 5px solid transparent;
              }
            }
          }
        }
        .p-menuitem-link {
          background: transparent;
          transition: none !important;
          &:hover {
            background: var(--white);
          }
          .p-menuitem-text {
            padding-bottom: 30px;
            border-bottom: 5px solid transparent;
            color: var(--grey-dark) !important;
            font-size: var(--fs-text-m);
            line-height: 22px;
            font-weight: var(--medium-weight) !important;
            white-space: nowrap;
            &:hover {
              border-bottom: 5px solid var(--blue-medium);
              color: var(--blue-medium) !important;
            }
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoints[mobileBreakPoint]}px) {
    min-height: 83px;

    .p-menubar-button,
    .p-menubar-end {
      display: none !important;
    }
    .p-menubar-start {
      width: 100%;
      display: flex;
      align-content: space-between;
      justify-content: space-between;
      align-items: center;
    }
    &.p-menubar.p-component {
      padding: 10px 16px;
      width: 100%;
      .p-menubar-root-list {
        display: none !important;
      }
    }
  }
`;

const ButtonRaisedIconCustom = styled(ButtonRaisedIcon)`
  background-color: inherit !important;
  box-shadow: none !important;
  padding: 8px 16px 8px 0;
  margin: 0;
  color: var(--grey-dark) !important;
  i {
    font-weight: var(--semibold-weight);
    color: var(--turquoise);
    margin-bottom: 2px;
  }
`;

const TransparentButtonCustom = styled(ButtonRaisedIcon)`
  background-color: none;
  text-transform: uppercase;
  font-weight: var(--medium-weight) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  border: none;
  padding: 8px 16px;
  .p-button-label {
    flex: none;
  }
  i {
    color: var(--blue-dark);
    font-weight: var(--medium-weight);
    font-size: var(--fs-icon-l);
    &:hover {
      color: var(--blue-dark);
    }
  }
`;

const BookDemoButton = styled(PrimaryButtonOutlined)`
  display: flex;
  justify-content: center;
  height: 40px;
  width: 118px;
  border-color: var(--yellow);
  &.p-button-rounded {
    color: var(--blue-dark);
    border: 2px solid var(--yellow) !important;
    .p-button-label {
      color: var(--yellow);
      font-size: var(--fs-text-m);
      line-height: 22px;
      font-weight: var(--semibold-weight);
    }
    &:not(a):not(.p-disabled):hover {
      background-color: var(--yellow) !important;
      border: 2px solid var(--yellow);
      .p-button-label {
        color: var(--grey-dark) !important;
      }
    }
  }
`;

const WebSideBar = () => {
  const [visibleFullScreen, setVisibleFullScreen] = useState(false);
  const isAuthenticatedFlag = isAuthenticated();
  const { messages } = useIntl();
  const history = useHistory();
  const { hasAnyPermissions } = useAuthorization();
  const { clearUser } = useUser();

  const navProps = {
    navItems: HEADER_NAV,
  };
  const sidemenuNavProps = {
    navItems: MYACCOUNTS_NAV.filter(obj => {
      const { permissions = [] } = obj;
      return hasAnyPermissions(permissions);
    }),
  };
  const handleRedirectToWPpage = url => {
    window.location = url;
  };

  const redirectToLogin = () => {
    clearUser();
    history.push(LOGIN);
  };

  const getBookDemoButton = () => {
    return (
      <Div
        alignItems={"center"}
        display="flex"
        mb={[3, 3, 3, 0]}
        justifyContent="center"
      >
        <BookDemoButton
          px="20px"
          label={messages.book_demo}
          onClick={handleRedirectToWPpage.bind(this, BOOK_DEMO)}
        />
      </Div>
    );
  };

  const supportPagesContent = () => {
    return (
      <Div py={3} borderTop="1px solid var(--turquoise-light)">
        <LinkArrow
          height={44}
          px={2}
          display={"flex"}
          href={`${Config.WP_URL}/kontakta-oss/`}
          target="_blank"
          rel="noreferrer"
        >
          <TextMediumWeight wordBreak="break-all" fontSize={"14px !important"}>
            {messages.support}
          </TextMediumWeight>
        </LinkArrow>
        <LinkArrow
          height={46}
          px={2}
          display={"flex"}
          href={`${BACKGROUND_SE}`}
          target="_blank"
          rel="noreferrer"
        >
          <TextMediumWeight wordBreak="break-all" fontSize={"14px !important"}>
            {messages.ba_website}
          </TextMediumWeight>
          <Icon name="Arrow-diagonal" ml={2} />
        </LinkArrow>
      </Div>
    );
  };

  const redirectToUrl = url => {
    history.push(url);
  };

  const menuItem = (label, url) => {
    return (
      <Div width={1} p={"12px 20px"} onClick={redirectToUrl.bind(this, url)}>
        <TextMediumWeight
          wordBreak="break-all"
          fontSize={"var(--fs-text-small) !important"}
          letterSpacing={"0.3px !important"}
        >
          {label}
        </TextMediumWeight>
      </Div>
    );
  };

  return (
    <>
      <Link
        label={messages.header_menu}
        onClick={() => setVisibleFullScreen(true)}
        darkblue
        variant="primary"
        flexDirection={"row-reverse !important"}
        display={["flex", "flex", "flex", "none"]}
      >
        <Icon name="hamburger" pr={2} />
      </Link>
      <Sidebar
        visible={visibleFullScreen}
        fullScreen
        icons={
          <H3 textTransform="none" pl={1}>
            {messages.label_menu_sidebar}
          </H3>
        }
        onHide={() => setVisibleFullScreen(false)}
      >
        <Div
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          {isAuthenticatedFlag ? (
            <Div>
              <SideMenuContent {...sidemenuNavProps} mobileView={true} />
            </Div>
          ) : (
            <Div>
              <SideMenuContent {...navProps} />
              {getBookDemoButton()}
            </Div>
          )}

          <Div>
            <Div py={3} borderTop="1px solid var(--turquoise-light)">
              {isAuthenticatedFlag ? (
                <>
                  {menuItem(messages.personal_setting, USER_PROFILE)}
                  <Div width={1} p={"12px 20px"} onClick={redirectToLogin}>
                    <TextMediumWeight
                      wordBreak="break-all"
                      fontSize={"var(--fs-text-small) !important"}
                      letterSpacing={"0.3px !important"}
                    >
                      {messages.label_log_out}
                    </TextMediumWeight>
                  </Div>
                </>
              ) : (
                <Div width={1} p={"12px 20px"} onClick={redirectToLogin}>
                  <TextMediumWeight
                    wordBreak="break-all"
                    fontSize={"var(--fs-text-small) !important"}
                    letterSpacing={"0.3px !important"}
                  >
                    {messages.login}
                  </TextMediumWeight>
                </Div>
              )}
            </Div>
            {supportPagesContent()}
          </Div>
        </Div>
      </Sidebar>
    </>
  );
};

const UserHeaderMain = ({ config, isLoading }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const { messages } = useIntl();
  const history = useHistory();
  const endRef = useRef(null);
  const { clearUser } = useUser();
  const userInfo = useSelector(state => state.authReducer.userInfo);
  const { name } = userInfo;
  const passedData = Array.isArray(config) ? config[0] : config;
  const shouldShowLoader = isLoading || !passedData.title;

  const handleLogout = async () => {
    await logoutService(localStorage.getItem("token"));
    clearUser();
    history.push(LOGIN);
  };

  const handleRedirect = () => {
    window.location = `${Config.WP_URL}/`;
  };

  const handleToggle = event => {
    setMenuVisible(menuVisible => !menuVisible);
    endRef.current.toggle(event);
  };

  let items = HEADER_NAV_MAIN.map(item => {
    const {
      redirectTo = "",
      isWpPage = "",
      value = "",
      preventRedirect = false,
      isDropDown = false,
    } = item;

    if (isWpPage) {
      item.url = redirectTo;
    } else if (!isDropDown) {
      item.command = () => {
        if (!preventRedirect) {
          history.push(value);
        }
      };
    }
    return item;
  });
  items.push({
    label: (
      <Div>
        <Icon
          color="var(--turquoise)"
          mr={"8px"}
          fontSize={"var(--fs-text-secondary) !important"}
          name={"logout"}
        />
        {messages.logout_term}
      </Div>
    ),
    command: () => {
      handleLogout();
    },
  });

  const start = () => (
    <>
      <Div>
        <Logo
          logo={LogoFull}
          width="auto"
          maxHeight={["40px", "60px", "60px", "60px"]}
          onClick={handleRedirect}
          display={["flex", "flex", "flex", "none"]}
        />

        <Text
          color="var(--blue-dark) !important"
          fontWeight="var(--semibold-weight) !important"
          fontSize={"var(--fs-h2) !important"}
          wordBreak="none !important"
          display={["none", "none", "none", "flex"]}
        >
          {shouldShowLoader ? (
            <Skeleton height={20} width={200} />
          ) : (
            passedData.title
          )}
        </Text>
      </Div>

      <WebSideBar />
    </>
  );

  const item = passedData.headerActions
    ?.filter(({ isHidden }) => !isHidden)
    ?.map(action => ({
      label: action.label,
      template: (
        <div>
          {(() => {
            switch (action.type) {
              case "checkbox":
                return (
                  <CheckBoxes
                    type={action.type}
                    value={action}
                    options={action.options}
                  />
                );
              case "calendar":
                return <UtilCalendar value={action} />;
              case "button":
                return (
                  <ButtonLinkIcon
                    {...action}
                    semibold
                    id={action.id}
                    label={action.label}
                    icon={
                      action.icon ? (
                        <Icon
                          name={action.icon}
                          mr={action.label ? 2 : 0}
                          color="var(--turquoise) !important"
                          fontSize="var(--fs-icon-m)"
                          fontWeight="var(--semibold-weight)"
                          mt={"2px"}
                        />
                      ) : (
                        ""
                      )
                    }
                    color="var(--grey-dark) !important"
                    fontSize="var(--fs-text-m) !important"
                    px={3}
                    py="8px"
                    minWidth={action.minWidth || "40px"}
                    minHeight="30px"
                    display="inline-flex"
                    textAlign="center"
                    whiteSpace
                  />
                );
              case "button-transparent":
                return (
                  <TransparentButtonCustom
                    {...action}
                    key={action.id}
                    id={action.id}
                    mx={3}
                    label={action.label}
                    semibold
                    px="20px"
                    py="6px"
                    minWidth={150}
                    alignSelf="center"
                    icon={<Icon name={action.icon} mr={1} cursor="pointer" />}
                  />
                );
              case "button-raised":
                return (
                  <ButtonRaisedIconCustom
                    {...action}
                    key={action.id}
                    id={action.id}
                    mx={2}
                    label={action.label}
                    /* padding top condition is done for "SCV" button style */
                    icon={
                      <Icon name={action.icon} pr={`${action.label ? 1 : 0}`} />
                    }
                  />
                );
              case "search":
                return <SearchBar type={action} />;
              case "multiselect":
                return (
                  <Multiselect
                    type={action.type}
                    placeholder={action.placeholder}
                    name={action.name}
                    onChange={action.onClick}
                    inputValue={action.inputValue}
                    id={action.id}
                    options={action.options}
                    width={action.width}
                    value={action.value}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
      ),
    }));

  const end = (
    <>
      <Div
        display={["none", "none", "flex"]}
        backgroundColor={"var(--blue-lightest)"}
        px="20px"
        alignItems="center"
        justifyContent="space-between"
        borderBottom={`4px solid ${
          menuVisible ? "var(--blue-medium)" : "transparent"
        }`}
        borderTop="4px solid var(--blue-lightest)"
        whiteSpace
        height={40}
        onClick={handleToggle}
        aria-controls="menu_left"
        aria-haspopup
      >
        <Div display={["none", "none", "flex"]} alignItems="center">
          <Icon pr={13} color="var(--blue-medium) !important" name="avatar" />
          <Link fontSize="16px" label={name} />
        </Div>
        <Icon
          pl={13}
          fontSize={"6px"}
          color="var(--blue-medium) !important"
          name="arrowbig-down"
        />
      </Div>

      <Menu
        popup
        model={items}
        forwardRef={endRef}
        id="popup_menu"
        onHide={() => setMenuVisible(false)}
      />
    </>
  );

  return (
    <Div mx={[0, 0, "16px", "32px"]}>
      <MenuBar model={item} start={start} end={end} width={[1, 1, 1]} />
    </Div>
  );
};

UserHeaderMain.propTypes = {
  config: PropTypes.object,
  isLoading: PropTypes.bool,
  navItems: PropTypes.array,
  sideMenuNavItems: PropTypes.array,
  children: PropTypes.node,
};

export default UserHeaderMain;
