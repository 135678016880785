import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Container from "@components/Container";
import Div from "@components/Div";
import Breadcrumb from "@components/Breadcrumb";

import useAuthorization from "@hooks/useAuthorization";

import CandidateOrderDetails from "@pages/shared/orderDetails/CandidateOrderDetails";

import { CustomerPermissions, AdminPermissions } from "@src/enum/Permissions";

import { ROUTES } from "@utils/constant";

import OrderBillingCard from "./OrderBillingCard";
import OrderInfo from "./OrderInfo";
import Timelog from "./Timelog";
import TotalPriceCard from "./TotalPriceCard";
import { PrimaryButtonIconOutlined } from "@components/Button";
import Icon from "@components/Icon";
import ConfirmationPopup from "@components/ConfirmationPopup";
import { useToast } from "@hooks/useToast";
import { useHistory } from "react-router-dom";
import { deleteOrderDetails } from "../../../app/services/orders/deleteOrderDetails";

const OrderDetails = ({
  orderSummaryProps,
  redirectTo = ROUTES.ORDERS_LIST.URL,
  isCustomer,
  onUpdateOrderSummaryProps,
}) => {
  const {
    showTimeLog = isCustomer,
    order_id: orderNum = "",
    watchList = {},
    reports = [],
    assignments = [],
    order_items: orderItems = [],
    payments = [],
    status: orderStatus,
    total_vat,
    price_after_adjustments,
  } = orderSummaryProps;

  const { messages } = useIntl();
  const { hasAllPermissions, isAdmin } = useAuthorization();
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();
  const history = useHistory();

  const isBillingPermission = isAdmin
    ? hasAllPermissions([AdminPermissions.AdminReadBilling])
    : hasAllPermissions([CustomerPermissions.CustomerReadCompanyInformation]);

  const paymentMethodInvoice = payments.some(
    payment =>
      payment.payment_method_id === 2 || payment.payment_method_id === 5
  );

  const breadCrumbItems = [
    { label: messages.my_profile },
    { label: messages.label_order_page, url: redirectTo },
    { label: `#${orderNum}` },
  ];

  const [report = {}] = reports;

  const hasDeleteAccessToOrders = hasAllPermissions([
    AdminPermissions.AdminDeleteOrders,
  ]);

  const handleDeleteOrder = () => {
    setIsDeleteDialogVisible(true);
  };

  const handleCancelDeleteOrder = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleDeleteSubmit = async () => {
    try {
      await deleteOrderDetails(orderNum);

      showSuccessToast(messages.order_deleted_successful);

      history.push(ROUTES.ADMIN_ORDERS_LIST.URL);
      return;
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  return (
    <Container m="0px !important" mb={40}>
      <Div>
        <Breadcrumb p={0} items={breadCrumbItems} />
      </Div>

      <Div mt={4} display="flex" flexDirection="column" gridGap={3}>
        <OrderInfo
          data={orderSummaryProps}
          isWatchList={watchList && watchList !== null}
        />

        <CandidateOrderDetails
          orderStatus={orderStatus}
          orderItems={orderItems}
          isOrderDetails={true}
          assignments={assignments}
        />

        <TotalPriceCard
          orderTotalVatPrice={total_vat}
          orderTotalPriceWithoutVat={price_after_adjustments}
          orderTotalPriceWithVat={price_after_adjustments + total_vat}
        />

        {paymentMethodInvoice && isBillingPermission && (
          <OrderBillingCard
          orderSummaryProps={orderSummaryProps}
          onUpdateOrderSummaryProps={onUpdateOrderSummaryProps}
        />
        )}

        {showTimeLog && (
          <Timelog logs={report.logs} ssn={report.personal_number} />
        )}
      </Div>

      {hasDeleteAccessToOrders && (
        <Div width={1}>
          <PrimaryButtonIconOutlined
            color="red"
            width={[1, 1, "250px", "250px"]}
            my={3}
            label={messages.label_delete_order}
            onClick={handleDeleteOrder}
            icon={<Icon mr={2} name="rubber" />}
          />

          {isDeleteDialogVisible && (
            <ConfirmationPopup
              title={messages.label_delete_order}
              description={messages.order_delete_message}
              onCancelClick={handleCancelDeleteOrder}
              onAcceptClick={handleDeleteSubmit}
            />
          )}
        </Div>
      )}
    </Container>
  );
};

OrderDetails.propTypes = {
  orderSummaryProps: PropTypes.array,
  redirectTo: PropTypes.string,
  showTimeLog: PropTypes.bool,
  isCustomer: PropTypes.bool,
  isAdmin: PropTypes.bool,
  onUpdateOrderSummaryProps: PropTypes.func,
};

export default OrderDetails;
