import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Container from "@components/Container";
import PropTypes from "prop-types";
import { TextSemiBoldWeight, TextMediumWeight } from "@components/Text";
import Switch from "@components/Switches";
import Div from "@components/Div";
import DataTable from "@components/DataTable/DataTable";
import { H3 } from "@components/Heading";
import { Text } from "@components/Text";
import Dialog from "@components/Dialog";
import { PrimaryButton } from "@components/Button";

import {
  PRODUCTS,
  BUNDLE,
  ANALYSIS,
  ASSIGNMENT,
  serviceTypes,
  SUBSCRIPTION_STATUS,
  STATUS_IN_ACTIVE,
} from "@utils/constant";
import editCustomerInfoService from "@app/services/users/editCustomerInfoService";
import getCustomerProducts from "@app/services/products/getCustomerProducts";
import updateCustomerProductById from "@app/services/products/updateCustomerProductById";
import useAuthorization from "@hooks/useAuthorization";
import { AdminPermissions } from "@src/enum/Permissions";
import { useToast } from "@hooks/useToast";

const Products = ({ customerId, customProduct = false }) => {
  const { messages } = useIntl();
  const [isCustomProduct, setIsCustomProduct] = useState(false);
  const [showCustomerProductDialog, setShowCustomerProductDialog] =
    useState(false);
  const { showErrorToast } = useToast();

  const { hasAllPermissions } = useAuthorization();
  const hasWriteAccessToCustomers = hasAllPermissions([
    AdminPermissions.AdminWriteCustomers,
  ]);
  const updateCustomerDetails = async value => {
    try {
      const response = await editCustomerInfoService({
        id: customerId,
        custom_product: value,
      });
      if (response) {
        if (value) {
          setShowCustomerProductDialog(true);
        }
        setIsCustomProduct(!isCustomProduct);
      }
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  useEffect(() => {
    setIsCustomProduct(customProduct);
  }, [customProduct]);

  const updateProductData = async values => {
    const { id, price_customer, default_selected, default_available } = values;
    const payload = {
      price_customer: Number(price_customer),
      default_available,
      default_selected,
    };

    return await updateCustomerProductById(customerId, id, payload)
      .then(() => {
        return true;
      })
      .catch(() => {
        //catch error
        return [];
      });
  };

  const handleOnChange = () => {
    updateCustomerDetails(!isCustomProduct);
  };

  const handleCloseCustomerProduct = () => {
    setShowCustomerProductDialog(!showCustomerProductDialog);
  };

  const formatServiceData = serviceData => {
    return serviceData.map(obj => {
      const {
        id,
        name,
        service_type,
        listing_order,
        total_price,
        customer_product: {
          price_customer,
          default_selected,
          default_available,
        },
      } = obj;
      const csvFormat = {
        Id: id,
        Name: name,
        "Service type": service_type,
        "Default selected": default_selected ? "YES" : "NO",
        "Default available": default_available ? "YES" : "NO",
        "Listing order": listing_order,
        "Total price": total_price,
        "Customer price": price_customer,
      };
      return {
        ...obj,
        csvFormat,
        default_available,
        default_selected,
        service_type: serviceTypes[service_type],
        price_customer: Number(price_customer),
      };
    });
  };

  const fetchServices = async (lazyParams = "") => {
    const {
      rows = 10,
      sortField = "created_at",
      page = 1,
      sortOrder = 1,
      first = 0,
      search = "",
    } = lazyParams;

    const sort = sortOrder === 1 || sortOrder === "desc" ? "desc" : "asc";
    let payload = `?pagenumber=${page}&pagesize=${rows}&sort=${sort}&sortby=${sortField}`;
    if (search) {
      payload = `${payload}&search=${search}`;
    }

    const urlParam = `${payload}&first=${first}`;
    return await getCustomerProducts(customerId)
      .then(response => {
        const { status } = response;
        const { data } = response.data;

        window.history.pushState(
          null,
          '',
          `${window.location.pathname}?${urlParam}` // the URL with the updated search params
        );
        const productData = formatServiceData(data);
        return { status, data: productData };
      })
      .catch(() => {
        window.history.pushState(
          null,
          '',
          `${window.location.pathname}${urlParam}` // the URL with the updated search params
        );
        return [];
      });
  };

  const config = {
    title: "",
    loadData: fetchServices,
    allowEdit: hasWriteAccessToCustomers,
    columns: [
      {
        field: "id",
        header: messages.label_id,
        body: "general",
        width: "70px",
      },
      {
        field: "name",
        header: messages.label_service_name,
        sortable: true,
        body: "service",
        width: "30%",
      },
      {
        field: "default_selected",
        header: messages.label_default_selected,
        sortable: true,
        body: "select",
        allowEdit: true,
        dropdown: true,
      },
      {
        field: "default_available",
        header: messages.header_availability,
        sortable: true,
        body: "select",
        allowEdit: true,
        dropdown: true,
      },
      {
        field: "price",
        header: messages.standard_price,
        body: "currency",
      },
      {
        field: "price_customer",
        header: messages.custom_price,
        body: "currency",
        allowEdit: true,
      },
    ],
    globalFilterFields: ["id", "name"],
    searchAction: {
      id: "search",
      type: "search",
      variant: "header",
    },
    dataTableActions: [
      {
        id: "filter",
        type: "table-action",
        icon: "filter",
        variant: "header",
        content: messages.filter_description,
        borderRadius: 0,
      },
      {
        id: "download",
        type: "table-action",
        icon: "headerarrowright",
        variant: "header",
        content: messages.download_csv_file,
        px: 3,
      },
    ],
    pagination: true,
    filters: [
      {
        title: messages.label_availability,
        id: "default_available",
        type: "checkbox",
        options: [
          {
            label: messages.yes_label,
            value: 1,
            checked: false,
          },
          {
            label: messages.no_label,
            value: 0,
            checked: false,
          },
        ],
      },
      {
        title: messages.label_service_type,
        id: "service_type",
        type: "checkbox",
        options: [
          {
            label: messages.analysis,
            value: serviceTypes[ANALYSIS],
            checked: false,
          },
          {
            label: messages.assignment,
            value: serviceTypes[ASSIGNMENT],
            checked: false,
          },
          {
            label: messages.servicetype_bundle,
            value: serviceTypes[BUNDLE],
            checked: false,
          },
        ],
      },
      {
        title: messages.label_status,
        id: "state",
        type: "checkbox",
        options: [
          {
            label: messages.active,
            value: SUBSCRIPTION_STATUS.ACTIVE,
            checked: true,
          },
          {
            label: messages.state_in_active,
            value: STATUS_IN_ACTIVE,
            checked: false,
          },
        ],
      },
    ],
    appliedFilters: [],
    headerActions: [],
    filterType: "dialog",
    sortable: true,
    selectionMode: "multiple",
    screenName: PRODUCTS,
    lazyload: false,
    updateData: updateProductData,
    intialSort: "listing_order",
    dropDownOptons: {
      default_available: [
        {
          label: messages.yes_label,
          value: 1,
        },
        {
          label: messages.no_label,
          value: 0,
        },
      ],
      default_selected: [
        {
          label: messages.yes_label,
          value: 1,
        },
        {
          label: messages.no_label,
          value: 0,
        },
      ],
    },
    defaultappliedFilters: [
      {
        id: "state",
        values: ["active"],
        type: "checkbox",
      },
    ],
  };

  return (
    <>
      <Container m={"0px !important"} pt={"16px"}>
        {hasWriteAccessToCustomers && (
          <Div display="flex" alignItems="center">
            <Switch
              name={"default_selected"}
              onChange={handleOnChange}
              checked={isCustomProduct}
              mr={3}
            />
            <TextSemiBoldWeight>
              {`${messages.custom_products}: ${
                isCustomProduct ? messages.yes_label : messages.no_label
              }`}
            </TextSemiBoldWeight>
          </Div>
        )}
        {isCustomProduct && (
          <Div>
            <DataTable config={config} />
          </Div>
        )}
        {!isCustomProduct && (
          <Div py={3}>
            <TextMediumWeight>
              {messages.description_custom_products}
            </TextMediumWeight>
          </Div>
        )}
      </Container>
      {showCustomerProductDialog && (
        <Dialog
          header={<H3 textAlign="center" textTransform="none">{messages.custom_products}</H3>}
          visible="displayBasic"
          draggable={false}
          onHide={handleCloseCustomerProduct}
          width={["90%", "90%", "90%", 445]}
        >
          <Div display="flex" alignItems="center" flexDirection="column">
            <Div my={3}>
              <Text>{messages.updating_custom_product_value}</Text>
            </Div>
            <PrimaryButton
              rounded
              semibold
              label={messages.label_ok}
              onClick={handleCloseCustomerProduct}
              mb={[4, 4, 4, 0]}
              width={[1, 1, 1, "45%"]}
            />
          </Div>
        </Dialog>
      )}
    </>
  );
};

Products.propTypes = {
  customerId: PropTypes.string,
  customProduct: PropTypes.bool,
  setShowCustomerProductDialog: PropTypes.node,
  showCustomerProductDialog: PropTypes.bool,
};
export default Products;
