import React from "react";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";
import Div from "@components/Div";
import { H3 } from "@components/Heading";
import {
  formatDateAndTime,
  convertSEK,
  getFormattedNumber,
} from "@utils/utils";
import {
  Text,
  TextLargeSemiBoldWeight,
  TextMediumSemiBoldWeight,
} from "@components/Text";
import { RECURRING } from "@utils/constant";
import Card from "@components/Card";
import Container from "@components/Container";

import useAuthorization from "@hooks/useAuthorization";
import { CustomerPermissions } from "@src/enum/Permissions";

const OrderInfo = ({ data = {}, isWatchList }) => {
  const { messages } = useIntl();
  const {
    user = {},
    total_price: totalPrice = 0,
    created_at: createdAt = "",
    payments = [],
    status = "",
    watchList = null,
    total_credits: totalCredits = 0,
    order_items: orderItems = [],
    adjustments,
  } = data;
  const [firstPayment = {}] = payments;
  const { payable_type: paymentType = "" } = firstPayment;

  const { name = "", email = "" } = user || {};
  const { is_recurrent = false, notification_emails: notificationEmails } =
    watchList || {};

  const { hasAllPermissions } = useAuthorization();
  const hasPricesPermission = hasAllPermissions([
    CustomerPermissions.CustomerReadPrices,
  ]);

  const findPromoCode = () => {
    let promoCode = "";

    if (!adjustments) {
      return promoCode;
    }

    const [adjustment] = adjustments;

    if (adjustment) {
      promoCode = adjustment?.title ?? promoCode;
    } else {
      if (!orderItems) {
        return promoCode;
      }

      orderItems.forEach(item => {
        if (!item.adjustments) {
          return promoCode;
        }

        const [adjustment] = item.adjustments;

        promoCode = adjustment?.title ?? promoCode;
      });
    }

    return promoCode;
  };

  const promoCode = findPromoCode();

  const renderPrice = () => {
    let updatedPrice = totalPrice;

    if (adjustments?.length > 0) {
      const [adjustment] = adjustments;

      if (adjustment) {
        const discountAmount = (
          Math.round(adjustment.amount * 100) / 100
        ).toFixed(2);

        updatedPrice = Math.max(updatedPrice - discountAmount, 0);
      }
    } else if (orderItems?.length > 0) {
      const discount = orderItems.reduce((accumulator, item) => {
        if (item.adjustments?.length > 0) {
          const [adjustment] = item.adjustments;

          const discountAmount = (
            Math.round(adjustment.amount * 100) / 100
          ).toFixed(2);

          return accumulator + discountAmount;
        }

        return accumulator;
      }, 0);

      updatedPrice = Math.max(updatedPrice - discount, 0);
    }

    if (totalCredits > 0 && totalPrice > 0) {
      return (
        <TextMediumSemiBoldWeight display="inline-block">
          {`${messages.price} `}
          <Text>{`${totalCredits} ${messages.order_info_label_credit} ${
            messages.text_and
          } ${getFormattedNumber(convertSEK(updatedPrice))} SEK`}</Text>
        </TextMediumSemiBoldWeight>
      );
    }

    if (totalCredits > 0) {
      return (
        <TextMediumSemiBoldWeight display="inline-block">
          {`${messages.price} `}
          <Text>{`${totalCredits} ${messages.order_info_label_credit}`}</Text>
        </TextMediumSemiBoldWeight>
      );
    }

    if (!hasPricesPermission) {
      return <Div />;
    }

    return (
      <TextMediumSemiBoldWeight display="inline-block">
        {`${messages.price} `}
        <Text> {`${getFormattedNumber(convertSEK(updatedPrice))} SEK`}</Text>
      </TextMediumSemiBoldWeight>
    );
  };

  const header = (
    <Div width={1} p={3}>
      <Div
        display="flex"
        alignItems={["left", "center"]}
        justifyContent="space-between"
        flexDirection={["column", "row"]}
      >
        <TextLargeSemiBoldWeight mt={2}>
          {messages["title_general_information"]}
        </TextLargeSemiBoldWeight>
      </Div>
    </Div>
  );

  return (
    <Card p={0} maxWidth="1110px" header={header}>
      <Container width={1} minWidth={["0px", "610px"]}>
        <Div p={3} borderTop="1px solid var(--grey-lightest)">
          {status !== "not-paid" ? (
            <>
              {!isWatchList && (
                <Div display="flex" flexDirection={["column", "row"]}>
                  <Div width="100%">
                    <ul>
                      <Div
                        mr={[0, 5]}
                        display="flex"
                        flexDirection="column"
                        gridGap={2}
                      >
                        <li>
                          <TextMediumSemiBoldWeight display="inline-block">
                            {`${messages.label_email}:`}
                            &nbsp;
                            <Text>{`${email}`}</Text>
                          </TextMediumSemiBoldWeight>
                        </li>

                        <li>{renderPrice()}</li>

                        <li>
                          <TextMediumSemiBoldWeight
                            display="inline-block"
                            mr={2}
                          >
                            {`${messages.label_payment_method}:`}
                            &nbsp;
                            <Text>{paymentType}</Text>
                          </TextMediumSemiBoldWeight>
                        </li>
                      </Div>
                    </ul>
                  </Div>

                  <Div width="100%">
                    <ul>
                      <Div
                        mr={[0, 5]}
                        display="flex"
                        flexDirection="column"
                        gridGap={2}
                      >
                        <li>
                          <TextMediumSemiBoldWeight
                            display="inline-block"
                            mr={2}
                          >
                            {`${messages.user}: `}
                            &nbsp;
                            <Text>{name || "-"}</Text>
                          </TextMediumSemiBoldWeight>
                        </li>

                        <li>
                          <TextMediumSemiBoldWeight
                            display="inline-block"
                            mr={2}
                          >
                            {`${messages.label_date_time}: `}
                            &nbsp;
                            <Text>
                              {createdAt ? formatDateAndTime(createdAt) : "-"}
                            </Text>
                          </TextMediumSemiBoldWeight>
                        </li>

                        {promoCode && (
                          <li>
                            <TextMediumSemiBoldWeight
                              display="inline-block"
                              mr={2}
                            >
                              {`${messages.coupon_code_label}: `}
                              &nbsp;
                              <Text>{promoCode}</Text>
                            </TextMediumSemiBoldWeight>
                          </li>
                        )}
                      </Div>
                    </ul>
                  </Div>
                </Div>
              )}

              {isWatchList && (
                <Div display="flex" flexDirection={["column", "row"]}>
                  <Div width={["50%", "100%"]}>
                    <ul>
                      <Div
                        mr={[0, 5]}
                        display="flex"
                        flexDirection="column"
                        gridGap={2}
                      >
                        <li>
                          <TextMediumSemiBoldWeight
                            display="inline-block"
                            mr={2}
                          >
                            {`${messages.watchlist_label_watchlist}: `}
                            &nbsp;
                            <Text>{watchList?.name || "-"}</Text>
                          </TextMediumSemiBoldWeight>
                        </li>
                        <li>
                          <TextMediumSemiBoldWeight
                            display="inline-block"
                            mr={2}
                          >
                            {`${messages.user}: `}
                            &nbsp;
                            <Text>{name || "-"}</Text>
                          </TextMediumSemiBoldWeight>
                        </li>
                        <li>
                          <TextMediumSemiBoldWeight
                            display="inline-block"
                            mr={2}
                          >
                            {`${messages.watchlist_type_label}:`}
                            &nbsp;
                            <Text>
                              {watchList?.watchlist_type == RECURRING
                                ? messages.watchlist_label_automated
                                : messages.watchlist_label_manual}
                            </Text>
                          </TextMediumSemiBoldWeight>
                        </li>
                        <li>
                          <TextMediumSemiBoldWeight
                            display="inline-block"
                            mr={2}
                          >
                            {`${messages.watchlist_additional_notification}:`}
                            &nbsp;
                            <Text>
                              {is_recurrent
                                ? notificationEmails.join(", ")
                                : email.length}
                            </Text>
                          </TextMediumSemiBoldWeight>
                        </li>
                      </Div>
                    </ul>
                  </Div>

                  <Div width={["50%", "100%"]}>
                    <ul>
                      <li>{renderPrice()}</li>
                    </ul>
                  </Div>
                </Div>
              )}
            </>
          ) : (
            <Div>
              <TextMediumSemiBoldWeight>
                {messages.message_order_status_not_paid}
              </TextMediumSemiBoldWeight>
            </Div>
          )}

          {status === "cancelled" && (
            <H3 display="block" mt={3}>
              {`${messages.label_refund}: SEK ${convertSEK(totalPrice) || 0}`}
            </H3>
          )}
        </Div>
      </Container>
    </Card>
  );
};

OrderInfo.propTypes = {
  data: Proptypes.object,
  isWatchList: Proptypes.bool,
};
export default OrderInfo;
