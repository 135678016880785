import React from "react";

import DataTable from "@components/DataTableV2/DataTable";

import AdminContainer from "@layout/AdminContainer";

import useInvoices from "./useInvoices";

const Invoices = () => {
  const { dataTableConfig, headerConfig } = useInvoices();

  return (
    <AdminContainer wide config={headerConfig}>
      <DataTable config={dataTableConfig} />
    </AdminContainer>
  );
};

export default Invoices;
