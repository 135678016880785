import React from "react";
import { FormattedMessage } from "react-intl";

import { ROUTES, INVOICED, INVOICE_CANCELLED } from "@utils/constant";
import { INVOICE_MODAL_TYPES } from "@utils/enum";

export const searchableColumns = [
  "id",
  "name",
  "email_invoice",
  "your_reference",
  "invoice_remark",
  "fortnox_customer_id",
];

export const FILTER_KEY_FROM = "filter[orders.created_at][gte]";

export const FILTER_KEY_TO = "filter[orders.created_at][lte]";

export const TYPE_DEPENDANT_INVOICE_DETAILS = {
  [INVOICE_MODAL_TYPES.CONFIRM]: {
    nextStatus: INVOICED,
    messagePlural: <FormattedMessage id="invoices_confirmed_text" />,
    messageSingular: <FormattedMessage id="invoice_confirmed_text" />,
  },
  [INVOICE_MODAL_TYPES.CANCEL]: {
    nextStatus: INVOICE_CANCELLED,
    messagePlural: <FormattedMessage id="invoices_canceled_text" />,
    messageSingular: <FormattedMessage id="invoice_canceled_text" />,
  },
};

export const TAB_CONTENT = [
  {
    title: <FormattedMessage id="label_services" />,
    content: <></>,
    url: ROUTES.ADMIN_UN_BILLED_SERVICES.URL,
  },
  {
    title: <FormattedMessage id="title_subscriptions" />,
    content: <></>,
    url: ROUTES.ADMIN_UN_BILLED_SUBSCRIPTIONS.URL,
  },
];
