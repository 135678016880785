import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import Div from "@components/Div";
import { H5 } from "@components/Heading";
import { SmallEllipseIcon, BundleIcon } from "@components/Icon";
import { PriceText, TextLargeSemiBoldWeight } from "@components/Text";
import Tooltip from "@components/ToolTip";

import useAuthorization from "@hooks/useAuthorization";

import { CustomerPermissions } from "@src/enum/Permissions";

import { BUNDLE } from "@utils/constant";
import { convertSEK, getFormattedNumber } from "@utils/utils";

function OrderSummaryCard({
  selectedServices,
  isCreditOnly = false,
  isPriceOnly = false,
}) {
  const { messages } = useIntl();

  const { hasAllPermissions } = useAuthorization();

  const isPricesPermission = hasAllPermissions([
    CustomerPermissions.CustomerReadPrices,
  ]);

  const renderDiscountedPrice = (service = {}) => (
    <>
      <PriceText
        pb={[1, 1, 0, 0]}
        fontSize={[
          "var(--fs-text-m) !important",
          "var(--fs-text-m) !important",
          "var(--fs-h3) !important",
          "var(--fs-h3) !important",
        ]}
        whiteSpace={"nowrap"}
        textDecoration="line-through"
        color="var(--grey)"
      >
        {service.purchase_by_credits && !isPriceOnly
          ? `${messages.prepaid_price_label}`
          : `${getFormattedNumber(convertSEK(service.price))} ${
              messages.search_label_currency
            }`}
      </PriceText>
      <Div mx={1} />
    </>
  );

  const renderPrice = (service = {}) => {
    const priceValue = service.discount
      ? service.priceWithDiscount
      : service.price;

    return (
      <Div display="flex">
        {!!service?.discount && renderDiscountedPrice(service)}

        <TextLargeSemiBoldWeight
          pb={[1, 1, 0, 0]}
          whiteSpace="nowrap"
          color={service.discount ? "var(--blue-dark)" : "var(--grey-dark)"}
        >
          {service.purchase_by_credits && !isPriceOnly
            ? `${messages.prepaid_price_label}`
            : `${getFormattedNumber(convertSEK(priceValue))} ${
                messages.search_label_currency
              }`}
        </TextLargeSemiBoldWeight>
      </Div>
    );
  };

  const justifyContent = isPricesPermission
    ? ["flex-end", "flex-end", "space-between", "space-between"]
    : "flex-end";

  return (
    <Div display="flex" flexDirection="column" mt={-2}>
      <Div display="flex" justifyContent="space-between">
        <Div>
          <H5 whiteSpace="nowrap">{`${messages.chosen_service_label}:`}</H5>
        </Div>

        <Div width="332px" display="flex" justifyContent={justifyContent}>
          <Div display={["none", "none", "block", "block"]}>
            <H5>{`${messages.delivery_time_label}:`}</H5>
          </Div>

          {isPricesPermission && (
            <Div>
              <H5>
                {`${
                  isCreditOnly
                    ? messages.amount_label
                    : messages.watchlist_label_price
                }:`}
              </H5>
            </Div>
          )}
        </Div>
      </Div>

      <Div display="flex" flexDirection="column">
        {selectedServices.map((service = {}) => {
          return (
            <Div key={service.id}>
              <Div
                mt={3}
                height="1px"
                mx={["-0.8rem", "-0.8rem", "-1rem", "-1rem"]}
                backgroundColor="var(--grey-lightest)"
              />

              <Div
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                pt="25px"
              >
                <TextLargeSemiBoldWeight whiteSpace="wrap">
                  {service.name}

                  {service.service_type === BUNDLE && (
                    <BundleIcon ml={2} name="bundle-tag" />
                  )}

                  <SmallEllipseIcon
                    display="flex"
                    justifyContent="center"
                    ml={2}
                    mt={[1, 0]}
                    name="info"
                    className={`serviceName-${service.id}`}
                  >
                    <Tooltip
                      target={`.serviceName-${service.id}`}
                      content={service.description}
                      isHtml={true}
                    />
                  </SmallEllipseIcon>
                </TextLargeSemiBoldWeight>

                <Div
                  width="332px"
                  display="flex"
                  justifyContent={justifyContent}
                >
                  <Div display={["none", "none", "block", "block"]}>
                    <TextLargeSemiBoldWeight>
                      {service.delivery_time}
                    </TextLargeSemiBoldWeight>
                  </Div>

                  <Div display="flex" justifyContent="end" alignItems="start">
                    {isPricesPermission && renderPrice(service)}
                  </Div>
                </Div>
              </Div>

              <Div
                display={["flex", "flex", "none", "none"]}
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
              >
                <H5>{`${messages.delivery_time_label}:`}</H5>
                <H5>{service.delivery_time}</H5>
              </Div>
            </Div>
          );
        })}
      </Div>
    </Div>
  );
}

OrderSummaryCard.propTypes = {
  selectedServices: PropTypes.object,
  isCreditOnly: PropTypes.bool,
  isPriceOnly: PropTypes.bool,
};

export default OrderSummaryCard;
