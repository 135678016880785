import React, { useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { klarnaActions } from "@app/reducers/klarnaReducer";
import { default as getDetails } from "@app/services/orders/fetchOrderDetails";
import ordersKlarnaService from "@app/services/orders/ordersKlarnaService";

import { H4 } from "@components/Heading";
import {
  PrimaryButtonIcon,
  PrimaryButtonIconOutlined,
} from "@components/Button";
import Div from "@components/Div";
import UpgradePackage from "@components/UpgradePackage";
import Skeleton from "@components/Skeleton";
import { TextLarge } from "@components/Text";

import { useUser } from "@contexts/AuthProvider";

import { trackPageView } from "@src/analyticsFunctions";

import CandidateOrderDetails from "@pages/shared/orderDetails/CandidateOrderDetails";

import {
  STATUS_COMPLETED,
  KLARNA_CHECKOUT,
  SUBSCRIPTION_STATUS,
  COMPANY,
  PENDING_CONSENT,
  ROUTES,
} from "@utils/constant";
import { CASES_DATA_STATUS_TYPE, SERVICE_TYPE } from "@utils/enum";

import OrderConfirmationLoader from "../orderDetails/OrderConfirmationLoader";

const DELIVERY_IMMEDIATE = "Direkt";

const OrderConfirmation = () => {
  const [loading, setLoading] = useState(true);
  const { isCustomer } = useUser();
  const { messages } = useIntl();
  const [showPackage, setShowPackage] = useState(false);
  const [idle, setIdle] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const paymentMethod = useSelector(
    state => state.personInformationReducer.paymentMethod
  );

  const { order: orders } = useSelector(
    state => state.klarnaReducer.ordersInfo
  );
  const userInfo = useSelector(state => state.authReducer.userInfo);
  const { personInfo = [] } = useSelector(
    state => state.personInformationReducer
  );
  const { order_id: orderId } = orders;
  const query = useMemo(() => new URLSearchParams(location.search), []);

  // This is necessary to delay the backend to not make the call instantly
  useEffect(() => {
    setTimeout(() => {
      setIdle(false);
    }, 3000);
  }, []);

  useEffect(() => {
    trackPageView();
    const _orderId = query.get("order_id");
    if (_orderId && !idle) {
      setLoading(true);
      const getOrderDetailsUsingKlarnaID = async () => {
        const response = await ordersKlarnaService(_orderId);
        dispatch(klarnaActions.ordersInfo(response.data));
      };
      getOrderDetailsUsingKlarnaID();
    }
  }, [dispatch, query, idle]);

  useEffect(() => {
    let iterations = 0;
    let reportIterations;
    const getData = async () => {
      if (++iterations >= 20) {
        setLoading(false);
        clearInterval(reportIterations);
      }
      let order;
      let response;
      const isLoggedIn = localStorage.getItem("token");

      if (isLoggedIn) {
        response = await getDetails(orderId);
        order = response;
      } else {
        const _orderId = query.get("order_id");
        response = await ordersKlarnaService(_orderId);
        order = response.data.order;
      }
      const { order_items = [], reports = [] } = order;
      const filteredOrderItems = order_items.filter(
        item =>
          (item.product.is_case &&
            item.data_source_status !== CASES_DATA_STATUS_TYPE.MANUAL &&
            item.product.delivery_time === "Direkt") ||
          (!item.product.is_case && item.product.delivery_time === "Direkt")
      );

      const completedReports = reports.filter(
        obj =>
          obj.report_status === STATUS_COMPLETED ||
          obj.report_status === PENDING_CONSENT
      );
      if (completedReports.length === filteredOrderItems.length) {
        dispatch(klarnaActions.ordersInfo({ order }));
        setLoading(false);
        clearInterval(reportIterations);
      }
    };

    setLoading(true);
    reportIterations = setInterval(getData, 3000);

    return () => clearInterval(reportIterations);
  }, [dispatch, query, personInfo?.length, orderId]);

  useEffect(() => {
    const loadActiveSubscriptionData = async () => {
      if (userInfo?.customer) {
        const {
          customer: { status: subscriptionStatus } = {},
          permissions = {},
        } = userInfo;
        if (
          userInfo.customer.type == COMPANY &&
          subscriptionStatus !== SUBSCRIPTION_STATUS.TERMINATED &&
          subscriptionStatus !== SUBSCRIPTION_STATUS.STOPPED &&
          (permissions?.subscription === SUBSCRIPTION_STATUS.READ ||
            permissions?.subscription === SUBSCRIPTION_STATUS.WRITE)
        ) {
          setShowPackage(false);
        } else {
          setShowPackage(false);
        }
      } else if (Object.keys(userInfo).length === 0) {
        setShowPackage(false);
      }
    };
    loadActiveSubscriptionData();
  }, [userInfo]);

  const {
    order_items: orderItems = [],
    status = "",
    watchList = null,
    reports = [],
  } = orders;

  const hasAssignment = orderItems.some(
    item => item.service_type === SERVICE_TYPE.ASSIGNMENT
  );
  const hasCase = orderItems.some(({ product }) => Boolean(product.is_case));

  const renderSubText = (
    isDeliveryTimeDirect,
    isDeliveryTimeNotDirect,
    consent,
    candidateForm,
    customerForm
  ) => {
    const conditions = [
      {
        condition:
          isDeliveryTimeDirect && !isDeliveryTimeNotDirect && !consent && !candidateForm && !customerForm,
        id: "label_case_order_text_1",
      },
      {
        condition:
          isDeliveryTimeDirect &&
          isDeliveryTimeNotDirect &&
          !consent &&
          !candidateForm &&
          !customerForm,
        id: "label_case_order_text_2",
      },
      {
        condition:
          !isDeliveryTimeDirect && isDeliveryTimeNotDirect && !consent && !candidateForm && !customerForm,
        id: "label_case_order_text_3",
      },
      {
        condition:
          isDeliveryTimeDirect && !isDeliveryTimeNotDirect && consent && !candidateForm && !customerForm,
        id: "label_case_order_text_4",
      },
      {
        condition:
          !isDeliveryTimeDirect && consent && !candidateForm && !customerForm,
        id: "label_case_order_text_5",
      },
      {
        condition: isDeliveryTimeDirect && isDeliveryTimeNotDirect && consent && !candidateForm && !customerForm,
        id: "label_case_order_text_6",
      },
      {
        condition: consent && candidateForm && customerForm,
        id: "label_case_order_text_7",
      },
      {
        condition: !consent && !candidateForm && customerForm,
        id: "label_case_order_text_8",
      },
      {
        condition: !consent && candidateForm && customerForm,
        id: "label_case_order_text_9",
      },
    ];

    const matchedCondition = conditions.find(({ condition }) => condition) ?? {
      id: "",
    };
    
    const resultSubText = messages?.[matchedCondition.id];

    return String(resultSubText);
  };

  const renderHeadingText = () => {
    // Check if all delivery times are "DELIVERY_IMMEDIATE"
    const isDeliveryTimeDirect = orderItems.some(
      ({ product: { delivery_time } }) => delivery_time === DELIVERY_IMMEDIATE
    );
    
    // check if all delivery times are not "DELIVERY_IMMEDIATE"
    const isDeliveryTimeNotDirect = orderItems.some(
      ({ product: { delivery_time } }) => delivery_time !== DELIVERY_IMMEDIATE
    );

    const consent = orderItems.some(({ consent }) => Boolean(consent));
    const candidateForm = orderItems.some(({ product: { candidate_form } }) =>
      Boolean(candidate_form)
    );
    const customerForm = orderItems.some(({ product: { customer_form } }) =>
      Boolean(customer_form)
    );

    const fallbackResultText = hasAssignment
      ? messages.order_details_assigment_note
      : "";
    const resultValue = hasCase
      ? renderSubText(
          isDeliveryTimeDirect,
          isDeliveryTimeNotDirect,
          consent,
          candidateForm,
          customerForm
        )
      : fallbackResultText;

    return resultValue;
  };

  const handleNewSearch = () => {
    history.push(ROUTES.WIZARD_SEARCH.URL);
  };
  const handleMyOrders = () => {
    history.push(ROUTES.ORDERS_LIST.URL);
  };

  return (
    <>
      <>
        <Div>
          {paymentMethod === KLARNA_CHECKOUT &&
            (loading ? (
              <Skeleton mt={3} maxWidth="100px" height="20px" />
            ) : (
              <H4>{messages.payment_approved}</H4>
            ))}

          {(hasAssignment || hasCase) && (
            <Div mb={3}>
              <TextLarge textAlign="left" whiteSpace="break-spaces">
                {loading ? (
                  <Skeleton mt={3} maxWidth="120px" height="25px" />
                ) : (
                  renderHeadingText()
                )}
              </TextLarge>
            </Div>
          )}
        </Div>

        {loading ? (
          <OrderConfirmationLoader
            showDelivery={true}
            orderItems={orderItems}
            orderItemCount={orderItems?.length}
            isWatchList={watchList && watchList !== null}
          />
        ) : (
          <>
            <CandidateOrderDetails
              orderStatus={status}
              orderItems={orderItems}
              showDelivery={true}
              reports={reports}
              showObject={true}
              isOrderConfirmation={true}
            />
            <Div
              mt={4}
              display="flex"
              flexDirection={["column", "row", "row", "row"]}
            >
              <PrimaryButtonIcon
                onClick={handleNewSearch}
                label={messages["candidate_order_details.new_search"]}
                icon="icon-arrowright"
                iconPos="right"
              />

              {isCustomer && (
                <PrimaryButtonIconOutlined
                  onClick={handleMyOrders}
                  ml={[0, 4, 4, 4]}
                  mt={[3, 0, 0, 0]}
                  label={messages["candidate_order_details.my_orders"]}
                  icon="icon-arrowright"
                  iconPos="right"
                />
              )}
            </Div>
          </>
        )}
      </>

      {showPackage && <UpgradePackage show_package_list={true} />}
    </>
  );
};

export default OrderConfirmation;
