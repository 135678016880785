import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import styled from "styled-components";

import getSourceDataById from "@app/services/cases/getSourceDataById";
import getEmailsBySourceDataId from "@app/services/cases/getEmailsBySourceDataId";
import updateSourceDataById from "@app/services/cases/updateSourceDataById";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Card from "@components/Card";
import DataTable from "@components/DataTableV2/DataTable";
import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import ProgressSpinner from "@components/ProgressSpinner";
import PromptDialog from "@components/PromptDialog";
import TabHeader from "@components/TabHeader";
import { TextLargeSemiBoldWeight } from "@components/Text";

import { useToast } from "@hooks/useToast";

import { CASES_DATA_STATUS_TYPE, DATA_SOURCE_TYPE } from "@utils/enum";

import { AddCrimesDialog, EditCrimesDialog, RawDataViewer } from "./components";

import Attachments from "../emails/components/Attachments";

const StyledDataTableWrapper = styled(Div)`
  .p-datatable.p-datatable-custom {
    .p-datatable-wrapper {
      overflow-x: hidden !important;

      @media (max-width: 1200px) {
        overflow-x: auto !important;
      }
    }
  }
`;

const fetchSourceData = async data_source_id => {
  const {
    data: [sourceData],
  } = await getSourceDataById(data_source_id);

  return sourceData;
};

const fetchAttachments = async data_source_id => {
  const { data = [] } = await getEmailsBySourceDataId(data_source_id);

  const attachments = data.flatMap(({ attachments }) => attachments);

  return attachments;
};

const CrimesDialog = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const { case_id, data_source_id } = useParams();
  const { showSuccessToast, showErrorToast } = useToast();

  const [shouldShowAddCrimeDialog, setShouldShowAddCrimeDialog] =
    useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [data, setData] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [selectedEditData, setSelectedEditData] = useState(null);
  const [selectedDeleteData, setSelectedDeleteData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");

  const isCrimesEditable =
    !isLoading && status !== CASES_DATA_STATUS_TYPE.COMPLETED;

  const shouldShowRawDataViewer =
    sourceData?.type ===
    DATA_SOURCE_TYPE.VERIFERA_PERSON_INFORMATION_ROLE_ANALYSIS;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const [sourceDataFromResponse, attachmentsData] = await Promise.all([
          fetchSourceData(data_source_id),
          fetchAttachments(data_source_id),
        ]);

        const { data: { crimes = [] } = {}, status: sourceDataStatus } =
          sourceDataFromResponse;

        setData(crimes);
        setSourceData(sourceDataFromResponse);
        setStatus(sourceDataStatus);
        setAttachments(attachmentsData);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [data_source_id, messages.exception_error_message, showErrorToast]);

  const handleOpenEditCrimeDialog = value => {
    const crimeDetails = {
      crime: {
        name: value.crime_name,
        id: value.crime_id,
      },
      crime_case_number: value.crime_case_number,
      crime_date: value.crime_date,
      court: {
        court_name: value.court_name ?? "",
        id: value.court_id,
      },
      crime_sentence: value.crime_sentence,
    };

    setSelectedEditData(crimeDetails);
  };

  const handleOpenDeleteCrimeDialog = value => {
    setSelectedDeleteData(value);
  };

  const config = {
    header: {},
    no_records_message: "datatable_admin_data_source_crimes_no_objects_found",
    columns: [
      {
        db_field: "crime_id",
        type: "text",
        isHidden: true,
      },
      {
        db_field: "court_id",
        type: "text",
        isHidden: true,
      },
      {
        db_field: "crime_name",
        title: "title_crime",
        type: "text",
        sortable: true,
        width: "25%",
      },
      {
        db_field: "crime_case_number",
        title: "title_case_number",
        type: "id",
        sortable: true,
        width: "15%",
      },
      {
        db_field: "crime_date",
        title: "title_date_of_crime",
        type: "date",
        sortable: true,
        width: "20%",
      },
      {
        db_field: "court_name",
        title: "label_court_name",
        type: "text",
        sortable: true,
        width: "20%",
      },
      {
        db_field: "crime_sentence",
        title: "label_crime_sentence",
        type: "text",
        sortable: true,
        width: "15%",
      },
      {
        type: "actions",
        width: "5%",
        isHidden: !isCrimesEditable,
        actions: [
          {
            label: messages.label_edit_crime,
            icon: "icon-pen",
            type: "button",
            onClick: handleOpenEditCrimeDialog,
          },
          {
            label: messages.label_delete_crime,
            icon: "icon-rubber",
            type: "button",
            onClick: handleOpenDeleteCrimeDialog,
          },
        ],
      },
    ],
    static_data: data,
    backend_querying: false,
  };

  const handleSave = () => {
    handleUpdateSourceData();
  };

  const handleUpdateSourceData = async (shouldComplete = false) => {
    try {
      setIsLoading(true);

      const mappedData = data.map(
        ({
          crime_id,
          crime_date,
          court_id,
          court_name,
          crime_case_number,
          crime_sentence,
        }) => ({
          crime_id,
          crime_case_number,
          crime_date,
          court_id,
          court_name,
          crime_sentence,
        })
      );
      const payload = { crimes: mappedData };

      if (shouldComplete) {
        payload.status = CASES_DATA_STATUS_TYPE.COMPLETED;
      }

      await updateSourceDataById(case_id, data_source_id, payload);
      showSuccessToast(messages.success_message);

      setIsDataChanged(false);
    } catch (error) {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompleteCrimesData = async () => {
    await handleUpdateSourceData(true);
    history.goBack();
  };

  const handleOpenAddEditCrimeDialog = () => {
    setShouldShowAddCrimeDialog(true);
  };

  const formatCrimeValues = values => {
    const {
      crime: { id: crime_id, name: crime_name } = {},
      court: { id: court_id, court_name } = {},
      crime_case_number,
      crime_date,
      crime_sentence,
    } = values;

    return {
      court_id,
      court_name,
      crime_id,
      crime_name,
      crime_case_number,
      crime_date,
      crime_sentence,
    };
  };

  const handleAddCrime = values => {
    const newCrime = formatCrimeValues(values);
    const updatedData = data;

    updatedData.push(newCrime);

    setData(updatedData);
    setIsDataChanged(true);

    handleCloseAddCrimeDialog();
  };

  const handleCloseAddCrimeDialog = () => {
    setShouldShowAddCrimeDialog(false);
  };

  const handleEditCrime = values => {
    const updatedCrime = formatCrimeValues(values);
    const updatedData = data;
    const index = updatedData.findIndex(
      item =>
        item.crime_id === updatedCrime.crime_id &&
        item.court_id === updatedCrime.court_id
    );

    updatedData.splice(index, 1, updatedCrime);

    setData(updatedData);
    setIsDataChanged(true);

    handleCloseEditCrimeDialog();
  };

  const handleCloseEditCrimeDialog = () => {
    setSelectedEditData(null);
  };

  const handleDeleteCrime = () => {
    const updatedData = data;
    const deletedElementIndex = data.findIndex(
      ({ crime_id, court_id }) =>
        crime_id === selectedDeleteData.crime_id &&
        court_id === selectedDeleteData.court_id
    );

    updatedData.splice(deletedElementIndex, 1);

    setData(updatedData);
    setIsDataChanged(true);

    handleCloseDeleteCrimeDialog();
  };

  const handleCloseDeleteCrimeDialog = () => {
    setSelectedDeleteData(null);
  };

  const renderAttachments = () => (
    <Card
      header={
        <Div p={3}>
          <TextLargeSemiBoldWeight>
            {messages.title_documents_from_court}
          </TextLargeSemiBoldWeight>
        </Div>
      }
    >
      <Div px={3} pb={3}>
        <Div border="1px solid var(--grey-lightest)" width={1} mb={3} />
        <Attachments attachments={attachments} />
      </Div>
    </Card>
  );

  const tabHeader = {
    subtitle: messages.text_add_edit_crimes,
    actions: [
      {
        id: "new-crime",
        type: "button",
        label: messages.label_add_crime,
        width: "auto",
        icon: "plus",
        variant: "header",
        isHidden: !isCrimesEditable,
        onClick: handleOpenAddEditCrimeDialog,
      },
    ],
  };

  return (
    <FullSizeDialog title={messages.title_crimes}>
      {isLoading && <ProgressSpinner />}

      {shouldShowAddCrimeDialog && (
        <AddCrimesDialog
          onHide={handleCloseAddCrimeDialog}
          onAdd={handleAddCrime}
        />
      )}

      {selectedEditData && (
        <EditCrimesDialog
          crimeDetails={selectedEditData}
          onHide={handleCloseEditCrimeDialog}
          onEdit={handleEditCrime}
        />
      )}

      {selectedDeleteData && (
        <PromptDialog
          title={messages.label_delete_crime}
          message={`${messages.message_delete_crime} ${selectedDeleteData?.crime_name}`}
          onConfirm={handleDeleteCrime}
          onCancel={handleCloseDeleteCrimeDialog}
          confirmLabel={messages.label_delete}
        />
      )}

      {isCrimesEditable && <TabHeader {...tabHeader} />}

      <StyledDataTableWrapper width="100%">
        <DataTable config={config} resetData={!isLoading} />
      </StyledDataTableWrapper>

      {!!attachments?.length && renderAttachments()}

      {isDataChanged && (
        <Div
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          gridGap={4}
        >
          <PrimaryButton
            label={messages.label_save_and_complete_source_data}
            onClick={handleCompleteCrimesData}
          />
          <PrimaryButtonOutlined
            label={messages.label_save}
            px={4}
            onClick={handleSave}
          />
        </Div>
      )}

      {shouldShowRawDataViewer && <RawDataViewer sourceData={sourceData} />}
    </FullSizeDialog>
  );
};

export default CrimesDialog;
