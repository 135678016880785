import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import Tabs from "@components/Tabs";
import AdminContainer from "@layout/AdminContainer";
import { ROUTES } from "@utils/constant";
import CourtList from "./courts/list";
import RulesList from "../rules/list";
import CrimesList from "../crimes/list";
import useAuthorization from "@hooks/useAuthorization";
import { AdminPermissions } from "@src/enum/Permissions";
import ActivityList from "../activityLogs/list";

const List = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const [activeIndex, setActiveIndex] = useState(0);
  const { hasAllPermissions } = useAuthorization();
  const [headerTitle, setHeaderTitle] = useState("");

  const tab_content = useMemo(
    () => [
      {
        title: <FormattedMessage id="title_rules" />,
        content: <RulesList />,
        url: ROUTES.ADMIN_RULES_LIST.URL,
        isHidden: !hasAllPermissions([AdminPermissions.AdminReadRules]),
        headerTitle: messages.cases_configuration_label,
      },
      {
        title: <FormattedMessage id="courts_label" />,
        content: <CourtList />,
        url: ROUTES.ADMIN_COURTS_LIST.URL,
        isHidden: !hasAllPermissions([AdminPermissions.AdminReadCourts]),
        headerTitle: messages.courts_configuration_label,
      },
      {
        title: <FormattedMessage id="title_crimes" />,
        content: <CrimesList />,
        url: ROUTES.ADMIN_CRIMES_LIST.URL,
        isHidden: !hasAllPermissions([AdminPermissions.AdminReadCrimes]),
        headerTitle: messages.crimes_configuration_label,
      },
      {
        title: <FormattedMessage id="activity_log" />,
        content: <ActivityList />,
        url: ROUTES.ADMIN_ACTIVITY_LOG_LIST.URL,
        isHidden: !hasAllPermissions([AdminPermissions.AdminReadActivityLog]),
        headerTitle: messages.activity_log_configuration_label,
      },
    ],
    [
      hasAllPermissions,
      messages.cases_configuration_label,
      messages.courts_configuration_label,
      messages.crimes_configuration_label,
      messages.activity_log_configuration_label,
    ]
  );

  useEffect(() => {
    const {
      location: { pathname },
    } = history;
    const activeTabIndex = tab_content.findIndex(item => item.url === pathname);
    setActiveIndex(activeTabIndex);
    setHeaderTitle(tab_content[activeTabIndex].headerTitle);
  }, [tab_content, history]);

  const handleTabChange = event => {
    const { index } = event;
    history.push({
      pathname: tab_content[index]?.url,
    });
  };
  const headerConfig = {
    title: headerTitle,
  };
  return (
    <AdminContainer config={headerConfig} wide>
      <Tabs
        content={tab_content}
        onTabChange={handleTabChange}
        activeIndex={activeIndex}
      />
    </AdminContainer>
  );
};
export default List;
