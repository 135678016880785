import React from "react";
import PropTypes from "prop-types";

import Dialog from "@components/Dialog";
import OrderReportModal from "@components/Report/LegacyReport/OrderReportModal";

const CreditsReportDialog = ({
  reportId,
  objectData,
  onClose,
  onReloadDetails,
  oldReport = false,
}) => {
  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 445, 445, 445]}
      onHide={onClose}
      m={[3, 3, 3, "auto"]}
    >
      <OrderReportModal
        reportID={reportId}
        objectData={objectData}
        reloadDetails={onReloadDetails}
        handleConfirmPopup={onClose}
        oldReport={oldReport}
      />
    </Dialog>
  );
};

CreditsReportDialog.propTypes = {
  reportId: PropTypes.string,
  objectData: PropTypes.object,
  onClose: PropTypes.func,
  onReloadDetails: PropTypes.func,
  oldReport: PropTypes.bool,
};

export default CreditsReportDialog;
