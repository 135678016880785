import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import deleteUserProfile from "@app/services/users/deleteUserProfileService";
import getRolesService from "@app/services/users/getRolesService";
import resendInvitationService from "@app/services/users/resendInvitationService";

import Container from "@components/Container";
import ErrorDialog from "@components/ErrorDialog";
import PromptDialog from "@components/PromptDialog";
import { Text } from "@components/Text";

import AdminContainer from "@layout/AdminContainer";

import { ROUTES } from "@utils/constant";
import { USER_TYPE } from "@utils/enum";
import { getUserRoleTranslationKey } from "@utils/utils";

import DeleteUser from "./Delete";
import UserDialog from "./components/UserDialog";
import AdminListTab from "./tabs/AdminListTab";
import CustomerListTab from "./tabs/CustomerListTab";
import { useToast } from "@hooks/useToast";

const formatAminUserRole = (userData) => {
  const { roles = [] } = userData;

  if (!roles?.length) {
    return userData;
  }

  const [{ name: selectedRoleName = "", ...restRoleData } = {}] = roles;

  if (selectedRoleName.includes("admin-")) {
    return userData;
  }

  const formattedRoleName = `admin-${selectedRoleName}`;

  const formattedUserData = {
    ...userData,
    roles: [{ ...restRoleData, name: formattedRoleName }],
  };

  return formattedUserData;
};

const List = () => {
  const history = useHistory();
  const { messages } = useIntl();

  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);
  const [showEditUserDialog, setShowEditUserDialog] = useState(false);
  const [showResendInvitationDialog, setShowResendInvitationDialog] =
    useState(false);

  const [selectedData, setSelectedData] = useState("");
  const [reloadList, setReloadList] = useState(false);
  const [roleFilters, setRoleFilters] = useState([]);
  const [isFilterValuesLoaded, setIsFilterValuesLoaded] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const { showSuccessToast } = useToast();
  const isSubUser = useMemo(() => activeTabIndex === 1, [activeTabIndex]);
  const userType = useMemo(
    () => (isSubUser ? USER_TYPE.CUSTOMER : USER_TYPE.ADMIN),
    [isSubUser],
  );

  const handleAddUser = useCallback(() => {
    if (showAddUserDialog) {
      setReloadList(!reloadList);
    }
    setShowAddUserDialog(!showAddUserDialog);
  }, [showAddUserDialog, reloadList, setReloadList]);

  const handleDeleteModal = useCallback(
    (data = {}) => {
      setSelectedData(data);

      if (showDeleteUserDialog) {
        setReloadList(!reloadList);
      }

      setShowDeleteUserDialog(!showDeleteUserDialog);
    },
    [reloadList, showDeleteUserDialog, setReloadList, setShowDeleteUserDialog],
  );

  const handleDeleteUser = async () => {
    const { id = "" } = selectedData;

    try {
      await deleteUserProfile({ id: id });

      handleDeleteModal();
      showSuccessToast(messages.text_deleted_successful);
    } catch (e) {
      setErrorMessage(messages.exception_error_message);
    }
  };

  const handleResendInvitation = async () => {
    const { id = "" } = selectedData;

    try {
      await resendInvitationService(id);

      handleReSendInvitationModal();
    } catch (error) {
      setErrorMessage(messages.exception_error_message);
    }
  };

  const handleEditUserModal = useCallback(
    (data = {}) => {
      const formattedData = !isSubUser ? formatAminUserRole(data) : data;
      setSelectedData(formattedData);

      if (showEditUserDialog) {
        setReloadList(!reloadList);
      }

      setShowEditUserDialog(!showEditUserDialog);
    },
    [isSubUser, showEditUserDialog, reloadList, setReloadList],
  );

  const handleReSendInvitationModal = useCallback(
    async (data = {}) => {
      setSelectedData(data);

      if (showResendInvitationDialog) {
        setReloadList(!reloadList);
      }

      setShowResendInvitationDialog(!showResendInvitationDialog);
    },
    [reloadList, showResendInvitationDialog],
  );

  const handleCloseResendInvitationModal = () => {
    setShowResendInvitationDialog(false);
    setSelectedData({});
  };

  const handleCloseErrorDialog = () => {
    setErrorMessage("");
  };

  const headerConfig = {
    title: activeTabIndex ? messages.title_customers : messages.title_admins,
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        const res = await getRolesService(userType);
        const availableRoleFilters = res.map((role) => ({
          label: messages[getUserRoleTranslationKey(role.value)],
          value: role.id,
          checked: false,
        }));

        setRoleFilters(availableRoleFilters);
        setIsFilterValuesLoaded(true);
      } catch (e) {
        throw new Error(e);
      }
    };

    getRoles();
  }, [isSubUser, userType, messages]);

  const tabViewContent = useMemo(
    () => [
      {
        title: messages.title_admins,
        content: (
          <AdminListTab
            roleFilters={roleFilters}
            isFilterValuesLoaded={isFilterValuesLoaded}
            reloadList={reloadList}
            onAddUser={handleAddUser}
            onEditUser={handleEditUserModal}
            onResendInvitation={handleReSendInvitationModal}
            onDeleteUser={handleDeleteModal}
          />
        ),
        url: ROUTES.ADMIN_ADMINS_LIST.URL,
      },
      {
        title: messages.title_customers,
        content: (
          <CustomerListTab
            roleFilters={roleFilters}
            isFilterValuesLoaded={isFilterValuesLoaded}
            reloadList={reloadList}
            onAddUser={handleAddUser}
            onEditUser={handleEditUserModal}
            onDeleteUser={handleDeleteModal}
            onResendInvitation={handleReSendInvitationModal}
          />
        ),
        url: ROUTES.ADMIN_CUSTOMERS_LIST.URL,
      },
    ],
    [
      messages,
      isFilterValuesLoaded,
      roleFilters,
      reloadList,
      handleAddUser,
      handleDeleteModal,
      handleEditUserModal,
      handleReSendInvitationModal,
    ],
  );

  useEffect(() => {
    const path = history.location.pathname;
    const selectedTabIndex = Math.max(
      tabViewContent.findIndex((item) => item.url === path),
      0,
    );

    setActiveTabIndex(selectedTabIndex);
  }, [history.location.pathname, tabViewContent]);

  return (
    <AdminContainer config={headerConfig} wide>
      <Container m={"0px !important"}>
        {tabViewContent?.[activeTabIndex]?.content}
      </Container>

      {showAddUserDialog && (
        <UserDialog
          title={messages.label_invite_sub_user}
          userType={userType}
          handleClose={handleAddUser}
        />
      )}

      {showEditUserDialog && (
        <UserDialog
          title={messages.label_edit_user}
          userType={userType}
          userDetails={selectedData}
          handleClose={handleEditUserModal}
        />
      )}

      {showResendInvitationDialog && (
        <PromptDialog
          title={messages.title_user_not_confirmed_invitation}
          message={`${messages.title_new_user_invite_sent} ${selectedData?.email}`}
          onConfirm={handleResendInvitation}
          onCancel={handleCloseResendInvitationModal}
        />
      )}

      {errorMessage && (
        <ErrorDialog
          errorMessage={errorMessage}
          onHide={handleCloseErrorDialog}
          onConfirm={handleCloseErrorDialog}
        />
      )}

      {showDeleteUserDialog && (
        <DeleteUser onDelete={handleDeleteUser} onCancel={handleDeleteModal}>
          <Text justifyContent="center" display="flex">
            {`${messages.label_delete_user_confirmation_first} ${messages.label_delete_user_confirmation_second}`}
          </Text>
        </DeleteUser>
      )}
    </AdminContainer>
  );
};

export default List;
