import React from "react";
import { FormattedMessage } from "react-intl";

import * as Yup from "yup";

import { SVLANG } from "@utils/constant";
import { ValidateOrganisationNumber } from "@utils/utils";

const NameSchema = Yup.object().shape({
  account_manager_id: Yup.mixed()
    .required(<FormattedMessage id="validation_empty_account_manager" />),
  company_name: Yup.string()
    .typeError(<FormattedMessage id="validation_empty_company_name" />)
    .required(<FormattedMessage id="validation_empty_company_name" />),
  address: Yup.string().required(
    <FormattedMessage id="validation_empty_company_address" />
  ),
  city: Yup.string().required(<FormattedMessage id="validation_enter_city" />),
  zip_code: Yup.string().required(
    <FormattedMessage id="validation_enter_zip_code" />
  ),
  registration_nr: Yup.string()
    .max(11, <FormattedMessage id="validation_valid_company_number" />)
    .required(<FormattedMessage id="validation_valid_company_number" />)
    .test({
      name: "valid-company-number",
      message: <FormattedMessage id="validation_valid_company_number" />,
      test: ValidateOrganisationNumber,
    }),
});

const ContactSchema = Yup.object().shape({
  firstname: Yup.string().required(
    <FormattedMessage id="validation_empty_name" />
  ),
  lastname: Yup.string().required(
    <FormattedMessage id="validation_empty_last_name" />
  ),
  email: Yup.string().required(
    <FormattedMessage id="validation_valid_email_input" />
  ),
  phone: Yup.string()
    .typeError(SVLANG.validation_valid_phone_number)
    .required(<FormattedMessage id="validation_empty_phone_number" />),
});

const SettingSchema = Yup.object().shape({
  is_active: Yup.string().required(
    <FormattedMessage id="validation_enter_status" />
  ),
  order_confirmation_email: Yup.string().required(
    <FormattedMessage id="validation_valid_email_notification" />
  ),
  integration_api_enabled: Yup.string().required(
    <FormattedMessage id="validation_empty_apiintegration" />
  ),
  company_type: Yup.string().required(
    <FormattedMessage id="validation_valid_company_type" />
  ),
});

const PubSchema = Yup.object().shape({
  pub_agreement: Yup.string().required(
    <FormattedMessage id="validation_empty_pub_agreement" />
  ),
  pub_date: Yup.string().when("pub_agreement", {
    is: pubAgreement => pubAgreement === 1,
    then: schema =>
      schema.required(
        <FormattedMessage id="validation_pub_agreement_required" />
      ),
    otherwise: schema => schema.nullable(),
  }),
});

export { NameSchema, ContactSchema, SettingSchema, PubSchema };
