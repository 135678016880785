import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import EditFormDialog from "@components/EditFormDialog";
import ProgressSpinner from "@components/ProgressSpinner";

import { SUBSCRIPTION_STATUS } from "@utils/constant";

import ActiveSubscription from "../../ActiveSubscription";
import SubcsriptionCard from "../../SubcsriptionCard";

import { ValidationSchema } from "./CustomerActiveSubscriptionTab.schema";
import useCustomerActiveSubscription from "./useCustomerActiveSubscription";

const CustomerActiveSubscriptionTab = () => {
  const { messages } = useIntl();
  const userInfo = useSelector(state => state.authReducer.userInfo);
  const {
    activityLog,
    subscriptionPackages,
    activePackage,
    createSubscriptionConfig,
    isFetching,
    showCreateSubscriptionModal,
    handleShowCreateSubscriptionModal,
    handleCreateSubscriptionRequest,
    handleUpdateSubscription,
    subscriptionStatus,
  } = useCustomerActiveSubscription();

  return isFetching ? (
    <ProgressSpinner />
  ) : (
    <>
      {showCreateSubscriptionModal && (
        <EditFormDialog
          onHide={handleShowCreateSubscriptionModal}
          onSubmit={handleCreateSubscriptionRequest}
          title={messages.title_subscription_request}
          description={messages.description_subscription_request}
          validationSchema={ValidationSchema}
          config={createSubscriptionConfig}
        />
      )}

      {subscriptionStatus === SUBSCRIPTION_STATUS.TERMINATED && (
        <SubcsriptionCard
          userInfo={userInfo}
          activePackage={activePackage}
          activityLogs={activityLog}
          terminated={true}
          onCreateSubscription={handleShowCreateSubscriptionModal}
        />
      )}

      {subscriptionStatus &&
        subscriptionStatus !== SUBSCRIPTION_STATUS.TERMINATED && (
          <ActiveSubscription
            activePackage={activePackage}
            subscriptionPackages={subscriptionPackages}
            onCreateSubscription={handleShowCreateSubscriptionModal}
            onUpdateSubscription={handleUpdateSubscription}
          />
        )}

      {Object.keys(activePackage ?? {}).length === 0 && (
        <SubcsriptionCard
          userInfo={userInfo}
          activePackage={false}
          activityLogs={activityLog}
          onCreateSubscription={handleShowCreateSubscriptionModal}
        />
      )}
    </>
  );
};

export default CustomerActiveSubscriptionTab;
