import { FunctionFactory } from "survey-core";
import moment from "moment";
import "moment/dist/locale/sv";

export const registerCustomFunctions = () => {
  // Register custom functions
  FunctionFactory.Instance.register("toLowerCase", function (params) {
    const [str] = params;
    return str ? str.toLowerCase() : "";
  });

  FunctionFactory.Instance.register("capitalize", params => {
    const [str] = params;
    if (!str) return "";
    if (str.length < 2) return str.charAt(0).toUpperCase();
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  });

  FunctionFactory.Instance.register("formatDate", params => {
    const [
      date,
      outputFormat = "D MMMM YYYY",
      inputFormat = "YYYY-MM-DD",
      language = "sv",
    ] = params;
    moment.locale(language);

    const parsedDate = moment(date, inputFormat, true);

    if (!parsedDate.isValid()) {
      return "";
    }

    return parsedDate.format(outputFormat);
  });
};
