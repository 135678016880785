import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { deleteUserProfile } from "@app/services/users/deleteUserProfileService";

import { ButtonLinkIcon } from "@components/Button";
import Card from "@components/Card";
import ConfirmationPopup from "@components/ConfirmationPopup";
import { default as Div } from "@components/Div";
import Icon from "@components/Icon";
import { Text, TextMediumWeight } from "@components/Text";

import { useToast } from "@hooks/useToast";

const MyProfileDeleteAccountCard = () => {
  const { messages } = useIntl();
  const { id } = useSelector(state => state.authReducer.userInfo);
  const { showErrorToast, showSuccessToast } = useToast();

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogVisible(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleDeleteSubmit = async () => {
    try {
      await deleteUserProfile({
        id,
      });

      localStorage.clear();

      showSuccessToast(messages.text_deleted_successful);
      return;
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  return (
    <>
      {isDeleteDialogVisible && (
        <ConfirmationPopup
          title={messages.label_delete_account}
          description={messages.user_delete_message}
          onCancelClick={handleCloseDeleteDialog}
          onAcceptClick={handleDeleteSubmit}
        />
      )}

      <Card p={3} borderRadius="20px" width={1}>
        <Div width={1} display="flex" flexDirection="column" gridGap="12px">
          <Div
            display="flex"
            flexDirection={["column", "column", "row", "row"]}
            alignItems="flex-start"
            justifyContent={[
              "flex-start",
              "flex-start",
              "space-between",
              "space-between",
            ]}
            gridGap={[3, 3, 0, 0]}
          >
            <Div display="flex" flexDirection="column" gridGap={2}>
              <Div
                display="flex"
                flexDirection="row"
                alignItems="center"
                gridGap={1}
              >
                <TextMediumWeight>
                  {messages.label_delete_account}
                </TextMediumWeight>

                <Icon
                  name="sign"
                  fontSize="var(--fs-icon-m)"
                  color="var(--red)"
                />
              </Div>

              <Text>{messages.message_delete_account}</Text>
            </Div>

            <ButtonLinkIcon
              p={0}
              label={messages.label_delete_account}
              onClick={handleOpenDeleteDialog}
              icon={
                <Icon
                  name="trash"
                  fontSize="var(--fs-icon-m)"
                  color="var(--turquoise)"
                  mr={1}
                />
              }
            />
          </Div>
        </Div>
      </Card>
    </>
  );
};

export default MyProfileDeleteAccountCard;
