import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import queryString from "query-string";

import { default as getDetails } from "@app/services/orders/fetchOrderDetails";

import ProgressSpinner from "@components/ProgressSpinner";

import { useToast } from "@hooks/useToast";

import UserContainer from "@layout/UserContainer";

import { ROUTES } from "@utils/constant";

import OrderDetails from "../../shared/orderDetails/OrderDetails";

const UserOrderDetails = () => {
  const { messages } = useIntl();
  const { search = "" } = useLocation();
  const { customer: { id: customerId = "" } = {} } = useSelector(
    state => state.authReducer.userInfo
  );
  const { showErrorToast } = useToast();

  const [orderData, setOrderData] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const isLoading = !isDataLoaded;

  useEffect(() => {
    const { id: orderId = "" } = queryString.parse(search);

    const getOrderDetails = async () => {
      try {
        setIsDataLoaded(false);

        const res = await getDetails(orderId);

        setOrderData(res);
      } catch (error) {
        showErrorToast(messages.exception_error_message);
      } finally {
        setIsDataLoaded(true);
      }
    };

    getOrderDetails();
  }, [messages.exception_error_message, search, showErrorToast]);

  const headerConfig = {
    title: messages.label_order + " #" + orderData.order_id,
  };

  return (
    <UserContainer config={headerConfig} isLoading={isLoading}>
      {isLoading ? (
        <ProgressSpinner />
      ) : (
        <OrderDetails
          orderSummaryProps={orderData}
          redirectTo={ROUTES.ORDERS_LIST.URL}
          customerId={customerId}
          isCustomer
          onUpdateOrderSummaryProps={setOrderData}
        />
      )}
    </UserContainer>
  );
};

export default UserOrderDetails;
