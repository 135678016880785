import React from "react";
import { ElementFactory, Question, Serializer, settings } from "survey-core";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from "survey-react-ui";
import {
  localization,
} from "survey-creator-core";

const RICH_CONTENT_EDITOR = "rich_content_editor";
const DEFAULT_FONT_SIZE = "14px";

const FontSizes = [DEFAULT_FONT_SIZE, "24px", "20px", "18px", "16px", "12px"];
const Size = Quill.import("attributors/style/size");
Size.whitelist = FontSizes;
Quill.register(Size, true);

const Font = Quill.import("formats/font");
Font.whitelist = ["Noto Sans"];
Quill.register(Font, true);

const toolbarOptions = [
  [{ header: [1, 2, false] }],
  [{ size: FontSizes }],
  ["bold", "italic", "underline"],
  [{ background: [] }, { color: [] }],
  [{ list: "ordered" }],
  [{ align: [] }],
  ["blockquote", "code-block", "link", "image"],
  ["clean"],
];

// Create a question model
export class QuestionQuillModel extends Question {
  getType() {
    return RICH_CONTENT_EDITOR;
  }
  get height() {
    return this.getPropertyValue("height");
  }
  set height(val) {
    this.setPropertyValue("height", val);
  }
}

// Create a class that renders Quill
export class SurveyQuestionQuill extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);

    this.handleValueChange = val => {
      this.question.value = val;
    };
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  // Support the read-only and design modes
  get style() {
    return { height: this.question.height };
  }

  renderQuill() {
    const isReadOnly = this.question.isReadOnly || this.question.isDesignMode;

    return (
      <ReactQuill
        readOnly={isReadOnly}
        value={this.value}
        modules={{
          toolbar: toolbarOptions,
        }}
        onChange={this.handleValueChange}
      />
    );
  }

  renderElement() {
    return <div style={this.style}>{this.renderQuill()}</div>;
  }
}

function applyTranslations() {
  const locale = localization.getLocale("");

  locale.qt[RICH_CONTENT_EDITOR] = "Rich Content Editor";
}

function applyIcon() {
  settings.customIcons[`icon-${RICH_CONTENT_EDITOR}`] = "icon-html";
}

export function registerQuillRichEditor() {
  // Register the model in `ElementFactory`
  ElementFactory.Instance.registerElement(RICH_CONTENT_EDITOR, name => {
    return new QuestionQuillModel(name);
  });

  Serializer.addClass(
    RICH_CONTENT_EDITOR,
    [{ name: "height", default: "250px", category: "layout" }],
    function () {
      return new QuestionQuillModel("");
    },
    "question"
  );

  ReactQuestionFactory.Instance.registerQuestion(RICH_CONTENT_EDITOR, props => {
    return React.createElement(SurveyQuestionQuill, props);
  });

  applyTranslations();
  applyIcon();
}
