import React, { useState, useEffect, memo, useRef, useCallback } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import queryString from "query-string";

import getReportAnalys from "@app/services/reports/getReportDetails";

import Div from "@components/Div";
import FullSizeDialog from "@components/FullSizeDialog";
import PrivacyPolicyFooter from "@components/PrivacyPolicyFooter";
import ProgressSpinner from "@components/ProgressSpinner";
import CaseReport from "@components/Report/CaseReport/CaseReport";
import { CreditsReportDialog } from "@components/Report/components";
import ExpiredReport from "@components/Report/ExpiredReport/ExpiredReport";
import AssignmentReport from "@components/Report/LegacyReport/AssignmentReport";
import LegacyReport from "@components/Report/LegacyReport/LegacyReport";

import { useToast } from "@hooks/useToast";

import { trackPageView } from "@src/analyticsFunctions";

import {
  ORDER_REPORT_ANCHOR_ID,
  ROUTES,
  STATUS_EXPIRED,
} from "@utils/constant";

const getDetails = async (reportId, token) => {
  const query = queryString.stringify(
    { token: token },
    { skipNull: true, skipEmptyString: true }
  );
  const response = await getReportAnalys(reportId, query);

  return response;
};

const Report = ({ currentReport }) => {
  const history = useHistory();
  const { messages } = useIntl();
  const { search = "" } = useLocation();
  const { report_id } = useParams();
  const { showErrorToast } = useToast();

  const [report, setReport] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [shouldShowCreditsReportDialog, setShouldShowCreditsReportDialog] =
    useState(false);

  const apiCalled = useRef(false); // Tracks if the API has already been called

  const { id: reportID = "", token } = queryString.parse(search);

  const reportId = reportID || report_id;

  const {
    documents = [],
    analysis_type: analysisType = "",
    is_case: isCase,
  } = report;

  const isAssignment = analysisType === "manual";
  const assignmentReport = documents[0];
  const assignmentDetails = {
    url: assignmentReport?.url,
    productName: report?.product?.name,
  };
  const reportData = isCase ? report?.report_data : report;
  const objectData = {
    personal_number: reportData?.personal_number
      ?.toString?.()
      .replace?.("-", ""),
    person_name: reportData?.name,
    email: reportData?.email ?? "",
    phone: reportData?.phone ?? "",
  };

  useEffect(() => {
    const openCreditsReportDialog = () => {
      setShouldShowCreditsReportDialog(true);
    };

    const processReportElements = () => {
      const element = document.querySelector(`#${ORDER_REPORT_ANCHOR_ID}`);

      if (element) {
        element.addEventListener("click", openCreditsReportDialog);

        observer?.disconnect();
      }
    };

    const observer = new MutationObserver(processReportElements);

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      const element = document.querySelector(`#${ORDER_REPORT_ANCHOR_ID}`);

      if (element) {
        element.removeEventListener("click", openCreditsReportDialog);
      }

      observer.disconnect();
    };
  }, [objectData?.personal_number]);

  const getReport = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await getDetails(reportId, token);

      const { data: { data: _report = {} } = {} } = response;
      const { report_status: reportStatusLabel = "" } = _report;

      if (reportStatusLabel === STATUS_EXPIRED) {
        setIsLoading(false);
        setIsExpired(true);
        return;
      }
      setReport(_report);
    } catch (e) {
      showErrorToast(messages.error);

      if (e?.response?.status === 403) {
        setIsExpired(true);
      }
    } finally {
      setIsLoading(false);

      apiCalled.current = false;
    }
  }, [messages.error, reportId, showErrorToast, token]);

  useEffect(() => {
    trackPageView();

    if (currentReport) {
      setReport(currentReport);
    } else if (!apiCalled.current) {
      getReport();

      apiCalled.current = true;
    }
  }, [
    currentReport,
    getReport,
    messages.error,
    reportId,
    showErrorToast,
    token,
  ]);

  const reloadDetails = () => {
    getReport();
  };

  const handleCloseResultsDialog = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(ROUTES.ALLANALYSIS_LIST.URL);
    }
  };

  const handleCloseCreditsReportDialog = () => {
    setShouldShowCreditsReportDialog(false);
  };

  return (
    <FullSizeDialog
      title={messages.label_report}
      onClose={handleCloseResultsDialog}
    >
      {isLoading && (
        <Div minHeight="100vh" width={1}>
          <ProgressSpinner />
        </Div>
      )}

      {shouldShowCreditsReportDialog && (
        <CreditsReportDialog
          reportId={reportId}
          objectData={objectData}
          onClose={handleCloseCreditsReportDialog}
        />
      )}

      {!isLoading &&
        (isExpired ? (
          <ExpiredReport />
        ) : (
          <>
            {isCase && <CaseReport report={report} />}

            {isAssignment && (
              <AssignmentReport assignment={assignmentDetails} />
            )}

            {!isAssignment && !isCase && (
              <LegacyReport
                report={report}
                reloadDetails={reloadDetails}
                isLoading={isLoading}
              />
            )}
          </>
        ))}

      <Div mt={4}>
        <PrivacyPolicyFooter />
      </Div>
    </FullSizeDialog>
  );
};

Report.propTypes = {
  currentReport: PropTypes.object,
};

export default memo(Report);
