import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import Div from "@components/Div";
import PrivacyPolicyFooter from "@components/PrivacyPolicyFooter";

import UserContainer from "@layout/UserContainer";

import SSNSearch from "@pages/shared/CreateOrderWizard/SSNSearch";

import { ROUTES, SSNNUMBER } from "@utils/constant";

const Search = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const { search } = useLocation();

  const [ssnNumber, setSsnNumber] = useState([]);

  const handleOpenWizardDialog = useCallback(
    params => {
      const query = new URLSearchParams(params);

      history.push({
        pathname: ROUTES.WIZARD_SEARCH.URL,
        search: query.toString(),
      });
    },
    [history]
  );

  useEffect(() => {
    const query = new URLSearchParams(search);
    const value = query.get(SSNNUMBER);

    if (!value) {
      return;
    }

    handleOpenWizardDialog(query);
  }, [handleOpenWizardDialog, search]);

  const handleSearch = useCallback(() => {
    handleOpenWizardDialog({ [SSNNUMBER]: ssnNumber });
  }, [handleOpenWizardDialog, ssnNumber]);

  const headerConfig = {
    title: messages.search_text,
  };

  return (
    <UserContainer config={headerConfig} hideGdprInfo={true}>
      <Div mt={3} position="relative">
        <SSNSearch
          ssnNumbers={ssnNumber}
          onSearchSSN={handleSearch}
          onChange={setSsnNumber}
          isAuthenticated={true}
        />
      </Div>

      <Div
        width={["95%", "95%", "95%", "80%"]}
        position="absolute"
        bottom="32px"
      >
        <PrivacyPolicyFooter />
      </Div>
    </UserContainer>
  );
};

export default Search;
