import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import getEmailLogById from "@app/services/cases/getEmailLogById";

import Div from "@components/Div";
import EditableFieldsCard from "@components/EditableFieldsCard";
import FullSizeDialog from "@components/FullSizeDialog";

import { ROUTES } from "@utils/constant";
import { EMAIL_CLASSIFICATION_TYPE, FORM_INPUT_TYPE } from "@utils/enum";
import { formatDateAndTimeForEmailLog } from "@utils/utils";

const getConfig = data => {
  const bodyToRender = data?.is_secure_email
    ? data?.secure_body_html
    : data?.body_html;
  const configuration = {
    details: [
      {
        key: "id",
        translationKey: "title_id",
        type: FORM_INPUT_TYPE.TEXT,
        isReadOnlyField: true,
        value: data?.id,
      },
      {
        key: "created_at",
        translationKey: "created_at",
        type: FORM_INPUT_TYPE.CALENDAR,
        isReadOnlyField: true,
        value: (data?.created_at && formatDateAndTimeForEmailLog(data?.created_at)) || "",
      },
      {
        key: "case_source_data_id",
        translationKey: "label_data_source_id",
        type: FORM_INPUT_TYPE.TEXT,
        isReadOnlyField: true,
        value: data?.case_source_data_id,
      },
      {
        key: "email_template_id",
        translationKey: "label_template_id",
        type: FORM_INPUT_TYPE.TEXT,
        isReadOnlyField: true,
        value: data?.email_template_id,
      },
      {
        key: "court.court_name",
        translationKey: "label_court_name",
        type: FORM_INPUT_TYPE.TEXT,
        isReadOnlyField: true,
        value: data?.court?.court_name,
      },
      {
        key: "outgoing_email_message_id",
        translationKey: "label_parent_email_id",
        type: FORM_INPUT_TYPE.TEXT,
        isReadOnlyField: true,
        value: data?.outgoing_email_message_id,
      },
      {
        key: "account?.from_name",
        translationKey: "label_email_account_name",
        type: FORM_INPUT_TYPE.TEXT,
        isReadOnlyField: true,
        value: data?.account?.from_name,
      },
    ],
    email: [
      {
        key: "from",
        translationKey: "label_from_email",
        type: FORM_INPUT_TYPE.TEXT,
        isReadOnlyField: true,
        value: data?.from,
      },
      {
        key: "to",
        translationKey: "label_to_email",
        type: FORM_INPUT_TYPE.TEXT,
        isReadOnlyField: true,
        value: data?.to,
      },
      {
        key: "subject",
        translationKey: "label_subject",
        type: FORM_INPUT_TYPE.TEXT,
        isReadOnlyField: true,
        value: data?.subject,
      },
      {
        type: FORM_INPUT_TYPE.FILLER,
      },
      {
        key: "body_html",
        translationKey: "label_email_body",
        type: FORM_INPUT_TYPE.EDITOR,
        isReadOnlyField: true,
        value: bodyToRender,
        fullWidth: true,
      },
      {
        key: "attachments",
        translationKey: "label_attachments",
        type: FORM_INPUT_TYPE.FILES,
        isReadOnlyField: true,
        value: data?.attachments,
        fullWidth: true,
        options: data?.attachments,
        isHidden: !data?.attachments?.length,
      },
    ],
    email_analyser: [
      {
        key: "is_secure_email",
        translationKey: "label_secure_email",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: <FormattedMessage id="yes_label" />,
            value: true,
          },
          {
            label: <FormattedMessage id="no_label" />,
            value: false,
          },
        ],
        selectorField: "label",
        value: data?.is_secure_email,
        isReadOnlyField: true,
      },
      {
        key: "email_reply_classification",
        translationKey: "label_email_classification",
        type: FORM_INPUT_TYPE.DROPDOWN,
        options: [
          {
            label: (
              <FormattedMessage
                id={EMAIL_CLASSIFICATION_TYPE.AUTOMATIC_REPLY}
              />
            ),
            value: EMAIL_CLASSIFICATION_TYPE.AUTOMATIC_REPLY,
          },
          {
            label: (
              <FormattedMessage
                id={EMAIL_CLASSIFICATION_TYPE.NO_CRIMINAL_CASES}
              />
            ),
            value: EMAIL_CLASSIFICATION_TYPE.NO_CRIMINAL_CASES,
          },
          {
            label: (
              <FormattedMessage
                id={EMAIL_CLASSIFICATION_TYPE.CRIMINAL_CASES_WITH_DOCUMENTS}
              />
            ),
            value: EMAIL_CLASSIFICATION_TYPE.CRIMINAL_CASES_WITH_DOCUMENTS,
          },
          {
            label: (
              <FormattedMessage id={EMAIL_CLASSIFICATION_TYPE.SECURE_EMAIL} />
            ),
            value: EMAIL_CLASSIFICATION_TYPE.SECURE_EMAIL,
          },
        ],
        selectorField: "label",
        value: data?.email_reply_classification,
        isReadOnlyField: true,
      },
    ],
  };

  return configuration;
};

const UpdateEmailLog = () => {
  const history = useHistory();
  const { messages } = useIntl();
  const { email_id: emailLogId } = useParams();

  const [emailLogDetails, setEmailLogDetails] = useState({});

  useEffect(() => {
    const fetchEmailLogDetails = async () => {
      const { data: emailLogsData } = await getEmailLogById(emailLogId);

      const [selectedEmailLog] = emailLogsData;

      if (selectedEmailLog) {
        const { attachments } = selectedEmailLog;
        const formattedAttachments = attachments?.map(
          ({ id, file_name, url }) => ({
            id,
            url,
            label: file_name,
          })
        );
        const formattedDetails = {
          ...selectedEmailLog,
          attachments: formattedAttachments,
          is_secure_email: !!selectedEmailLog.is_secure_email,
        };

        setEmailLogDetails(formattedDetails);
      }
    };

    fetchEmailLogDetails();
  }, [emailLogId]);

  const config = getConfig(emailLogDetails);

  const handleClose = () => {
    history.push(ROUTES.ADMIN_EMAIL_LOG.URL);
  };

  return (
    <FullSizeDialog
      title={
        <FormattedMessage
          id="title_details_of_id"
          defaultMessage="Details of"
          values={{ id: `#${emailLogDetails?.id}` }}
        />
      }
      onClose={handleClose}
    >
      <Div>
        <EditableFieldsCard
          title={messages.label_details}
          config={config.details}
          isEditPermission={false}
        />
        <EditableFieldsCard
          title={messages.label_email}
          config={config.email}
          isEditPermission={false}
        />
        <EditableFieldsCard
          title={messages.label_email_analyser}
          config={config.email_analyser}
          isEditPermission={false}
        />
      </Div>
    </FullSizeDialog>
  );
};

export default UpdateEmailLog;
