export const searchableColumns = [
  "id",
  "billingAccount.invoice_remark",
  "billingAccount.your_reference",
  "fortnox_id",
  "billingAccount.name",
  "billingAccount.fortnox_customer_id",
  "customer.customer_name",
];

export const FILTER_KEY_FROM = "filter[invoice_date][gte]";

export const FILTER_KEY_TO = "filter[invoice_date][lte]";
