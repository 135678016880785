import React from "react";
import PropTypes from "prop-types";

import Dialog from "@components/Dialog";

import ErrorModal from "@components/Report/LegacyReport/ErrorModal";

const ErrorDialog = ({ errorMessage, onHide, onConfirm }) => {
  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 1, "450px", "450px"]}
      onHide={onHide}
      m={[3, 3, 3, "auto"]}
    >
      <ErrorModal handleConfirmPopup={onConfirm} content={errorMessage} />
    </Dialog>
  );
};

ErrorDialog.propTypes = {
  errorMessage: PropTypes.string,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ErrorDialog;
