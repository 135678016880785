import React, { useState } from "react";
import { useIntl } from "react-intl";

import DataTable from "@components/DataTableV2/DataTable";

import useAuthorization from "@hooks/useAuthorization";

import AdminContainer from "@layout/AdminContainer";

import { AdminPermissions } from "@src/enum/Permissions";

import { PRODUCTS, ROUTES } from "@utils/constant";
import { PRODUCT_TYPE } from "@utils/enum";

import EditSubscriptionPackageDialog from "./EditSubscriptionPackageDialog";

const SEARCHABLE_COLUMNS = ["id", "name", "credits", "price", "type"];

const SubscriptionPackageList = () => {
  const { messages } = useIntl();
  const { hasAllPermissions } = useAuthorization();

  const [showAddSubscriptionDialog, setShowAddSubscriptionDialog] =
    useState(false);

  const hasProductsPermission = hasAllPermissions([
    AdminPermissions.AdminWriteProducts,
  ]);

  const handleCreateNewSubscription = () => {
    setShowAddSubscriptionDialog(!showAddSubscriptionDialog);
  };

  const handleEditSubscription = ({ id }) =>
    ROUTES.ADMIN_UPDATE_SUBSCRIPTION.URL.replace(":id", id);

  const dataTableConfig = {
    header: {
      title: "label_subscription_packages",
      actions: [
        {
          id: "new-product",
          type: "button",
          label: messages.button_add_new,
          onClick: handleCreateNewSubscription,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: !hasProductsPermission,
        },
      ],
    },
    enable_search: false,
    enable_filter: false,
    enable_csv_download: false,
    backend_querying: true,
    no_records_message: "no_results_found",
    enable_query_builder: true,
    api: {
      resource: `/${PRODUCTS}`,
      method: "GET",
      search_fields: SEARCHABLE_COLUMNS,
      params: [
        ["fields", SEARCHABLE_COLUMNS],
        ["filter[type]", PRODUCT_TYPE.SUBSCRIPTION],
      ],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        db_field: "name",
        title: "label_subscription_name",
        type: "text",
      },
      {
        db_field: "credits",
        title: "analysis",
        type: "text",
      },
      {
        db_field: "price",
        title: "watchlist_label_price",
        type: "currency",
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            type: "link",
            href: handleEditSubscription,
          },
        ],
      },
    ],
  };

  const headerConfig = {
    title: messages.label_subscription_packages,
  };

  return (
    <AdminContainer config={headerConfig} wide>
      <DataTable config={dataTableConfig} />

      {showAddSubscriptionDialog && (
        <EditSubscriptionPackageDialog
          handleClose={handleCreateNewSubscription}
        />
      )}
    </AdminContainer>
  );
};
export default SubscriptionPackageList;
