import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";

import Div from "@components/Div";
import { PrimaryButton } from "@components/Button";
import StyledInputText from "@components/StyledInputText";

const CouponCodeInputText = styled(StyledInputText)`
  border-radius: 10px !important;
  margin-right: -20px !important;
`;

const ApplyCouponButton = styled(PrimaryButton)`
  z-index: 1;
  border-radius: 50px !important;
`;

const CouponCodeInput = ({ onCouponCodeSubmitted }) => {
  const { messages } = useIntl();

  const [couponCodeText, setCouponCodeText] = useState("");

  const handleCouponCodeTextChange = event => {
    setCouponCodeText(event.target.value.toUpperCase());
  };

  const handleCouponCodeSubmit = () => {
    if (couponCodeText == "") {
      return;
    }

    onCouponCodeSubmitted(couponCodeText);
  };

  return (
    <Div className="p-inputgroup">
      <CouponCodeInputText
        placeholder={messages.coupon_code_title}
        value={couponCodeText.toUpperCase()}
        onChange={handleCouponCodeTextChange}
      />

      <ApplyCouponButton
        width={["110px", "200px", "200px", "200px"]}
        justifyContent="center"
        label={messages.label_apply}
        onClick={handleCouponCodeSubmit}
      />
    </Div>
  );
};

CouponCodeInput.propTypes = {
  hasError: PropTypes.bool,
  onCouponCodeSubmitted: PropTypes.func,
};

export default CouponCodeInput;
