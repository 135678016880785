import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { authActions } from "@app/reducers/authReducer";

import getUserDetails from "@app/services/auth/getUserDetails";

import { parseJwt } from "@utils/utils";

const INITIAL_USER_INFO = {
  user: {},
  isLogout: true,
};

export const AuthContext = createContext({
  initializeUser: () => {},
  clearUser: () => {},
  isLoading: true,
  isAdmin: false,
  isCustomer: false,
  isAuthenticated: false,
});

export const isTokenValid = () => {
  const token = localStorage.getItem("token");
  try {
    const jwtPayload = parseJwt(token);
    if (jwtPayload) {
      return jwtPayload.exp > +new Date() / 1000;
    }
  } catch (e) {
    throw new Error(e);
  }
  return false;
};

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const clearUser = useCallback(() => {
    dispatch(authActions.storeUserInfo(INITIAL_USER_INFO));
    setIsAdmin(false);
    setIsCustomer(false);
    setIsAuthenticated(false);
    localStorage.clear();
  }, [dispatch]);

  const initializeUser = useCallback(async (user) => {
    if (!isTokenValid()) {
      clearUser();
      setIsLoading(false);
      return;
    }

    try {
      let status = "";

      if (!user) {
        const { data: { data: _user = {} } = {}, status: _status = "" } = await getUserDetails();
        user = _user;
        status = _status;
      }

      if (status === 401) {
        throw new Error("Unauthorized");
      }

      const token = localStorage.getItem("token");
      const decodedToken = parseJwt(token);

      setIsAuthenticated(
        decodedToken.scopes.some((scope) =>
          ["admin", "customer"].includes(scope),
        ),
      );
      setIsAdmin(decodedToken.scopes.includes("admin"));
      setIsCustomer(decodedToken.scopes.includes("customer"));
      dispatch(authActions.storeUserInfo(user));
    } catch (error) {
      clearUser();
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, clearUser]);

  useEffect(() => {
    initializeUser();

    // Listen for changes in localStorage
    const handleStorageChange = (event) => {
      if (event.key === "token") {
        initializeUser();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [initializeUser]);

  const value = useMemo(
    () => ({
      isLoading,
      isAdmin,
      isCustomer,
      isAuthenticated,
      initializeUser,
      clearUser
    }),
    [isLoading, isAdmin, isCustomer, isAuthenticated, initializeUser, clearUser],
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export const useUser = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useUser must be used within a AuthProvider");
  }
  return context;
};

