import React, { useCallback, useEffect, useMemo } from "react";
import QRCode from "react-qr-code";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import {
  ButtonLinkIcon,
  PrimaryButton,
  PrimaryButtonOutlined,
} from "@components/Button";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import Icon from "@components/Icon";
import { OrderedList } from "@components/List";
import { Text, TextSemiBoldWeight } from "@components/Text";

import { useToast } from "@hooks/useToast";

import {
  BANKID_MESSAGE_TERM,
  COMPLETE_STATUS,
  FAILED_STATUS,
  PENDING_STATUS,
} from "@utils/constant";

const BANK_ID_STEPS = [
  <Text key="message_bankID_step_1" fontSize="var(--fs-text-small)">
    <FormattedMessage id="message_bankID_step_1" />
  </Text>,
  <Text key="message_bankID_step_2" fontSize="var(--fs-text-small)">
    <FormattedMessage id="message_bankID_step_2" />
  </Text>,
  <Text key="message_bankID_step_3" fontSize="var(--fs-text-small)">
    <FormattedMessage id="message_bankID_step_3" />
  </Text>,
];

const BankIDModal = ({
  hideModal,
  qrCodeData,
  bankIDFeedback,
  onTryAgain,
  onUseApp,
}) => {
  const { messages } = useIntl();
  const { showErrorToast } = useToast();

  const { status = "", message_term: messageTerm = "" } = bankIDFeedback;

  const parseFeedback = useCallback(
    (status, messageTerm) => {
      if ((!status && !messageTerm) || status === COMPLETE_STATUS) {
        return "";
      }

      if (status === PENDING_STATUS) {
        switch (messageTerm) {
          case BANKID_MESSAGE_TERM.OUTSTANDING_TRANSACTION:
          case BANKID_MESSAGE_TERM.NO_CLIENT:
            return messages.message_bankID_start_your_bankID;

          case BANKID_MESSAGE_TERM.USER_SIGN:
            return messages.message_bankID_enter_security_code;

          case BANKID_MESSAGE_TERM.STARTED:
            return messages.message_bankID_searching;

          case BANKID_MESSAGE_TERM.USER_MRTD:
            return messages.message_bankID_process_travel_document;

          default:
            return messages.message_bankID_identification_in_progress;
        }
      } else if (status === FAILED_STATUS) {
        switch (messageTerm) {
          case BANKID_MESSAGE_TERM.USER_CANCEL:
            return messages.message_bankID_action_cancelled;

          case BANKID_MESSAGE_TERM.EXPIRED_TRANSACTION:
            return messages.message_bankID_not_responding;

          case BANKID_MESSAGE_TERM.CERTIFICATE_ERR:
            return messages.message_bankID_blocked;

          case BANKID_MESSAGE_TERM.START_FAILED:
            return messages.message_bankID_not_found;

          default:
            return messages.message_bankID_unknown_error;
        }
      } else {
        switch (messageTerm) {
          case BANKID_MESSAGE_TERM.CANCELLED:
            return messages.message_bankID_cancelled;

          case BANKID_MESSAGE_TERM.ALREADY_IN_PROGRESS:
            return messages.message_bankID_already_started;

          default:
            return messages.message_bankID_internal_error;
        }
      }
    },
    [messages]
  );

  const isBankIdFailed = useMemo(
    () =>
      status === PENDING_STATUS &&
      messageTerm === BANKID_MESSAGE_TERM.START_FAILED,
    [messageTerm, status]
  );

  const title = useMemo(
    () =>
      isBankIdFailed
        ? messages.label_bankID_login_failed
        : messages.label_log_in_with_bankID,
    [
      isBankIdFailed,
      messages.label_bankID_login_failed,
      messages.label_log_in_with_bankID,
    ]
  );

  useEffect(() => {
    if (status !== FAILED_STATUS) {
      return;
    }

    const errorMessage = parseFeedback(status, messageTerm);

    showErrorToast(errorMessage);

    hideModal();
  }, [hideModal, messageTerm, parseFeedback, showErrorToast, status]);

  return (
    <Dialog
      visible="displayBasic"
      draggable={false}
      width={[1, 445, 445, 445]}
      onHide={hideModal}
      m={[3, 3, "auto", "auto"]}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gridGap="20px"
      >
        <Icon
          name="bankID"
          color="var(--blue-bank-id-logo)"
          fontSize="var(--fs-icon-xl)"
        />

        <TextSemiBoldWeight textAlign="center">{title}</TextSemiBoldWeight>

        {status && messageTerm && (
          <Text fontSize="var(--fs-text-small)">
            {parseFeedback(status, messageTerm)}
          </Text>
        )}

        <OrderedList content={BANK_ID_STEPS} />

        {qrCodeData && !isBankIdFailed && (
          <QRCode size={200} value={qrCodeData} />
        )}

        {isBankIdFailed && (
          <PrimaryButton
            width="150px"
            justifyContent="center"
            onClick={onTryAgain}
          >
            {messages.label_try_again}
          </PrimaryButton>
        )}

        <ButtonLinkIcon
          label={messages.label_open_bank_id_on_device}
          onClick={onUseApp}
          icon="icon-bankID"
        />

        <PrimaryButtonOutlined
          width="150px"
          label={messages.label_cancel}
          onClick={hideModal}
        />
      </Div>
    </Dialog>
  );
};

BankIDModal.propTypes = {
  hideModal: PropTypes.func,
  qrCodeData: PropTypes.string,
  bankIDFeedback: PropTypes.object,
  onTryAgain: PropTypes.func,
  onUseApp: PropTypes.func,
};

export default BankIDModal;
