import React, { useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import Breadcrumb from "@components/Breadcrumb";
import Tabs from "@components/Tabs";

import UserContainer from "@layout/UserContainer";

import { ROUTES } from "@utils/constant";

import SubscriptionOrders from "../SubscriptionOrders";

import { CustomerActiveSubscriptionTab } from "./tabs";

const Subscription = () => {
  const history = useHistory();
  const { messages } = useIntl();

  const [activeIndex, setActiveIndex] = useState(0);

  const headerConfig = {
    title: messages.subscription,
  };

  const breadCrumbItems = [
    {
      label: messages.my_profile,
    },
    { label: messages.subscriptions_title },
  ];

  const handleTabChange = ({ index }) => {
    history.push({
      pathname: TAB_CONTENT[index]?.url,
    });
  };

  const TAB_CONTENT = useMemo(
    () => [
      {
        title: messages.subscription,
        content: <CustomerActiveSubscriptionTab />,
        url: ROUTES.SUBSCRIPTION.URL,
      },
      {
        title: messages.title_orders,
        content: <SubscriptionOrders />,
        url: ROUTES.SUBSCRIPTION_ORDERS_MY_ACCOUNTS.URL,
      },
    ],
    [messages.subscription, messages.title_orders]
  );

  useEffect(() => {
    const {
      location: { pathname },
    } = history;
    const activeTabIndex = TAB_CONTENT.findIndex(item => item.url === pathname);
    setActiveIndex(activeTabIndex);
  }, [TAB_CONTENT, history]);

  return (
    <UserContainer config={headerConfig}>
      <Breadcrumb p={0} mb={3} items={breadCrumbItems} />

      <Tabs
        content={TAB_CONTENT}
        onTabChange={handleTabChange}
        activeIndex={activeIndex}
      />
    </UserContainer>
  );
};

export default Subscription;
