const host = "https://api.app.bakgrundsanalys.se/";
const wpHost = "https://bakgrundsanalys.se";

export default {
  domain: host,
  BASE_URL: `${host}api/v1/`,
  baseName: "/",
  language: "sv",
  mobileBreakPoint: "desktopSmall",
  WP_URL: wpHost,
  markerDestinationId: "6516b46f0ed8ef89c0dce485",
  APP_URL: "https://app.bakgrundsanalys.se",
  SURVEY_JS_API_KEY:
    "MGNkOTA4MzItMWFmZi00OWFiLWE2MmYtNjBjNGI1MjllYzMyOzE9MjAyNS0wMy0xNA==",
  CREDIT_REPORT_PRODUCT_ID: 109,
};
