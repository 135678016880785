import React from "react";
import SideMenuContent from "./SideMenuContent";
import { ADMIN_NAV } from "@utils/constant";
import Div from "@components/Div";
import { Text } from "@components/Text";
import Logo from "@components/Logo";
import LogoFull from "@assets/ba-logo.svg";
import useAuthorization from "@hooks/useAuthorization";
import _package from "../../package.json";

const handleRedirect = () => {
  window.location = " /sok";
};

const LogoComponent = () => (
  <Div display="flex" flexDirection="column" justifyContent="center" my={4} mx={3}>
    <Logo
      display="block"
      logo={LogoFull}
      width={140}
      height={"auto"}
      onClick={handleRedirect}
    />
    <Div width="100%" display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
      <Text fontSize="var(--fs-text-s)" fontWeight="--regular-weight" color="var(--grey-dark)" bg="var(--turquoise-light)" borderRadius="50px" p={1} px={2}>
        Admin
      </Text>
      <Text fontSize="var(--fs-text-s)" fontWeight="--regular-weight" color="var(--grey-dark)" bg="var(--turquoise-light)" borderRadius="50px" py={1} px={2}>
        {`Version ${_package.version}`}
      </Text>
    </Div>
  </Div>
);

const AdminSideMenu = () => {
  const { hasAnyPermissions } = useAuthorization();
  let menuItems = ADMIN_NAV.filter(obj => {
    const { permissions = [] } = obj;
    return hasAnyPermissions(permissions);
  });

  menuItems = menuItems.map(item => {
    if (item?.isDropDown) {
      const dropDownOptions = item?.dropDownOptions.filter(obj => {
        const { permissions = [] } = obj;
        return hasAnyPermissions(permissions);
      });
      return { ...item, dropDownOptions };
    }
    return { ...item };
  });

  const props = {
    Component: LogoComponent,
    navItems: [...menuItems],
  };
  return <SideMenuContent {...props} />;
};

export default AdminSideMenu;
