import React, { useState } from "react";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";
import { Formik } from "formik";
import AlertPage from "@pages/auth/shared/AlertPage";
import Form from "@components/Form";
import Section from "@components/Section";
import Container from "@components/Container";
import Div from "@components/Div";
import InputText from "@components/InputText";
import { H3, H4 } from "@components/Heading";
import { PrimaryButtonIcon } from "@components/Button";
import Link from "@components/Link";
import { INPUT, LOGIN } from "@utils/constant";
import Email from "@pages/auth/schema/Email";
import useHistory from "@utils/useHistory";
import ErrorMessage from "../../shared/ErrorMessage";
import forgotPasswordService from "@app/services/auth/forgotPasswordService";
import Card from "@components/Card";
import GDPRInformationMessage from "@components/privacyPolicies/GDPRInformationMessage";

export const ForgotPassword = () => {
  const { messages } = useIntl();
  const history = useHistory();
  const { EMAIL } = INPUT.NAME;
  const [isMailSubmitted, setIsMailSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const redirectToLogin = () => {
    history.push(LOGIN);
  };

  const handleEmailSubmit = async (values) => {
    try {
      await forgotPasswordService(values);
      setIsMailSubmitted(true);
    } catch (e) {
      setErrorMessage(messages.error);
      throw new Error(e);
    }
  };

  return (
    <>
      <Section
        pb={"40px !important"}
        flex={1}
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        bg="var(--site-background-color)"
      >
        <Container
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between">
          <Div
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {isMailSubmitted && (
              <Card width={1} maxWidth="465px">
              <Div px={[3, 3, 4, 4]}
                  py="24px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  gridGap={3}>
                <AlertPage
                  sourcePage={messages.title_forgot_password}
                  redirectToLogin={redirectToLogin}
                />
              </Div>
              </Card>
            )}
            {!isMailSubmitted && (
              <Card width={1} maxWidth="465px">
                <Div
                  px={[3, 3, 4, 4]}
                  py="24px"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  gridGap={3}
                >
                  <H3 color="var(--blue-dark) !important" textTransform="none none !important">{messages.title_forgot_password}</H3>
                  <Div textAlign="center">
                    <H4 lineHeight={1.4} fontSize="var(--fs-link-m) !important">
                      {messages.label_fp_text}
                    </H4>
                  </Div>
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={Email}
                    onSubmit={handleEmailSubmit}
                  >
                    {prop => {
                      const { values, handleSubmit, isValid, touched } = prop;
                      return (
                        <Form width={1} >
                          <Div
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <InputText
                              curved
                              placeholder={`${messages.label_email}...`}
                              value={values.email}
                              name={EMAIL}
                              formikProps={prop}
                              label={messages.label_email}
                              width={"100%"}
                              mt={0}
                            />
                            {errorMessage && (
                              <Div display="flex" justifyContent="center">
                                <ErrorMessage errorMessage={errorMessage} />
                              </Div>
                            )}
                            <PrimaryButtonIcon
                              rounded
                              semibold
                              onClick={handleSubmit}
                              disabled={
                                !(isValid && Object.keys(touched).length > 0)
                              }
                              label={messages.label_ok}
                              width={["50%"]}
                              minWidth={160}
                              mt={3}
                              py={2}
                              px={5}
                            />
                          </Div>
                        </Form>
                      );
                    }}
                  </Formik>
                  <Link
                    label={messages.label_back_to_login}
                    onClick={redirectToLogin}
                    direction="left"
                    variant="secondary"
                    mb={2}
                  >
                    <i className="icon-headerarrowright"></i>
                  </Link>
                </Div>
              </Card>
            )}
          </Div>
          <GDPRInformationMessage />
        </Container>
      </Section>
    </>
  );
};

ForgotPassword.propTypes = {
  isAdmin: Proptypes.bool,
};

export default ForgotPassword;
