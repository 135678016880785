import React, { useState } from "react";
import { useIntl } from "react-intl";

import PropTypes from "prop-types";

import generateCreditsReport from "@app/services/reports/generateCreditsReport";
import getReportList from "@app/services/reports/getReportList";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Checkbox from "@components/Checkbox";
import Div from "@components/Div";
import { Text, TextLargeSemiBoldWeight } from "@components/Text";

import Config from "@src/config";
import getCreditReports from "@app/services/reports/getCreditReports";
import DoneModal from "./DoneModal";
import ErrorModal from "./ErrorModal";

export const OrderReportModal = ({
  reportID = "",
  objectData = {},
  handleConfirmPopup = {},
  reloadDetails = () => {},
  oldReport = false,
}) => {
  const { messages } = useIntl();

  const [checked, setChecked] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [mailNotSent, setMailNotSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = e => {
    setChecked(e.checked);
  };

  const resendToMail = async () => {
    setIsSubmitting(true);
    try {
      if (oldReport) {
        await getCreditReports(reportID);
      } else {
        const {
          data: { data: [{ order_id = "" } = {}] = [] },
        } = await getReportList(
          `?filter[search][columns]=id,&filter[search][value]=${reportID}&fields=id,order_id`
        );

        const creditReportOrderItem = {
          service_id: Config.CREDIT_REPORT_PRODUCT_ID,
          personal_number: objectData?.personal_number,
          person_name: objectData?.person_name,
          email: objectData?.email,
          phone: objectData?.phone,
        };
        const payload = {
          order_items: [creditReportOrderItem],
        };

        await generateCreditsReport(order_id, payload);
      }
      setMailSent(true);
      setMailNotSent(false);
      reloadDetails?.();
    } catch (e) {
      setMailNotSent(true);
      setMailSent(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {!mailSent && !mailNotSent && (
        <Div
          pt={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gridGap={3}
        >
          <TextLargeSemiBoldWeight>
            {messages.finansial_report_header}
          </TextLargeSemiBoldWeight>

          <Text>{messages.finansial_popup_para}</Text>

          <Text>{messages.financial_popup_content}</Text>

          <Div display="flex" alignItems="flex-start">
            <Checkbox
              fontSize="var(--fs-text)"
              inputId="policy_check"
              checked={checked}
              onChange={handleChange}
            />
            <Text htmlFor="policy_check" ml={10} color="var(--grey-dark)">
              {messages.finansial_popup_check_text}
            </Text>
          </Div>

          <Div
            display="flex"
            flexDirection={["column", "column", "row", "row"]}
            alignItems="center"
            gridGap={3}
          >
            <PrimaryButtonOutlined
              width={[1, 1, "150px"]}
              label={messages.label_cancel}
              onClick={handleConfirmPopup}
            />

            <PrimaryButton
              width={[1, 1, "150px"]}
              label={messages.label_send}
              onClick={resendToMail}
              disabled={!checked || isSubmitting}
            />
          </Div>
        </Div>
      )}

      {mailSent && <DoneModal handleConfirmPopup={handleConfirmPopup} />}

      {mailNotSent && <ErrorModal handleConfirmPopup={handleConfirmPopup} />}
    </>
  );
};

OrderReportModal.propTypes = {
  reportID: PropTypes.string,
  objectData: PropTypes.obj,
  handleConfirmPopup: PropTypes.func,
  reloadDetails: PropTypes.func,
  oldReport: PropTypes.bool,
};

export default OrderReportModal;
