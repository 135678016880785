import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import ConfirmationPopup from "@components/ConfirmationPopup";

const Delete = ({ onDelete, onCancel, subUser = false }) => {
  const { messages } = useIntl();

  const [isPopupVisible, setIsPopupVisible] = useState(true);

  const handleCancelClick = () => {
    setIsPopupVisible(false);
    if (onCancel) onCancel(); // Call the onCancel callback if provided
  };

  const handleAcceptClick = () => {
    if (onDelete) onDelete(); // Call the onDelete callback if provided
    setIsPopupVisible(false);
  };

  return (
    isPopupVisible && (
      <ConfirmationPopup
        title={
          subUser ? messages.title_delete_sub_user : messages.label_delete_user
        }
        description={messages.text_confirm_delete_subuser}
        acceptButtonLabel={
          subUser ? messages.term_delete_user : messages.label_delete
        }
        cancelButtonLabel={messages.label_cancel}
        onCancelClick={handleCancelClick}
        onAcceptClick={handleAcceptClick}
      />
    )
  );
};

Delete.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  subUser: PropTypes.bool,
};
export default Delete;
