import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { PERMISSIONS } from "@utils/constant";
import permissionsService from "@app/services/permissions/permissionsService";
import Div from "@components/Div";
import ConfirmationPopup from "@components/ConfirmationPopup";
import { useToast } from "@hooks/useToast";
import deletePermissionService from "@app/services/permissions/deletePermissionsService";
import CreatePermissionDialog from "./CreatePermissionDialog";
import EditPermissionDialog from "./EditPermissionDialog";
import useAuthorization from "@hooks/useAuthorization";
import { AdminPermissions } from "@src/enum/Permissions";
import getUserDetails from "@app/services/auth/getUserDetails";
import { useDispatch } from "react-redux";
import { authActions } from "@app/reducers/authReducer";

const List = () => {
  const { messages } = useIntl();
  const [groups, setGroups] = useState([]);
  const searchableColumns = ["id", "name"];
  const [showAddPermissionDialog, setShowAddPermissionDialog] = useState(false);
  const [showEditPermissionDialog, setShowEditPermissionDialog] =
    useState(false);
  const [editPermissionId, setEditPermissionId] = useState("");
  const [deletePermissionId, setDeletePermissionId] = useState("");
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();
  const { hasAllPermissions } = useAuthorization();
  const dispatch = useDispatch();

  const isAdminWritePermission = hasAllPermissions([
    AdminPermissions.AdminWriteUserRolesPermissions,
  ]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { data: { data: _user = {} } = {} } = await getUserDetails();
        dispatch(authActions.storeUserInfo(_user));
      } catch (error) {
        showErrorToast(messages.error_try_later);
      }
    }; 

    fetchUserDetails();
  }, [messages.error_try_later, showErrorToast, dispatch]);

  const handleAddNewPermission = () => {
    setShowAddPermissionDialog(!showAddPermissionDialog);
  };

  const handleEditNewPermissionOpen = values => {
    const { id } = values;
    setEditPermissionId(id);
    setShowEditPermissionDialog(!showEditPermissionDialog);
  };

  const handleEditNewPermissionClose = () => {
    setShowEditPermissionDialog(!showEditPermissionDialog);
  };

  const handleDeletePermission = values => {
    const { id } = values;
    setDeletePermissionId(id);
    setIsDeleteDialogVisible(true);
  };

  const handleCancelDeletePermission = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleDeleteSubmit = async () => {
    try {
      await deletePermissionService(deletePermissionId);
      showSuccessToast(messages.label_permission_deleted);
      setIsDeleteDialogVisible(false);
      return;
    } catch (e) {
      showErrorToast(messages.error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [permissionsResponse] = await Promise.all([permissionsService()]);
        const { data: { data: rules = [] } = {} } = permissionsResponse;
        const formattedGroups = rules
          .map(o => ({
            name: messages[`permissions_group_${o.group}`],
            code: o?.group,
          }))
          .filter(
            (rule, index, self) =>
              index === self.findIndex(r => r.code === rule.code)
          );
        setGroups(formattedGroups);
      } catch (error) {
        // Handle error
      }
    };
    fetchData();
  }, [messages]);

  const dataTableConfig = {
    header: {
      title: "report_permissions",
      actions: [
        {
          id: "new-court",
          type: "button",
          label: messages.button_add_new,
          onClick: handleAddNewPermission,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: !isAdminWritePermission,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "reports.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.report_portal,
        id: "filter[portal]",
        type: "multiselect",
        options: [
          {
            name: "filter[portal]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[portal]",
            options: [
              {
                name: messages.role_admin,
                code: "admin",
              },
              {
                name: messages.customer_label,
                code: "customer",
              },
            ],
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.permission_group,
        id: "filter[group]",
        type: "multiselect",
        options: [
          {
            name: "filter[group]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[group]",
            options: groups,
            type: "multiselect",
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${PERMISSIONS}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [["fields", ["id", "name", "portal", "group"]]],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
        sortable: true,
        width: "100px",
      },
      {
        title: "label_permission_name",
        db_field: "name",
        type: "text",
        formatter: value => messages[value.replace(/-/g, "_")],
        sortable: true,
      },
      {
        title: "laebel_db_name",
        db_field: "name",
        type: "text",
        sortable: true,
      },
      {
        title: "report_portal",
        db_field: "portal",
        type: "text",
        sortable: true,
        width: "130px",
        formatter: value =>
          value === "admin" ? value : messages.title_customer,
      },
      {
        title: "permission_group",
        db_field: "group",
        type: "text",
        formatter: value => messages[`permissions_group_${value}`],
        sortable: true,
      },
      {
        type: "actions",
        width: "50px",
        isHidden: !isAdminWritePermission,
        actions: [
          {
            label: messages.label_view_edit_permission,
            icon: "icon-pen",
            onClick: handleEditNewPermissionOpen,
          },
          {
            label: messages.label_delete_permission,
            icon: "icon-rubber",
            onClick: handleDeletePermission,
          },
        ],
      },
      {
        type: "actions",
        width: "50px",
        isHidden: isAdminWritePermission,
        actions: [
          {
            type: "button",
            onClick: handleEditNewPermissionOpen,
           },
        ],
      },
      
    ],
  };

  return (
    <>
      {groups.length > 0 && <DataTable config={dataTableConfig} />}
      {showAddPermissionDialog && (
        <Div mt={8}>
          <CreatePermissionDialog handleClose={handleAddNewPermission} />
        </Div>
      )}
      {showEditPermissionDialog && (
        <Div mt={8}>
          <EditPermissionDialog
            editPermission={isAdminWritePermission}
            editPermissionId={editPermissionId}
            handleClose={handleEditNewPermissionClose}
          />
        </Div>
      )}
      {isDeleteDialogVisible && (
        <ConfirmationPopup
          title={messages.delete_permission_label}
          description={messages.label_delete_permission_confirm}
          onCancelClick={handleCancelDeletePermission}
          onAcceptClick={handleDeleteSubmit}
        />
      )}
    </>
  );
};

export default List;
