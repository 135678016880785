import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import Div from "@components/Div";
import { H4 } from "@components/Heading";
import Icon from "@components/Icon";
import Link from "@components/Link";

import CouponCodeInput from "./CouponCodeInput";

const CouponCodeHeader = ({ hasError, onValidateCouponCode }) => {
  const { messages } = useIntl();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isCouponCodeApplied, setIsCouponCodeApplied] = useState(false);

  const shouldShowCouponCodeButton = !isEditMode && !isCouponCodeApplied;
  const shouldShowCouponCodeInput =
    (isEditMode && !isCouponCodeApplied) || hasError;
  const shouldShowCouponCodeApplied = isCouponCodeApplied && !hasError;

  const openEditMode = () => {
    setIsEditMode(true);
  };

  const handleCouponCodeSubmit = couponCodeText => {
    if (couponCodeText == "") {
      return;
    }

    setIsCouponCodeApplied(true);

    onValidateCouponCode(couponCodeText);
  };

  return (
    <Div display="flex" flexDirection="column" gridGap={3}>
      <Div display="flex" justifyContent="space-between" alignItems="center">
        <H4>{messages.label_summary}</H4>

        {shouldShowCouponCodeButton && (
          <Link
            justifyContent="flex-end"
            label={messages.coupon_code_label}
            iconPos="left"
            onClick={openEditMode}
          >
            <Icon mx="5px" name="receipt" />
          </Link>
        )}

        {shouldShowCouponCodeApplied && (
          <Link
            justifyContent="flex-end"
            label={messages.coupon_code_applied_label}
            iconPos="left"
            onClick={openEditMode}
          >
            <Icon mx="8px" name="mark" />
          </Link>
        )}

        {shouldShowCouponCodeInput && (
          <Div display={["none", "none", "block", "block"]}>
            <CouponCodeInput onCouponCodeSubmitted={handleCouponCodeSubmit} />
          </Div>
        )}
      </Div>

      <Div display={["block", "block", "none", "none"]}>
        {shouldShowCouponCodeInput && (
          <CouponCodeInput onCouponCodeSubmitted={handleCouponCodeSubmit} />
        )}
      </Div>
    </Div>
  );
};

CouponCodeHeader.propTypes = {
  hasError: PropTypes.bool,
  onValidateCouponCode: PropTypes.func,
};

export default CouponCodeHeader;
