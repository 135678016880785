import React, { useState } from "react";
import { useIntl } from "react-intl";
import DataTable from "@components/DataTableV2/DataTable";
import { ROLES } from "@utils/constant";
import CreateRoleDialog from "./CreateRoleDialog";
import EditRoleDialog from "./EditRoleDialog";
import Div from "@components/Div";
import ConfirmationPopup from "@components/ConfirmationPopup";
import { useToast } from "@hooks/useToast";
import deleteRolesService from "../../../app/services/roles/deleteRolesService";
import { AdminPermissions } from "@src/enum/Permissions";
import useAuthorization from "@hooks/useAuthorization";

const List = () => {
  const { messages } = useIntl();
  const [showAddRoleDialog, setShowAddRoleDialog] = useState(false);
  const [showEditRoleDialog, setShowEditRoleDialog] = useState(false);
  const [editRoleId, setEditRoleId] = useState("");
  const [deleteRoleId, setDeleteRoleId] = useState("");
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();
  const { hasAllPermissions } = useAuthorization();
  const searchableColumns = ["id", "portal", "name"];

  const hasWriteRulesRoles = hasAllPermissions([
    AdminPermissions.AdminWriteUserRolesPermissions,
  ]);

  const handleAddNewRole = () => {
    setShowAddRoleDialog(!showAddRoleDialog);
  };

  const handleEditNewRoleOpen = values => {
    const { id } = values;
    setEditRoleId(id);
    setShowEditRoleDialog(!showEditRoleDialog);
  };

  const handleEditNewRoleClose = () => {
    setShowEditRoleDialog(!showEditRoleDialog);
  };

  const handleDeleteRole = values => {
    const { id } = values;
    setDeleteRoleId(id);
    setIsDeleteDialogVisible(true);
  };

  const handleCancelDeleteRole = () => {
    setIsDeleteDialogVisible(false);
  };

  const handleDeleteSubmit = async () => {
    try {
      await deleteRolesService(deleteRoleId);

      showSuccessToast(messages.label_role_deleted);
      setIsDeleteDialogVisible(false);
      return;
    } catch (e) {
      showErrorToast(messages.error);
    }
  };

  const renderPermissions = value => {
    if (!value || value.length === 0) {
      return "-";
    }
    return value.map(item => item.id).join(", ");
  };
  const dataTableConfig = {
    header: {
      title: "menu_roles",
      actions: [
        {
          id: "new-rule",
          type: "button",
          label: messages.label_create_role,
          onClick: handleAddNewRole,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: !hasWriteRulesRoles,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "reports.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.report_portal,
        id: "filter[portal]",
        type: "multiselect",
        options: [
          {
            name: "filter[portal]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[portal]",
            options: [
              {
                name: messages.role_admin,
                code: "admin",
              },
              {
                name: messages.customer_label,
                code: "customer",
              },
            ],
            type: "multiselect",
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${ROLES}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        ["fields", ["id", "name", "portal"]],
        ["include", ["permissions"]],
      ],
    },
    default_sort: {
      field: "id",
      order: "asc",
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "text",
        width: "100px",
        sortable: true,
      },
      {
        title: "role_label",
        db_field: "name",
        type: "text",
        formatter: value => messages[value.replace(/-/g, "_")],
        sortable: true,
      },
      {
        title: "laebel_db_name",
        db_field: "name",
        type: "text",
        sortable: true,
      },
      {
        title: "report_portal",
        db_field: "portal",
        type: "text",
        formatter: value => messages[value],
        sortable: true,
      },
      {
        title: "report_permissions",
        db_field: "permissions",
        type: "text",
        formatter: renderPermissions,
      },
      {
        type: "actions",
        width: "50px",
        isHidden: !hasWriteRulesRoles,
        actions: [
          {
            label: messages.label_view_edit_role,
            icon: "icon-pen",
            onClick: handleEditNewRoleOpen,
          },
          {
            label: messages.label_delete_role,
            icon: "icon-rubber",
            onClick: handleDeleteRole,
          },
        ],
      },
    ],
  };

  return (
    <>
      {<DataTable config={dataTableConfig} />}
      {showAddRoleDialog && (
        <Div mt={8}>
          <CreateRoleDialog handleClose={handleAddNewRole} />
        </Div>
      )}

      {showEditRoleDialog && (
        <Div mt={8}>
          <EditRoleDialog
            editRoleId={editRoleId}
            handleClose={handleEditNewRoleClose}
          />
        </Div>
      )}

      {isDeleteDialogVisible && (
        <ConfirmationPopup
          title={messages.label_delete_role}
          description={messages.label_role_delete_confirmation}
          onCancelClick={handleCancelDeleteRole}
          onAcceptClick={handleDeleteSubmit}
        />
      )}
    </>
  );
};

export default List;
