import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { useFormikContext } from "formik";
import { Password } from "primereact/password";

import Div from "@components/Div";
import { Error } from "@components/Heading";

const TextPassword = ({ name, feedback }) => {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext();
  const { messages } = useIntl();

  const errorMessage = errors[name];
  const touchedMessage = touched[name];

  return (
    <Div flex="1 1 40%">
      <Password
        mt={2}
        width={1}
        name={name}
        value={values?.[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        toggleMask
        feedback={feedback}
        promptLabel={messages.validation_empty_password_input}
        weakLabel={messages.label_weak_password}
        mediumLabel={messages.label_medium_password}
        strongLabel={messages.label_strong_password}
      />

      {errorMessage && touchedMessage && (
        <Div pb={1} pt={3}>
          <Error>{errorMessage}</Error>
        </Div>
      )}
    </Div>
  );
};

TextPassword.propTypes = {
  name: PropTypes.string.isRequired,
  feedback: PropTypes.bool,
};

export default TextPassword;
